import './Stakeholder.scss'
import React, { useEffect, useState } from 'react'
import { withRouter, Link } from "react-router-dom"
import { Layout, Spin, Icon, Menu, Dropdown, Button, Col, Row, message } from 'antd'
import { useTranslation } from "react-i18next"
import { StakePublic, StakePrivate } from '../'
import { ModalChangePassword } from '../../layouts/Private/components'
import { getDomainPromise, getUserByIdPromise, changePasswordPromise } from './promises'
import { SessionStorageService, LocalStorageService } from '../../services'
import i18nextConfig from '../../config/i18next'

import logoAml from '../../layouts/Private/components/Footer/logo-aml.png'
import ImageResources from "../../layouts/commonComponent/components/ExternalResources/ImageResources";

const { Header } = Layout

const Stakeholder = ({ match }) => {
	const { t } = useTranslation();
	const [cliente, setCliente] = useState(null)
	const [user, setUser] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [isModalChangePasswordVisible, setIsModalChangePasswordVisible] = useState(false)

	document.title = "Portal de Usuarios"

	useEffect(() => {
		const language = LocalStorageService.read('i18nextLng')
		getDomainPromise(match.params.domain).then(c => {
			setCliente(c.data)

			if(c.data) {
				i18nextConfig.changeLanguage(language.substring(0, 2) + c.data.country)

				const userId = SessionStorageService.read('portal-user-id')
				if(userId !== null && userId !== '') {
					getUserByIdPromise(c.data.id, userId).then(u => {
						if(u.data !== null && u.data !== '') {
							handleLogin(u.data, SessionStorageService.read('portal-user-status'))
						}
						setIsLoading(false)
					})
				}else {
					setIsLoading(false)
				}
			}
		})
	}, [])

	const handleLogin = async (data, code) => {
		let record = data.record
		const cliente = data.client
		if(code === 'x') {
			record.activeUser = true
		}else if(code === '200' || code === '202') {
			record.activeUser = (code === '200')
			SessionStorageService.create('portal-user-id', record.id)
			SessionStorageService.create('portal-user-status', code)
			if(code === '202') {
				message.warn({content: 'Se recomienda cambiar la contraseña'})
			}
		}else {
			record.activeUser = false
		}
		setCliente(cliente)
		setUser(record)
		setIsLoggedIn(true)
  }

	const handleLogout = async (e) => {
		setIsLoggedIn(false)
		setUser(null)
		SessionStorageService.delete('portal-user-id')
		SessionStorageService.delete('portal-user-status')
  }

	const handleOpenModalChangePassword = () => {
		setIsModalChangePasswordVisible(true)
	}

	const handleCloseModalChangePassword = () => {
		setIsModalChangePasswordVisible(false)
  }

 	const handleSaveModalChangePassword = (passwordCurrent, passwordNew, passwordNewConfirm) => {
    	changePasswordPromise(user.id, passwordCurrent, passwordNew, passwordNewConfirm).then(response => {
			if(response) {
				setIsModalChangePasswordVisible(false)
				if(!user.activeUser) {
					let _u = {...user, activeUser: true}
					SessionStorageService.create('portal-user-status', '200')
					setUser(_u)
				}
			}
		})
  }


	const dropdownMenu = (
		<Menu>
			<Menu.Item>
				<Link to={ '#' } onClick={ handleOpenModalChangePassword }>
					<Icon type="lock" /> &nbsp;{ t('messages.aml.changePwd') }
				</Link>
			</Menu.Item>
			<Menu.Item>
				<Link to={ '#' } onClick={ handleLogout }>
					<Icon type="logout" /> { t('messages.aml.logout') }
				</Link>
			</Menu.Item>
		</Menu>
	)

	const getUrlPerson = (cliente, clCliente) => {
		if(cliente.outsourcer && clCliente?.subcliente) {
			return ('/getImageClient?clientId=' + cliente.id + '&subclienteId=' + clCliente.subcliente.id)
		}else {
			return ('/getImageClient?clientId=' + cliente.id)
		}
	}

	return (
		<div className="stakeholder">
			{ cliente ?
				<>
					<Header className="header">
						<div className="logo">
							<ImageResources key={Math.random()} url={getUrlPerson(cliente, user)} ></ImageResources>
						</div>
						{ isLoggedIn && user !== null &&
							<div className="current-user">
						<Dropdown overlay={ dropdownMenu }>
							<Button>
							<Icon type="user" /> { user.nombre } <Icon type="caret-down" />
							</Button>
						</Dropdown>
							</div>
						}
					</Header>
					{ isLoading ? <Col className="row-loading"><Spin size="large"/></Col>
						:
						isLoggedIn ?
							<>
							{ <StakePrivate client={cliente} currentUser={user} /> }
							{ (isModalChangePasswordVisible) &&
								<ModalChangePassword
									visible={ true }
									isForced={user.activeUser === false}
									onOk={ handleSaveModalChangePassword }
									onCancel={ handleCloseModalChangePassword }
									/>
							}
							</>
						:
							<StakePublic client={cliente} successHandler={handleLogin} />
					}
					<div id="footer">
						<Row className="footer-inner">
							<Col xs={ 7 }>
								<img className="logo" src={ logoAml } alt="" />
								<div className="powered">
									<span>Powered by <a href="https://www.gesintel.cl" target="_blank">Gesintel Compliance S.A.</a></span>
								</div>
							</Col>
						</Row>
					</div>
				</>
			:
				<div align="center"><br/><br/><br/><h1>Cliente no existe</h1></div>
			}
		</div>
	)
}

export default withRouter(Stakeholder)
