import animateLogout from './animateLogout'
import changePassword from './changePassword'
import getAuthToken from './getAuthToken'
import getAuthEmailToken from './getAuthEmailToken'
import getCurrentUser from './getCurrentUser'
import getUsersByClient from './getUsersByClient'
import logout from './logout'
import removeLoginAnimations from './removeLoginAnimations'
import getIndices from './getIndices'
import getUpdateIndexes from './getUpdateIndexes'
import getBase64 from './getBase64'
import getParametroByKey from './getParametroByKey'
import getSIIActivities from './getSIIActivities'
import getRegionComuna from './getRegionComuna'
import generateReport from './generateReport'
import getReport from './getReport'
import getReportByUser from './getReportByUser'
import deleteReport from './deleteReport'
import getTransfenciasEstadoResumen from './getTransfenciasEstadoResumen'
import getTransfenciasEstadoDetalle from './getTransfenciasEstadoDetalle'
import getClientFileList from './getClientFileList'
import deleteClientFileList from './getClientFile'
import moveClientFile from './moveClientFile'
import validateMoveClientFile from './validateMoveClientFile'
import uploadClientFile from './uploadClientFile'
import createFolderPath from './createFolderPath'

export const animateLogoutPromise = animateLogout
export const changePasswordPromise = changePassword
export const getAuthTokenPromise = getAuthToken
export const getAuthEmailTokenPromise = getAuthEmailToken
export const getCurrentUserPromise = getCurrentUser
export const getUsersByClientPromise = getUsersByClient
export const logoutPromise = logout
export const removeLoginAnimationsPromise = removeLoginAnimations
export const getIndicesPromise = getIndices
export const getUpdateIndexesPromise = getUpdateIndexes
export const getBase64Promise = getBase64
export const getParametroByKeyPromise = getParametroByKey
export const getSIIActivitiesPromise = getSIIActivities
export const getRegionComunaPromise = getRegionComuna
export const generateReportPromise = generateReport
export const getReportPromise = getReport;
export const getReportByUserPromise = getReportByUser;
export const deleteReportPromise = deleteReport;
export const getTransfenciasEstadoResumenPromise = getTransfenciasEstadoResumen;
export const getTransfenciasEstadoDetallePromise = getTransfenciasEstadoDetalle;

export const getClientFileListPromise = getClientFileList
export const uploadClientFilePromise = uploadClientFile
export const createFolderPathPromise = createFolderPath
export const deleteClientFileListPromise = deleteClientFileList
export const moveClientFilePromise = moveClientFile
export const validateMoveClientFilePromise = validateMoveClientFile
