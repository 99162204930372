import "./FormKycProv.scss";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row, Spin, notification, Table, Icon, Radio, Divider, List, message, Upload, Modal } from "antd";
import { FormLayout } from "../../../../../../layouts";
import { withRouter } from "react-router-dom";
import { getFormPromise, getParamsPromise } from "../../../../promises";
import moment from "moment";
import Logo from '../../../../../../layouts/commonComponent/components/Logo/Logo'
import { getCountriesCodePromise, sendFileFormPromise } from "../../../../promises";
import apiConfig from '../../../../../../config/api'
import { banksList, cargosOptions, relationshipOptionsParauco, SIIactivities, tipoDocEntityOptions, tipoDocOptions, tipoEmpresaOptions, tipoFundacionOptions } from "../../../../../OnBoarding/forms/Peru/assets/optList";
import useFormsHooks from "../../../../../InterestConflicts/forms/hooks/useFormsHooks";
import { getRegionComunaPromise } from "../../../../../../promises";
import { getParamsUboFinderPromise } from "../../../../../InterestConflicts/promises";
import { camelizerHelper } from "../../../../../../helpers";

const FormProv = ({ form, match }) => {
  const { t } = useTranslation();
  const { getFieldsError, validateFieldsAndScroll, setFieldsValue, validateFields } = form;
  const [isValidate, setIsValidate] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  const [paises, setPaises] = useState(null);
  const [date, setDate] = useState(0);
  const [signed, setSigned] = useState(false);
  const [user, setUser] = useState({});
  const [apiForm, _setApiForm] = useState(null);
  const [colLogo, setColLogo] = useState(4);
  const [format, setFormat] = useState("html");
  const [openSigner, setOpenSigner] = useState(false);
  const [hasSign, setHasSign] =  useState(true);
  const [clientName, setClientName] = useState("");
  const [signData, setSignData] = useState(null);
  const apiFormRef = useRef(apiForm);
  const [validarRegistros, setValidarRegistros] = useState(false);
  const [params, setParams] = useState([]);
  const [empresasGrupo, setEmpresasGrupo] = useState([]);
  const [registerData, setRegisterData] = useState();
  const [regionesPais, setRegionesPais] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [tmpFilesList, setTmpFilesList] = useState([]);
  const [fileNames, setFileNames] = useState([]);


  const setApiForm = data => {
    apiFormRef.current = data;
    _setApiForm(data);
  };

  const handlerOnChangeObjAttr = async (sectionId, value, formObj) => {
    handleOnChangeField(sectionId, value)
    const binfo = entriesToObj(basicInformation)
    const actEco = entriesToObj(infoActEco)
    const iBan = entriesToObj(infoBancaria)
    const iPep = entriesToObj(infoPep)
    // const fPub = entriesToObj(funcionarioPublico)
    const rFunc = entriesToObj(representacionFuncPublic)
    const conI = entriesToObj(conflictoInteres)
    const sMed = entriesToObj(saludMedioambiente)
    const eCon = entriesToObj(eticaConducta)
    const acc = entriesToObj(accionistas)

    if(!formObj) formObj = apiForm
    const json = { 
      basicInformation: binfo,
      infoActEco: actEco,
      infoBancaria: iBan,
      infoPep: iPep,
      accionistas: acc,
      representacionFuncPublic: rFunc,
      conflictoInteres: conI,
      saludMedioambiente: sMed,
      eticaConducta: eCon,
    }
    let formToUpdate = { ...formObj, json };
    let ret = await saveFormPromiseLocal(formToUpdate);
    if(!ret.success) {
    //  setFieldsValue({[field]: ret.form[field]})
    }
  }

  const numTelValidator = (rule, value, callback) => {
    const regex = /^[0-9]*$/;
    if (value && (value.length < 6 || value.length > 9)) {
      callback("Número debe entre 6 y 9 dígitos");
    } else if (value && !(regex.test(value))) {
      callback("Debe contenter sólo números");
    } else {
      callback();
    }
  };

  const numCelValidator = (rule, value, callback) => {
    const regex = /^[0-9]*$/;
    if (value && (value.length !== 9)) {
      callback("Número debe tener 9 dígitos");
    } else if (value && !(regex.test(value))) {
      callback("Debe contenter sólo números");
    } else {
      callback();
    }
  };

  const emailValidator = (rule, value, callback) => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (value && regex.test(value) === false){
      callback("Debe ingresar un correo electrónico válido");
    }else{
      callback();
    }
  }

  const propsUpload = {
    accept: ".pdf, .png, .docx, .xlsx",
    onRemove: file => {
      const index = tmpFilesList.indexOf(file);
      handleListDeleted(index);
    },
    beforeUpload: file => {
      validateFields([attch.fileName.id]).then((error, values) => {
        const validFiles = file.type === 'application/pdf' || file.type === 'image/png'|| file.name.endsWith('.docx') || file.name.endsWith('.xlsx');
        if (!validFiles) {
          message.error('Formato no permitido.');
        }else{
          const validSize = file.size / 1024 / 1024 < 2;
          if (!validSize) {
            message.error('Tamaño máximo 2MB!');
          }else{
            setTmpFilesList(oldTmpFilesList => [...oldTmpFilesList, file]);
            setFileNames(oldFileNames => [...oldFileNames, attch.fileName.val]);
          }
          setFieldsValue({[attch.fileName.id]: null})
          handleOnChangeField(attch.fileName.id, "")
        }
      })
      return false
    },
    multiple: false,
    showUploadList: false
  }

  const handleListDeleted = (index) => {
    setFileNames(oldFileNames => oldFileNames.filter((_, i) => i !== index));
    setTmpFilesList(oldTmpFilesList => oldTmpFilesList.filter((_, i) => i !== index));
  };
  

  const initVariablesFromObject = (obj, objs={}) => {
    let objs1 = {...objs}
    if (obj.json) {
      let objs1 = {...objs}
      if (obj.json.basicInformation) {
        let binfo = objToVariable(basicInformationIS, obj.json.basicInformation);
        objs1['basicInformation'] = binfo

        if(obj.json.basicInformation.domicilio?.codigoPais === 'PE') {
          getRegionComunaPromise(obj.json.basicInformation.domicilio?.codigoPais).then((regiones) => {
            setRegionesPais(regiones)
            if(regiones) {
              const region = regiones.filter(r => r.code === obj.json.basicInformation.domicilio?.codigoDepartamento)
              if(region?.length === 1) {
                const provincias = region[0].provincias
                setProvincias(provincias)

                const provincia = provincias.filter(r => r.code === obj.json.basicInformation.domicilio?.codigoProvincia)
                if(provincia?.length === 1) {
                  const comunas = provincia[0].comunas
                  setDistritos(comunas)
                }
              }
            }
          })
        }
      }
      if (obj.json.infoActEco) {
        let actEco = objToVariable(infoActEcoIS, obj.json.infoActEco);
        objs1['infoActEco'] = actEco
      }
      if (obj.json.infoBancaria) {
        let iBan = objToVariable(infoBancariaIS, obj.json.infoBancaria);
        objs1['infoBancaria'] = iBan
      }
      if (obj.json.infoPep) {
        let iPep = objToVariable(infoPepIS, obj.json.infoPep);
        objs1['infoPep'] = iPep
      }
      // if (obj.json.funcionarioPublico) {
      //   let fPub = objToVariable(funcionarioPublicoIS, obj.json.funcionarioPublico);
      //   objs1['funcionarioPublico'] = fPub
      // }
      if (obj.json.representacionFuncPublic) {
        let rFunc = objToVariable(representacionFuncPublicIS, obj.json.representacionFuncPublic);
        objs1['representacionFuncPublic'] = rFunc
      }
      if (obj.json.conflictoInteres) {
        let confI = objToVariable(conflictoInteresIS , obj.json.conflictoInteres);
        objs1['conflictoInteres'] = confI
      }
      if (obj.json.saludMedioambiente) {
        let sMed = objToVariable(saludMedioambienteIS, obj.json.saludMedioambiente);
        objs1['saludMedioambiente'] = sMed
      }
      if (obj.json.eticaConducta) {
        let eCon = objToVariable(eticaConductaIS, obj.json.eticaConducta);
        objs1['eticaConducta'] = eCon
      }
      if (obj.json.accionistas) {
        let acc = objToVariable(accionistasIS, obj.json.accionistas);
        objs1['accionistas'] = acc
      }
    }
    registerObjSections(objs1)
  };

  const { getObjSection, 
    registerObjSections, 
    handleOnChangeFields,
    handleOnChangeField, 
    renderFormItem, 
    renderFormItemTable, 
    renderFormItemObj, 
    objToVariable, 
    handleOnClear, 
    handleDeleteAttrTable, 
    handleOnAddAttrTable, 
    entriesToObj, 
    saveFormPromiseLocal, 
    sendDocument, 
    docValidator, 
    toDescriptionsPdf,
    signAndSend
  } = useFormsHooks({format, form, match, apiForm, setApiForm, handlerOnChangeObjAttr, initVariablesFromObject});

  let registersStop = {};

  const radioStyle = {
    display: 'block',
    height: '22px',
    lineHeight: '22px',
    pointerEvents: format === 'pdf' ? 'none' : 'auto'
  };

  // INTRODUCCION
  const basicInformationIS= {
    name: { key: "name", title: "Nombre Completo", maxLength:100, id: "basicInformationName", required:true, type: 'input', readOnly:true, section: 'basicInfo', disabled: true },
    tipoDocumento: { key:"tipoDocumento", title:"Tipo de documento", maxLength:50, id:'basicInformationTipoDocumento',readOnly:true, type: 'select', section: 'basicInfo', disabled: false },
    nroDocumento: { key: "nroDocumento", title: "Nro. de Identificación", maxLength:50, id: "basicInformationNroDocumento", required:true, readOnly:true, type: 'input', section: 'basicInfo', disabled: true },
    fecNacimiento: { key: "fecNacimiento", title:"Fecha de nacimiento", maxLength:50, id: "basicInformationFecNacimiento", required: true, type: 'date', section: 'basicInfo'},
    tipoSociedad: { key:"tipoSociedad",title:"Tipo de Sociedad", maxLength:50, id:'basicInformationTipoSociedad',readOnly:true, type: 'select', section: 'basicInfo' },
    tipoSociedadOtra: { key:"tipoSociedadOtra",title:"Ingrese Otra", maxLength:100, id:'basicInfoTipoSociedadOtra',readOnly:true, section: 'basicInfo'},
    tipoEmpresa: { key:"tipoEmpresa",title:"Tipo de Empresa", maxLength:50, id:'basicInformationTipoEmpresa',readOnly:true, type: 'select', section: 'basicInfo' },
    nationality: { key:"nationality", title:"Nacionalidad", mFxLength:50, id:'basicInformationNationality',readOnly:true, type: 'select', section: 'basicInfo' },
    paisConst: { key:"paisConst", title:"País de Constitución", mFxLength:50, id:'basicInformationPaisConst',readOnly:true, type: 'select', section: 'basicInfo' },
    propositoRel: { key:"propositoRel", title:"Propósito de la Relación", maxLength:50, id:'basicInformationPropositoRel',readOnly:true, type:'select', section: 'basicInfo' },
    tipoPermanencia: {  key:"tipoPermanencia", title:"Tipo de Permanencia", maxLength:50, id:'basicInformationTipoPermanencia',readOnly:true, type:'select', section: 'basicInfo' },
    ocupacion: { key:"ocupacion", title: "Ocupación u oficio", maxLength:50, id:'basicInformationOcupacion',readOnly:true, type: 'select', section: 'additionalInfo' },
    ocupacionOtro: { key:"ocupacionOtro", title:"Especifique otro oficio" ,maxLength:100, id:'basicInformationOcupacionOtro',readOnly:true, type: 'input', section: 'additionalInfo' },
    contactName: { key:"contactName", title:"Nombres y Apellidos", maxLength:100, id:'basicInformationContactName',readOnly:true, section:'contacto' },
    cargo: { key: "cargo", val:"",title:"Cargo", maxLength:100, id:'basicInformationCargo', readOnly:true, section:'contacto' },
    mail: { key:"mail", title:"Correo Electrónico" ,maxLength:100, id:'basicInformationMail',readOnly:true, section:'contacto' },
    dir: { key:"dir", title: "Dirección" ,maxLength:100, id:'basicInformationDir',readOnly:true, section:'domicilio' },
    departamento: { key:"departamento", title: "Departamento", id:"basicInformationDepartamento", readOnly: true, section:'domicilio', type: 'select', required: true},
    provincia: { key:"provincia", title: "Provincia", id:"basicInformationProvincia", readOnly: true, section:'domicilio', type: 'select', required: true},
    distrito: { key:"distrito", title: "Distrito", id:"basicInformationDistrito", readOnly: true, section:'domicilio', type: 'select', required: true},
    pais: { key:"pais", title: "País", id:"basicInformationPais", readOnly: true, type: 'select', section:'domicilio' },
    codigoPais: { key:"codigoPais", id:"basicInformationCodigoPais", readOnly: true, section:'domicilio' },
    codigoDepartamento: { key:"codigoDepartamento", id:"basicInformationCodigoDepartamento", readOnly: true, section:'domicilio' },
    codigoProvincia: { key:"codigoProvincia", id:"basicInformationCodigoProvincia", readOnly: true, section:'domicilio' },
    codigoDistrito: { key:"codigoDistrito", id:"basicInformationCodigoDistrito", readOnly: true, section:'domicilio' },
    tel: { key:"tel", title: "Teléfono Fijo", id:"basicInformationTel", readOnly: true, required: true, type: 'input', section:'contacto' },
    cel: { key:"cel", title: "Teléfono Celular", id:"basicInformationCel", type: 'input', section:'contacto', required: false },
    mtrz: { key:"matriz", title: "Tiene Casa Matriz?", id:"basicInformationMtrz", type: 'select', section: 'additionalInfo', maxLength:50 },
    mtrzName: { key:"mtrzName", title: "Razón Social", id:"basicInformationMtrzName", readOnly: true, section: 'casaMatriz', maxLength:100 },
    mtrzDir: { key:"mtrzDir", title: "Dirección", id:"basicInformationMtrzDir", readOnly: true, section: 'casaMatriz', maxLength:200 },
    mtrzPais: { key:"mtrzPais", title: "País", id:"basicInformationMtrzPais", readOnly: true, type: 'select', section: 'casaMatriz', maxLength:200 },
    mtrzCity: { key:"mtrzCity", title: "Ciudad", id:"basicInformationMtrzCity", readOnly: true, section: 'casaMatriz', maxLength:200 },
    nomFacturacion: { key:"nomFacturacion", title: "Nombres y Apellidos", id:"basicInformationNomFacturacion", section: 'facturacion', maxLength:100 },
    cargoFacturacion: { key:"cargoFacturacion", title: "Cargo", id:"basicInformationCargoFacturacion", section: 'facturacion', maxLength:100 },
    mailFacturacion: { key:"mailFacturacion", title: "Correo electrónico", id:"basicInformationMailFacturacion", readOnly: true, section: 'facturacion', maxLength:100 },
    celFacturacion: { key:"celFacturacion", title: "Teléfono Celular", id:"basicInformationCelFacturacion", readOnly: true, required: false, section: 'facturacion' },
    telFacturacion: { key:"telFacturacion", title: "Teléfono Fijo", id:"basicInformationTelFacturacion",required: true, section: 'facturacion' },
    nombreRepresentante: { key:"nombreRepresentante", title: "Nombres y Apellidos", id:"basicInformationNombreRepresentante", readOnly: true, section: 'representante', maxLength:100 },
    tipoDocRepresentante: { key:"tipoDocRepresentante", title: "Tipo de documento", id:"basicInformationTipoDocRepresentante", type: 'select', section: 'representante', maxLength:50 },
    docRepresentante: { key:"docRepresentante", title: "Nro. de identificación", id:"basicInformationDocRepresentante", readOnly: true, section: 'representante', maxLength:100 },
    cargoRepresentante: { key:"cargoRepresentante", title: "Cargo", id:"basicInformationCargoRepresentante", readOnly: true, section: 'representante', maxLength:100 },
    isShareholder: { key:"isShareholder", title: "Es accionista de la empresa?", id:"basicInformationIsShareholder", readOnly: true, section: 'representante', type:'select', maxLength:50 },
    correoRepresentante: { key:"correoRepresentante", title: "Correo electrónico", id:"basicInformationCorreoRepresentante", readOnly: true, section: 'representante', maxLength:100 },
  };
  const [keyBasicInformation, basicInformation] = getObjSection('basicInformation')

  // II. INFORMACIÓN SOBRE ACTIVIDAD ECONÓMICA
  const infoActEcoIS= {
    fecInicioAct: { key: "fecInicioAct", title:"Fecha de incio de actividades", maxLength:100, id: "infoActEcoFecInicioAct", required: true, type: 'date'},
    experienciaMercado: { key: "experienciaMercado", title:"Experiencia en el mercado (meses)", id: "infoActEcoExperienciaMercado", required: true, type: 'input', disabled: true},
    actividadesRecords: { key: "actividadesRecords", val: [], id: "infoActEcoActividadesRecords"},
    // donacionesRecords: { key: "donacionesRecords", val: [], id: "infoActEcoDonacionesRecords"},
  }
  const [keyInfoActEco, infoActEco] = getObjSection('infoActEco')

  const prodActObjIS = {
    actEconomica: { key: "actEconomica", title:"Seleccione Actividad Económica", maxLength:50, id: "prodActObjActEconomica", required: true, type: 'select'},
  }
  const [keyProdActObj, prodActObj] = getObjSection('prodActObj')

  // const donacionActObjIS = {
  //   donacion: { key: "donacion", title:"Seleccione Actividad Económica propia de Donaciones", maxLength:50, id: "donacionActObjDonacion", required: true, type: 'select'},
  // }
  // const [keyDonacionActObj, donacionActObj] = getObjSection('donacionActObj')

  //  III. INFORMACIÓN BANCARIA
  const infoBancariaIS = {
    tipoProducto: { key: "tipoProducto", title:"Tipo de producto", maxLength:100, id: "infoBancariaTipoProducto", required: true, type: 'select'},
    tipoMoneda: { key: "tipoMoneda", title:"Tipo de moneda", maxLength:100, id: "infoBancariaTipoMoneda", required: true, type: 'select'},
    pais: { key: "pais", title:"País", maxLength:100, id: "infoBancariaTipoPais", required: true, type: 'select'},
    nroCuenta: { key: "nroCuenta", title:"Número de cuenta", maxLength:100, id: "infoBancariaNroCuenta", required: true, type: 'input'},
    codSwift: { key: "codSwift", title:"Código SWIFT / IBAN", maxLength:100, id: "infoBancariaCodSwift", required: false, type: 'input'},
    banco: { key: "banco", title:"Nombre de la entidad bancaria", maxLength:100, id: "infoBancariaBanco", required: true, type: 'select'},
    otroBanco: { key: "otroBanco", title:"Especifique Otro", maxLength:100, id: "infoBancariaOtroBanco", required: true, type: 'input'},
    hasFactura: { key: "hasFactura", title:"Emitirá factura", maxLength:100, id: "infoBancariaHasFactura", required: true, type: 'select'},
    cci: { key: "cci", title:"CCI", maxLength:100, id: "infoBancariaCci", required: true, type: 'input'},
    cuentaDetraccion: { key: "cuentaDetraccion", title:"Cuenta de detracción en Banco de la Nación", maxLength:100, id: "infoBancariaCuentaDetraccion", required: true, type: 'input'},
  }
  const [keyInfoBancaria, infoBancaria] = getObjSection('infoBancaria')

  // ACCIONISTAS
  const accionistasIS= {
    isAccionista: { key: "isAccionista", val: undefined, title: "", id: "accionistasIsAccionista", required: true},
    accionistaRecords: { key: "accionistaRecords", val:[], title: "", id: "accionistasAccionistaRecords", required: true},
  };
  const [keyAccionistas, accionistas]=getObjSection('accionistas')

  const  accionistaObjIS={
    tipoPersona: { key: "tipoPersona", title: "Tipo de persona", maxLength:50, id: "accionistaObjTipoPersona", required: true, type: 'select', typeEntity: ['PJ']},
    name: { key: "name", title: "Nombres y Apellidos / Razón Social", maxLength:100, id: "accionistaObjName", required: true, typeEntity: ['PJ']},
    tipoId: { key: "tipoId", title: "Tipo de documento", maxLength:50, id: "accionistaObjTipoId", required: true, type: 'select', typeEntity: ['PJ']},
    nroIdentificacion: { key: "nroIdentificacion", title: "Nro. de identificación", maxLength:50, id: "accionistaObjNroIdentificacion", required: true, type: 'input', typeEntity: ['PJ']},
    nacionalidad: { key: "nacionalidad", title: "Nacionalidad", maxLength:200, id: "accionistaObjNacionalidad", required: true, typeEntity: ['PJ'], type: 'select'},
    participacion: { key: "participacion", title: "Participación (En %)", id: "accionistaObjParticipacion", required: true, typeEntity: ['PJ'], type:'number', isPercentage: true, max: 100, min: 0},
  }
  const [keyAccionistaObj, accionistaObj ]=getObjSection('accionistaObj')


  //  IV. PERSONA EXPUESTA POLÍTICAMENTE O PÚBLICAMENTE (PEP)
  const infoPepIS= {
    isPublic: { key: "isPublic", val: undefined, title: "", id: "infoPepIsPublic", required: true},
    hasFamily: { key: "hasFamily", title: "", id: "infoPepHasFamily", required: true},
    hasRelation: { key: "hasRelation", title: "", id: "infoPepHasRelation", required: true},
    publicRecords: { key: "publicRecords", val: [], id: "infoPepPublicRecords"},
    familyRecords: { key: "familyRecords", val: [], id: "infoPepFamilyRecords"},
    relationRecords: { key: "relationRecords", val: [], id: "infoPepRelationRecords"},
  };
  const [keyInfoPep, infoPep]=getObjSection('infoPep')

  const  pepPublicObjIS={
    grupo: { key: "grupo", title: "Grupo de la persona relacionada", maxLength:50, id: "pepPublicObjGrupo", required: true, type: 'select', typeEntity: ['PJ']},
    name: { key: "name", title: "Nombre Completo", maxLength:100, id: "pepPublicObjName", required: true, typeEntity: ['PJ']},
    tipoId: { key: "tipoId", title: "Tipo de documento", maxLength:50, id: "pepPublicObjTipoDoc", required: true, type: 'select', typeEntity: ['PJ']},
    nroIdentificacion: { key: "nroIdentificacion", title: "Nro. de identificación", maxLength:50, id: "pepPublicObjNroId", required: true, type: 'input', typeEntity: ['PJ']},
    orgPublic: { key: "orgPublic", title: "Organismo Público", maxLength:200, id: "pepPublicObjOrgPublic", required: true, typeEntity: ['PJ']},
    cargo: { key: "cargo", title: "Cargo", id: "pepPublicObjCargo", required: true, typeEntity: ['PJ'], maxLength:100},
    pais: { key: "pais", title: "País", maxLength:50, id: "pepPublicObjPais", required: true, type: 'select', typeEntity: ['PJ']},
    fecTermino: { key: "fecTermino", val: null, title: "Fecha de término", maxLength:50, id: "pepPublicObjFecTermino", required: false, type: 'date', typeEntity: ['PJ']},
    cargoPerNat: { key: "cargoPerNat", title: "Cargo", id: "pepPublicObjCargoPerNat", required: true, typeEntity: ['PN'], maxLength:100},
    orgPublicPerNat: { key: "orgPublicPerNat", title: "Organismo Público", maxLength:200, id: "pepPublicObjOrgPublicPerNat", required: true, typeEntity: ['PN']},
    paisPerNat: { key: "paisPerNat", title: "País", maxLength:50, id: "pepPublicObjPaisPerNat", required: true, type: 'select', typeEntity: ['PN']},
    fecTerminoPerNat: { key: "fecTerminoPerNat", val: null, title: "Fecha de término", maxLength:50, id: "pepPublicObjFecTerminoPerNat", required: false, type: 'date', typeEntity: ['PN']},
  }
  const [keyPepPublicObj, pepPublicObj ]=getObjSection('pepPublicObj')

  const  pepFamilyObjIS={
    grupo: { key: "grupo", title: "Grupo de la persona relacionada", maxLength:50, id: "pepFamilyObjGrupo", required: true, type: 'select', typeEntity: ['PJ']},
    name: { key: "name", title: "Nombre del Familiar", maxLength:100, id: "pepFamilyObjName", required: true, type: 'input', typeEntity: ['PJ']},
    tipoDoc: { key: "tipoDoc", title: "Tipo de documento", maxLength:50, id: "pepFamilyObjTipoDoc", required: true, type: 'select', typeEntity: ['PJ']},
    nroId: { key: "nroId", title: "Nro. de identicación", maxLength:50, id: "pepFamilyObjNroId", required: true, type: 'input', typeEntity: ['PJ']},
    parentesco: { key: "parentesco", title: "Tipo de Parentesco", maxLength:50, id: "pepFamilyObjParentesco", required: true, type: 'select', typeEntity: ['PJ']},
    nombrePariente: { key: "nombrePariente", title: "Nombre Completo", maxLength:100, id: "pepFamilyObjNamePariente", required: true, type: 'input', typeEntity: ['PJ']},
    tipoDocPariente: { key: "tipoDocPariente", title: "Tipo de documento", maxLength:50, id: "pepFamilyObjTipoDocPariente", required: true, type: 'select', typeEntity: ['PJ']},
    nroIdPariente: { key: "nroIdPariente", title: "Nro. de identificación", maxLength:50, id: "pepFamilyObjNroIdPariente", required: true, type: 'input', typeEntity: ['PJ']},
    orgPublic: { key: "orgPublic", title: "Organismo Público", maxLength:200, id: "pepFamilyObjOrgPublic", required: true, typeEntity: ['PJ']},
    pais: { key: "pais", title: "País", maxLength:50, id: "pepFamilyObjPais", required: true, type: 'select', typeEntity: ['PJ']},
    cargo: { key: "cargo", title: "Cargo del PEP", maxLength:100, id: "pepFamilyObjCargo", required: true, typeEntity: ['PJ']},
    fecTermino: { key: "fecTermino", val: null, title: "Fecha de término", maxLength:50, id: "pepFamilyObjFecTermino", required: false, type: 'date', typeEntity: ['PJ']},
    parentescoPerNat: { key: "parentescoPerNat", title: "Tipo de Parentesco", maxLength:50, id: "pepFamilyObjParentescoPerNat", required: true, type: 'select', typeEntity: ['PN']},
    namePerNat: { key: "namePerNat", title: "Nombre del Familiar", maxLength:100, id: "pepFamilyObjNamePerNat", required: true, type: 'input', typeEntity: ['PN']},
    tipoDocPerNat: { key: "tipoDocPerNat", title: "Tipo de documento", maxLength:50, id: "pepFamilyObjTipoDocPerNat", required: true, type: 'select', typeEntity: ['PN']},
    nroIdPerNat: { key: "nroIdPerNat", title: "Nro. de identicación", maxLength:50, id: "pepFamilyObjNroIdPerNat", required: true, type: 'input', typeEntity: ['PN']},
    cargoPerNat: { key: "cargoPerNat", title: "Cargo del PEP", maxLength:100, id: "pepFamilyObjCargoPerNat", required: true, typeEntity: ['PN']},
    orgPublicPerNat: { key: "orgPublicPerNat", title: "Organismo Público", maxLength:200, id: "pepFamilyObjOrgPublicPerNat", required: true, typeEntity: ['PN']},
    paisPerNat: { key: "paisPerNat", title: "País", maxLength:50, id: "pepFamilyObjPaisPerNat", required: true, type: 'select', typeEntity: ['PN']},
    fecTerminoPerNat: { key: "fecTerminoPerNat", val: null, title: "Fecha de término", maxLength:50, id: "pepFamilyObjFecTerminoPerNat", required: false, type: 'date', typeEntity: ['PN']},
  }
  const [keyPepFamilyObj, pepFamilyObj]=getObjSection('pepFamilyObj')

  const  pepRelationObjIS={
    grupo: { key: "grupo", title: "Grupo de la persona relacionada", maxLength:50, id: "pepRelationObjGrupo", required: true, type: 'select', typeEntity: ['PJ']},
    name: { key: "name", title: "Nombre Completo", maxLength:100, id: "pepRelationObjName", required: true, typeEntity: ['PJ']},
    tipoId: { key: "tipoId", title: "Tipo de documento", maxLength:50, id: "pepRelationObjTipoId", required: true, type: 'select', typeEntity: ['PJ']},
    nroIdentificacion: { key: "nroIdentificacion", title: "Nro. de identificación", maxLength:50, id: "pepRelationObjNroId", required: true, typeEntity: ['PJ']},
    namePac: { key: "namePac", title: "Nombre Completo", maxLength:100, id: "pepRelationObjNamePac", required: true, typeEntity: ['PJ']},
    tipoDocPac: { key: "tipoDocPac", title: "Tipo de documento", maxLength:50, id: "pepRelationObjTipoDocPac", required: true, type: 'select', typeEntity: ['PJ']},
    nroIdPac: { key: "nroIdPac", title: "Nro. de identificación", maxLength:50, id: "pepRelationObjNroIdPac", required: true, typeEntity: ['PJ']},
    orgPublico: { key: "orgPublico", title: "Organismo Público", maxLength:200, id: "pepRelationObjOrgPublico", required: true, typeEntity: ['PJ']},
    pais: { key: "pais", title: "País", maxLength:50, id: "pepRelationObjPais", required: true, type: 'select', typeEntity: ['PJ']},
    cargoPac: { key: "cargoPac", title: "Cargo", maxLength:100, id: "pepRelationObjCargoPac", required: true, typeEntity: ['PJ']},
    fecTermino: { key: "fecTermino", val: null, title: "Fecha de término", maxLength:50, id: "pepRelationObjFecTermino", required: false, type: 'date', typeEntity: ['PJ']},
    // empresa: { key: "empresa", title: "Razón social", maxLength:100, id: "pepRelationObjEmpresa", required: true, typeEntity: ['PJ']},
    nameEmp: { key: "nameEmp", title: "Razón Social", maxLength:100, id: "pepRelationObjNameEmp", required: true, typeEntity: ['PJ']},
    tipoIdEmp: { key: "tipoIdEmp", title: "Tipo de documento", maxLength:50, id: "pepRelationObjTipoDocEmp", required: true, type: 'select', typeEntity: ['PJ']},
    nroIdEmp: { key: "nroIdEmp", title: "Nro. de identificación", maxLength:50, id: "pepRelationObjNroIdEmp", required: true, typeEntity: ['PJ']},
    empresaPerNat: { key: "empresaPerNat", title: "Razón social", maxLength:100, id: "pepRelationObjEmpresaPerNat", required: true, typeEntity: ['PN']},
    tipoIdEmpPerNat: { key: "tipoIdEmpPerNat", title: "Tipo de documento", maxLength:50, id: "pepRelationObjTipoDocEmpPerNat", required: true, type: 'select', typeEntity: ['PN']},
    nroIdEmpPerNat: { key: "nroIdEmpPerNat", title: "Nro. de identificación", maxLength:50, id: "pepRelationObjNroIdEmpPerNat", required: true, typeEntity: ['PN']},
    pepNamePerNat: { key: "pepNamePerNat", title: "Nombre Completo", maxLength:100, id: "pepRelationObjPepNamePerNat", required: true, typeEntity: ['PN']},
    tipoDocPepPerNat: { key: "tipoDocPepPerNat", title: "Tipo de documento", maxLength:50, id: "pepRelationObjTipoDocPepPerNat", required: true, type: 'select', typeEntity: ['PN']},
    nroIdPepPerNat: { key: "nroIdPepPerNat", title: "Nro. de identificación", maxLength:50, id: "pepRelationObjNroIdPepPerNat", required: true, typeEntity: ['PN']},
    cargoPepPerNat: { key: "cargoPepPerNat", title: "Cargo del PEP", maxLength:100, id: "pepRelationObjCargoPepPerNat", required: true, typeEntity: ['PN']},
    orgPublicoPerNat: { key: "orgPublicoPerNat", title: "Organismo Público", maxLength:200, id: "pepRelationObjOrgPublicoPerNat", required: true, typeEntity: ['PN']},
    paisPerNat: { key: "paisPerNat", title: "País", maxLength:50, id: "pepRelationObjPaisPerNat", required: true, type: 'select', typeEntity: ['PN']},
    fecTerminoPerNat: { key: "fecTerminoPerNat", val: null, title: "Fecha de término", maxLength:50, id: "pepRelationObjFecTerminoPerNat", required: false, type: 'date', typeEntity: ['PN']},
  }
  const [keyPepRelationObj, pepRelationObj]=getObjSection('pepRelationObj')

  // VI. REPRESENTACIÓN ANTE FUNCIONARIOS O SERVIDORES PÚBLICOS
  const  representacionFuncPublicIS={
    isRepresentative: { key: "isRepresentative", val: undefined, title: "", maxLength:200, id: "representacionFuncPublicIsRepresentative", required: true},
  }
  const [keyRepresentacionFuncPublicIS, representacionFuncPublic]=getObjSection('representacionFuncPublic')

  //VII. CONFLICTO DE INTERÉS
  const conflictoInteresIS = {
    hasFamily: { key: "hasFamily", title: "", id: "conflictoInteresHasFamily", required: true},
    hasShares: { key: "hasShares", title: "", id: "conflictoInteresHasShares", required: true},
    hasRelation: { key: "hasRelation", title: "", id: "conflictoInteresHasRelation", required: true},
    isDirector: { key: "isDirector", title: "", id: "conflictoInteresIsDirector", required: true},
    directorHireDate: { key: "directorHireDate", title: "¿Desde cuándo?", id: "conflictoInteresDirectorHireDate", required: true, type: 'date'},
    familyRecords: { key: "familyRecords", val: [], id: "conflictoInteresFamilyRecords"},
    shareRecords: { key: "shareRecords", val: [], id: "conflictoInteresShareRecords"},
    relationRecords: { key: "relationRecords", val: [], id: "conflictoInteresRelationRecords"},
    isDirectorRecords: { key: "isDirectorRecords", val: [], id: "conflictoInteresIsDirectorRecords"},
  }
  const [keyConflictoInteresIS, conflictoInteres]=getObjSection('conflictoInteres')

  const  conFamilyObjIS={
    name: { key: "name", title: "Nombres y Apellidos", maxLength:100, id: "conFamilyObjName", required: true, typeEntity: ['PJ', 'PN']},
    tipoId: { key: "tipoId", title: "Tipo de documento", maxLength:50, id: "conFamilyObjTipoDoc", required: true, type: 'select', typeEntity: ['PJ', 'PN']},
    nroIdentificacion: { key: "nroIdentificacion", title: "Nro. de identificación", maxLength:50, id: "conFamilyObjNroIdentificacion", required: true, type: 'input', typeEntity: ['PJ', 'PN']},
    cargo: { key: "cargo", title: "Cargo", maxLength:100, id: "conFamilyObjCargo", required: true, typeEntity: ['PJ','PN']},
    parentesco: { key: "parentesco", title: "Tipo de parentesco", maxLength:50, id: "conFamilyObjObjParentesco", required: true, type: 'select', typeEntity: ['PJ', 'PN']},
    nombreRelacionado: { key: "nombreRelacionado", title: "Nombres y Apellidos", maxLength:100, id: "conFamilyObjObjNombreRelacionado", required: true, typeEntity: ['PJ', 'PN']},
    tipoDocRelacionado: { key: "tipoDocRelacionado", title: "Tipo de documento", maxLength:50, id: "conFamilyObjObjTipoDocRelacionado", required: true, type: 'select', typeEntity: ['PJ', 'PN']},
    nroIdRelacionado: { key: "nroIdRelacionado", title: "Nro. de identificación", maxLength:50, id: "conFamilyObjObjNroIdRelacionado", required: true, typeEntity: ['PJ', 'PN']},
    cargoRelacionado: { key: "cargoRelacionado", title: "Cargo Proveedor", maxLength:100, id: "conFamilyObjObjCargoRelacionado", required: true, typeEntity: ['PJ', 'PN']},
  }
  const [keyConFamilyObjIS, conFamilyObj ]=getObjSection('conFamilyObj')

  const  conShareholderObjIS={
    nameEmp: { key: "nameEmp", title: "Razón Social", maxLength:100, id: "conShareholderObjNameEmp", required: true, typeEntity: ['PJ', 'PN']},
    tipoIdEmp: { key: "tipoIdEmp", title: "Tipo de documento", maxLength:50, id: "conShareholderObjTipoDocEmp", required: true, type: 'select', typeEntity: ['PJ', 'PN']},
    nroIdEmp: { key: "nroIdEmp", title: "Nro. de identificación", maxLength:50, id: "conShareholderObjNroIdEmp", required: true, typeEntity: ['PJ', 'PN']},
    name: { key: "name", title: "Nombres y Apellidos", maxLength:100, id: "conShareholderObjName", required: true, typeEntity: ['PJ', 'PN']},
    tipoDoc: { key: "tipoDoc", title: "Tipo de documento", maxLength:50, id: "conShareholderObjTipoDoc", required: true, type:'select', typeEntity: ['PJ', 'PN']},
    nroIdentificacion: { key: "nroIdentificacion", title: "Nro. de identificación", maxLength:50, id: "conShareholderObjNroIdentificacion", required: true, typeEntity: ['PJ', 'PN']},
    cargo: { key: "cargo", title: "Cargo", maxLength:100, id: "conShareholderObjCargo", required: true, type: 'input', typeEntity: ['PJ', 'PN']},
    nombreColab: { key: "nombreColab", title: "Nombres y Apellidos", maxLength:100, id: "conShareholderObjNombreColab", required: true, type: 'input', typeEntity: ['PJ', 'PN']},
    tipoDocColab: { key: "tipoDocColab", title: "Tipo de documento", maxLength:50, id: "conShareholderObjTipoDocColab", required: true, type: 'select', typeEntity: ['PJ', 'PN']},
    nroIdColab: { key: "nroIdColab", title: "Nro. de identificación", maxLength:50, id: "conShareholderObjnroIdColab", required: true, type: 'input', typeEntity: ['PJ', 'PN']},
    cargoColab: { key: "cargoColab", title: "Cargo", maxLength:100, id: "conShareholderObjCargoColab", required: true, type: 'input', typeEntity: ['PJ', 'PN']},
  }
  const [keyConShareholderObjIS, conShareholderObj]=getObjSection('conShareholderObjIS')

  const  conHasRelationObjIS={
    cargo: { key: "cargo", title: "Cargo", maxLength:100, id: "conHasRelationObjCargo", required: true, type: 'input', typeEntity: ['PJ']},
    fechaTermino: { key: "fechaTermino", title: "Fecha de término", maxLength:50, id: "conHasRelationObjFechaTermino", required: true, type: 'date', typeEntity: ['PJ']},
    nombreRelacionado: { key: "nombreRelacionado", title: "Nombres y Apellidos", maxLength:100, id: "conHasRelationObjNombreRelacionado", required: true, typeEntity: ['PJ']},
    tipoDocRelacionado: { key: "tipoDocRelacionado", title: "Tipo de documento", maxLength:50, id: "conHasRelationObjTipoDocRelacionado", required: true, type:'select', typeEntity: ['PJ']},
    nroIdRelacionado: { key: "nroIdRelacionado", title: "Nro. de identificación", maxLength:50, id: "conHasRelationObjNroIdRelacionado", required: true, typeEntity: ['PJ']},
    cargoRelacionado: { key: "cargoRelacionado", title: "Cargo Proveedor", maxLength:100, id: "conHasRelationObjCargoRelacionado", required: true, typeEntity: ['PJ']},
    cargoPerNat: { key: "cargoPerNat", title: "Cargo", maxLength:100, id: "conHasRelationObjCargoPerNat", required: true, type: 'input', typeEntity: ['PN']},
    fechaTerminoPerNat: { key: "fechaTerminoPerNat", title: "Fecha de término", maxLength:50, id: "conHasRelationObjFechaTerminoPerNat", required: true, type: 'date', typeEntity: ['PN']},
  }
  const [keyConHasRelationObjIS, conHasRelationObj]=getObjSection('conHasRelationObj')

  const  conIsDirectorObjIS={
    nombre: { key: "nombre", title: "Nombres y Apeliidos", maxLength:100, id: "conIsDirectorObjNombre", required: true, typeEntity: ['PJ', 'PN']},
    tipoDoc: { key: "tipoDoc", title: "Tipo de documento", maxLength:50, id: "conIsDirectorObjTipoDoc", required: true, type: 'select', typeEntity: ['PJ', 'PN']},
    nroIdentificacion: { key: "nroIdentificacion", title: "Nro. de identificación", maxLength:50, id: "conIsDirectorObjNroIdentificacion", required: true, typeEntity: ['PJ', 'PN']},
  }
  const [keyConIsDirectorObjIS, conIsDirectorObj]=getObjSection('conIsDirectorObj')

  const  isDirectorObjIS={
    hireDate: { key: "hireDate", title: "¿Desde cuándo?", maxLength:50, id: "isDirectorObjHireDate", required: true, type: 'input', typeEntity: ['PJ']},
  }
  const [keyIsDirectorObjIS, isDirectorObj]=getObjSection('isDirectorObj')

  //IX. SALUD, SEGURIDAD Y MEDIO AMBIENTE
  const saludMedioambienteIS = {
    hasEnvRegulations: { key: "hasEnvRegulations", title: "", id: "saludMedioambienteHasEnvRegulations", required: true},
    hasHumanRegulations: { key: "hasHumanRegulations", title: "", id: "saludMedioambienteHasHumanRegulations", required: true},
    hasHealthPolicies: { key: "hasHealthPolicies", title: "", id: "saludMedioambienteHasHealthPolicies", required: true},
  }
  const [keySaludMedioambienteIS, saludMedioambiente]=getObjSection('saludMedioambiente')
  
  //X. ÉTICA Y CONDUCTA
  const eticaConductaIS = {
    hasRules: { key: "hasRules", title: "", id: "eticaConductaHasRules", required: true},
    hasChannels: { key: "hasChannels", title: "", id: "eticaConductaHasChannels", required: true},
    hasPolicies: { key: "hasPolicies", title: "", id: "eticaConductaHasPolicies", required: true},
    defeatHumanRights: { key: "defeatHumanRights", title: "", id: "eticaConductaDefeatHumanRights", required: true},
    hasControls: { key: "hasControls", title: "", id: "eticaConductaHasControls", required: true},
  }
  const [keyEticaConductaIS, eticaConducta]=getObjSection('eticaConducta')

  const attchIs={
    fileName: {key: "fileName", title: "Documento", id: "attchFileName", maxLength: 100, required: true, type: "select"},
  }
  const [keyAttchIs, attch]=getObjSection('attch')

  
  useEffect(() => {
    const obj = { basicInformation: basicInformationIS,
      infoActEco: infoActEcoIS,
      prodActObj: prodActObjIS,
      // donacionActObj: donacionActObjIS,
      infoBancaria : infoBancariaIS,
      infoPep: infoPepIS,
      pepPublicObj: pepPublicObjIS,
      pepFamilyObj: pepFamilyObjIS,
      pepRelationObj: pepRelationObjIS,
      accionistas: accionistasIS,
      accionistaObj: accionistaObjIS,
      representacionFuncPublic: representacionFuncPublicIS,
      conflictoInteres : conflictoInteresIS ,
      conFamilyObj: conFamilyObjIS,
      conShareholderObj: conShareholderObjIS,
      conHasRelationObj: conHasRelationObjIS,
      conIsDirectorObj: conIsDirectorObjIS,
      isDirectorObj: isDirectorObjIS,
      saludMedioambiente: saludMedioambienteIS,
      eticaConducta: eticaConductaIS,
      attch: attchIs
    }

    if (match.params.view === "pdf") {
      setColLogo(5);
      setFormat("pdf");
    }

    getCountriesCodePromise().then((response) => {
      setPaises(response)
    })
    getFormPromise(match.params.id).then((response) => {
      if (response?.data?.status) {
        if (response.data.recipient.record.type === 'Entity'){
          response.data["typeSol"] = 'PJ'
        }else{
          response.data["typeSol"] = 'PN'
        }
        setApiForm(response.data);
        initVariablesFromObject(response.data, obj)
        setUser(response.data.recipient.request.createUser);
        setClientName(response.data.recipient.request.createUser.cliente.name);
        setRegisterData(response.data.recipient.record)
        
        if(response.data.recipient.request.createUser.cliente.outsourcer) {
          setEmpresasGrupo(response.data.recipient.request.createUser.cliente.clientes.map(item => {
            return item.name
          }))
        }

        const clientId = response.data.recipient.request.createUser.cliente.id
        getParamsUboFinderPromise(clientId).then((response) => {
          setHasSign(response.data.firma !== "N");
        });

        if(response.data.status === "SENT") {
          setDate(moment(response.data.receiveDate).format("DD-MM-YYYY"));
        }else {
          setDate(moment().format("DD-MM-YYYY"));
        }
      }
      setIsloading(false);
    });

    const handleMessage = (message) => {
      if(message.data && typeof message.data === 'string' && message.data.startsWith('DOCUSIGN') && apiConfig.url.startsWith(message.origin)) {
        const data = message.data.split('|')
        if(data.length === 4) {
          if(data[1] === "signing_complete") {
            const reqId = data[2] + "|" + data[3]
            sendDocument(reqId, apiFormRef.current)
          }else {
            closeHandlerSigner();
            notification["warning"]({
              message: "Firma cancelada",
            });
          }
        }
      }
    }

    window.addEventListener("message", handleMessage);
    return function () {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const closeHandlerSigner = () => {
    setSignData(null)
    setOpenSigner(false)
  }

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }
  

const handleOnAddAttrTableLocal = (attrObj, attrTable, attrRecords='records') => {
  setIsValidate(true);
  if(handleOnAddAttrTable(attrObj, attrTable, attrRecords)) {
    registersStop[attrObj] = false;
  }
}

const sendDocumentPrivate = async (requestId) => {
  sendDocument(requestId)
}

  const handleSubmit = async (e) => {
    //VALIDACIONES
    e.preventDefault();
    setIsValidate(true);
    setValidarRegistros(true);
    // Construir lista de campos a validar

    let fieldsToValidate = []

    const basicValues = Object.values(basicInformation)
    basicValues.map(item => {
      fieldsToValidate.push(item.id)
    })
    const bancariaValues = Object.values(infoBancaria)
    bancariaValues.map(item => {
      fieldsToValidate.push(item.id)
    })
    const saludValues = Object.values(saludMedioambiente)
    saludValues.map(item => {
      fieldsToValidate.push(item.id)
    })
    const eticaValues = Object.values(eticaConducta)
    eticaValues.map(item => {
      fieldsToValidate.push(item.id)
    })

    const sectionsWithArray = [
      infoActEco.fecInicioAct.id,
      infoActEco.experienciaMercado.id,
      infoPep.isPublic.id,
      infoPep.hasFamily.id,
      infoPep.hasRelation.id,
      accionistas.isAccionista.id,
      representacionFuncPublic.isRepresentative.id,
      conflictoInteres.hasFamily.id,
      conflictoInteres.hasShares.id,
      conflictoInteres.hasRelation.id,
      conflictoInteres.isDirector.id,
    ];

    const finalArrayToValidate = fieldsToValidate.concat(sectionsWithArray)

    // Validar los campos seleccionados
    validateFieldsAndScroll(finalArrayToValidate);
    
    
    // Agregar 'otroOficio' solo si 'oficio' es igual a 'otros'
    if (basicInformation.ocupacion.val === 'Otro') {
      fieldsToValidate.push(basicInformation.ocupacionOtro.id);
    }
    
    if (infoBancaria.banco.val === 'Otro') {
      fieldsToValidate.push(infoBancaria.otroBanco.id);
    }
    

    // VALIDACIÓN FUNCIONARIOS PUBLICOS Y PEP
    if(infoPep.hasRelation.val && infoPep.relationRecords.val.length === 0){
      validateFieldsAndScroll(Object.values(pepRelationObj).map(v => v.id))
      registersStop = { ...registersStop, [infoPep.relationRecords.id]: true };
    }

    if(infoPep.hasFamily.val && infoPep.familyRecords.val.length === 0){
      validateFieldsAndScroll(Object.values(pepFamilyObj).map(v => v.id))
      registersStop = { ...registersStop, [infoPep.familyRecords.id]: true };
    }

    if(infoPep.isPublic.val && infoPep.publicRecords.val.length === 0){
      validateFieldsAndScroll(Object.values(pepPublicObj).map(v => v.id))
      registersStop = { ...registersStop, [infoPep.publicRecords.id]: true };
    }
    
    // VALIDACIÓN ACTIVIDAD ECONÓMICA
    if(infoActEco.actividadesRecords.val.length === 0){
      validateFieldsAndScroll(Object.values(prodActObj).map(v => v.id))
      registersStop = { ...registersStop, [infoActEco.actividadesRecords.id]: true };
    }

    // if(infoActEco.donacionesRecords.val.length === 0 && basicInformation.propositoRel.val === 'Donación'){
    //   validateFieldsAndScroll(Object.values(donacionActObj).map(v => v.id))
    //   registersStop = { ...registersStop, [infoActEco.donacionesRecords.id]: true };
    // }

    if(infoActEco.actividadesRecords.val.length === 0){
      validateFieldsAndScroll(Object.values(prodActObj).map(v => v.id))
      registersStop = { ...registersStop, [infoActEco.actividadesRecords.id]: true };
    }

    // VALIDACIÓN CONFLICTO DE INTERÉS
    if(conflictoInteres.hasFamily.val && conflictoInteres.familyRecords.val.length === 0){
      validateFieldsAndScroll(Object.values(conFamilyObj).map(v => v.id))
      registersStop = { ...registersStop, [conflictoInteres.familyRecords.id]: true };
    }
    if(conflictoInteres.hasShares.val && conflictoInteres.shareRecords.val.length === 0){
      validateFieldsAndScroll(Object.values(conShareholderObj).map(v => v.id))
      registersStop = { ...registersStop, [conflictoInteres.shareRecords.id]: true };
    }
    if(conflictoInteres.hasRelation.val && conflictoInteres.relationRecords.val.length === 0){
      validateFieldsAndScroll(Object.values(conHasRelationObj).map(v => v.id))
      registersStop = { ...registersStop, [conflictoInteres.relationRecords.id]: true };
    }
    if(apiForm.typeSol === 'PN'){
      if(conflictoInteres.isDirector.val){
        validateFieldsAndScroll([conflictoInteres.directorHireDate.id])
      }
    }
    if(apiForm.typeSol === 'PJ'){
      if(conflictoInteres.isDirector.val && conflictoInteres.isDirectorRecords.val.length === 0){
        validateFieldsAndScroll(Object.values(conIsDirectorObj).map(v => v.id))
        registersStop = { ...registersStop, [conflictoInteres.isDirectorRecords.id]: true };
      }
    }

    // VALIDACIÓN ACCIONISTAS
    if(accionistas.isAccionista.val && accionistas.accionistaRecords.val.length === 0){
      validateFieldsAndScroll(Object.values(accionistaObj).map(v => v.id))
      registersStop = { ...registersStop, [accionistas.accionistaRecords.val]: true };
    }

    // VALIDACIÓN DOCUMENTOS ADJUNTOS
    if(apiForm.typeSol === 'PJ'){
      if (tmpFilesList.length < 3) {
        validateFieldsAndScroll(Object.values(attch).map(v => v.id))
        registersStop = { ...registersStop, [attch.fileName.id]: true };
      }
    }else{
      if (tmpFilesList.length < 2) {
        validateFieldsAndScroll(Object.values(attch).map(v => v.id))
        registersStop = { ...registersStop, [attch.fileName.id]: true };
      }
    }
      

    if (
      hasErrors(getFieldsError()) ||
      Object.values(registersStop).find((value) => value === true) !== undefined
    ) {
      notification["warning"]({
        message: t("messages.aml.missingRequiredField"),
      });
    } else {
      setOpenSigner(true)
      if(tmpFilesList !== null && tmpFilesList.length > 0){
        const formData = new FormData()
        tmpFilesList.forEach((file, index) => {
          formData.append('file', file)
          formData.append('fileName', fileNames[index])
        })
        formData.append('formId', apiForm.id);
    
        await sendFileFormPromise(formData);
      }
      if(hasSign) {
        await signAndSend(apiForm, setSignData, setSigned)
      }else {
        await sendDocument()
      }
      setOpenSigner(false)
    }
  };

  const getPepPublicColumns = () => {
    var columns = []
    if(apiForm.typeSol === 'PN') {
      columns.push(
        {
          title: "Cargo",
          dataIndex: "cargoPerNat",
          width:"23%"
        },
        {
          title: "Organismo Público",
          dataIndex: "orgPublicPerNat",
          width:"23%"
        },
        {
          title: "País",
          dataIndex: "paisPerNat",
          width:"23%"
        },
        {
          title: "Fecha de término",
          dataIndex: "fecTerminoPerNat",
          width:"23%"
        },
      )
    }else{
    columns.push(
    {
      title: "Grupo de la persona relacionada",
      dataIndex: "grupo",
      width:"18.4%"
    },
    {
      title: "Nombre Completo",
      dataIndex: "name",
      width:"18.4%"
    },
    {
      title: "Nro. identificación",
      dataIndex: "nroIdentificacion",
      width:"18.4%"
    },
    {
      title: "Organismo Público",
      dataIndex: "orgPublic",
      width:"18.4%"
    },
    {
      title: "Cargo",
      dataIndex: "cargo",
      width:"18.4%"
    },
    )}

    if(format === "html" && !signed) {
      columns.push({
          title: "Acción",
          dataIndex: "",
          key: "x",
          width: "8%",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteAttrTable({attrTable:'infoPep',attrRecords: "publicRecords", index})}>
              <Icon type="delete" />
            </a>
          ),
        }
      )
    }
    return columns
  }
  const getConflictFamilyColumns = () => {
    var columns = []
    if(apiForm.typeSol === 'PN') {
      columns.push(
        {
          title: "Nombres y Apellidos",
          dataIndex: "name",
        },
        {
          title: "TIpo de documento",
          dataIndex: "tipoId",
        },
        {
          title: "Nro. de identificación",
          dataIndex: "nroIdentificacion",
        },
        {
          title: "Cargo",
          dataIndex: "cargo",
        },
        {
          title: "Tipo de parentesco",
          dataIndex: "parentesco",
        },
      )
    }else{
      columns.push(
        {
          title: "Nombre rel. proveedor",
          dataIndex: "name",
        },
        {
          title: "Nro. ID rel. proveedor",
          dataIndex: "nroIdentificacion",
        },
        {
          title: "Cargo rel. proveedor",
          dataIndex: "cargo",
        },
        {
          title: "Parentesco rel. proveedor",
          dataIndex: "parentesco",
        },
        {
          title: "Nombre rel. "+clientName,
          dataIndex: "nombreRelacionado",
        },
        {
          title: "Nro. Id "+clientName,
          dataIndex: "nroIdRelacionado",
        },
        {
          title: "Cargo rel. "+clientName,
          dataIndex: "cargoRelacionado",
        },
      )
    }
    if(format === "html" && !signed) {
      columns.push({
          title: "Acción",
          dataIndex: "",
          key: "x",
          width: "8%",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteAttrTable({attrTable:'conflictoInteres',attrRecords: "familyRecords", index})}>
              <Icon type="delete" />
            </a>
          ),
        }
      )
    }
    return columns
  }
  const getConflictShareColumns = () => {
    var columns = []
    if(apiForm.typeSol === 'PN') {
      columns.push(
        {
          title: "Nombre rel. proveedor",
          dataIndex: "name",
        },
        {
          title: "Nro id rel. proveedor",
          dataIndex: "nroIdentificacion",
        },
        {
          title: "Cargo rel. proveedor",
          dataIndex: "cargo",
        },
        {
          title: "Razón social",
          dataIndex: "nameEmp",
        },
        {
          title: "Nro. id sociedad",
          dataIndex: "nroIdEmp",
        },
      )
    }else{
      columns.push(
        {
          title: "Nombre rel. proveedor",
          dataIndex: "name",
        },
        {
          title: "Cargo rel. proveedor",
          dataIndex: "cargo",
        },
        {
          title: "Razón social sociedad",
          dataIndex: "nameEmp",
        },
        {
          title: "Nro. id sociedad",
          dataIndex: "nroIdEmp",
        },
        {
          title: "Nombre colab.",
          dataIndex: "nombreColab",
        },
        {
          title: "Nro. id colab.",
          dataIndex: "nroIdColab",
        },
        {
          title: "Cargo colab.",
          dataIndex: "cargoColab",
        },
      )
    }
    
    if(format === "html" && !signed) {
      columns.push({
          title: "Acción",
          dataIndex: "",
          key: "x",
          width: "8%",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteAttrTable({attrTable:'conflictoInteres',attrRecords: "shareRecords", index})}>
              <Icon type="delete" />
            </a>
          ),
        }
      )
    }
    return columns
  }
  
  const getConflictRelationColumns = () => {
    var columns = []
    if(apiForm.typeSol === 'PJ') {
      columns.push(
        {
          title: "Cargo",
          dataIndex: "cargo",
        },
        {
          title: "Fecha de término",
          dataIndex: "fechaTermino",
        },
        {
          title: "Nombre Proveedor",
          dataIndex: "nombreRelacionado",
        },
        {
          title: "Nro. id Proveedor",
          dataIndex: "nroIdRelacionado",
        },
        {
          title: "Cargo Proveedor",
          dataIndex: "cargoRelacionado",
        },
      )
    }else{
      columns.push(
        {
          title: "Cargo",
          dataIndex: "cargoPerNat",
        },
        {
          title: "Fecha de término",
          dataIndex: "fechaTerminoPerNat",
        },
      )
    }
    if(format === "html" && !signed) {
      columns.push({
          title: "Acción",
          dataIndex: "",
          key: "x",
          width: "8%",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteAttrTable({attrTable:'conflictoInteres',attrRecords: "relationRecords", index})}>
              <Icon type="delete" />
            </a>
          ),
        }
      )
    }
    return columns
  }
  const getConflictDirectorColumns = () => {
    var columns = []
    if(apiForm.typeSol === 'PJ') {
      columns.push(
        {
          title: "Nombres y Apellidos",
          dataIndex: "nombre",
        },
        {
          title: "Tipo de documento",
          dataIndex: "tipoDoc",
        },
        {
          title: "Nro. de identificación",
          dataIndex: "nroIdentificacion",
        },
      )
    }
    if(format === "html" && !signed) {
      columns.push({
          title: "Acción",
          dataIndex: "",
          key: "x",
          width: "8%",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteAttrTable({attrTable:'conflictoInteres',attrRecords: "isDirectorRecords", index})}>
              <Icon type="delete" />
            </a>
          ),
        }
      )
    }
    return columns
  }

  const getPepFamilyColumns = () => {
    var columns = []
      if(apiForm.typeSol === 'PJ') {
        columns.push(
          {
            title: "Grupo de la persona relacionada",
            dataIndex: "grupo",
          },
          {
            title: "Nombre Completo Relacionado",
            dataIndex: "name",
          },
          {
            title: "Nro. identificación Relacionado",
            dataIndex: "nroId",
          },
          {
            title: "Nombre Completo Pariente",
            dataIndex: "nombrePariente",
          },
          {
            title: "Nro. identificación Pariente",
            dataIndex: "nroIdPariente"
          },
        )
      }else{
        columns.push(
          {
            title: "Nombre del Familiar",
            dataIndex: "namePerNat",
          },
          {
            title: "Nro. identificación",
            dataIndex: "nroIdPerNat",
          },
          {
            title: "Cargo",
            dataIndex: "cargoPerNat"
          },
          {
            title: "Organismo público",
            dataIndex: "orgPublicPerNat",
          },
    )}

    if(format === "html" && !signed) {
      columns.push({
          title: "Acción",
          dataIndex: "",
          key: "x",
          width: "8%",
          render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleDeleteAttrTable({attrTable:'infoPep',attrRecords: "familyRecords", index})}>
              <Icon type="delete" />
            </a>
          ),
        }
      )
    }
    return columns
  }

  const actEcoColumns = [
    {
      title: "Actividad Económica",
      dataIndex: "actEconomica",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'infoActEco',attrRecords: "actividadesRecords", index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ]

  // const donacionColumns = [
  //   {
  //     title: "Donaciones",
  //     dataIndex: "donacion",
  //   },
  //   format === "html" && !signed ? {
  //     title: "Acción",
  //     dataIndex: "",
  //     width: "8%",
  //     render: (text, record, index) => (
  //       <a onClick={handleDeleteAttrTable({attrTable: 'infoActEco',attrRecords: "donacionesRecords", index})}>
  //         <Icon type="delete" />
  //       </a>
  //     ),
  //   } : {},
  // ]

  const accionistaColumns = [
    {
      title: "Tipo de Persona",
      dataIndex: "tipoPersona",
    },
    {
      title: "Nombre / Razón Social",
      dataIndex: "name",
    },
    {
      title: "Nro. de identificación",
      dataIndex: "nroIdentificacion",
    },
    {
      title: "Nacionalidad",
      dataIndex: "nacionalidad",
    },
    {
      title: "Participación (En %)",
      dataIndex: "participacion",
    },
    format === "html" && !signed ? {
      title: "Acción",
      dataIndex: "",
      width: "8%",
      render: (text, record, index) => (
        <a onClick={handleDeleteAttrTable({attrTable: 'accionistas',attrRecords: "accionistaRecords", index})}>
          <Icon type="delete" />
        </a>
      ),
    } : {},
  ]

  return (
    <FormLayout view={match.params.view} >
      <div style={{ position: "relative" }}>
        {isLoading ? (
          <div className="form-header">
            <Row>
              <Col xs={9}></Col>
              <Col xs={6}>
                <div className="form-wrapper" style={{ textAlign: "center" }}>
                  <Spin style={{ fontColor: "#fff" }} size={"large"} />
                </div>
              </Col>
              <Col xs={9}></Col>
            </Row>
          </div>
        ) : (
          <div className="form-kyc-parauco">
            {apiForm !== null ? (
              <div
                className={
                  "form-content " + format + " " +(!isValidate ? " form-validate-messages" : "")
                }
              >
                <Form onSubmit={handleSubmit} className="form-form">
                  <Row
                    className="title-logo"
                    gutter={[0, 6]}
                    style={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      marginTop: "0px",
                      marginBottom: "0px",
                      textAlign: "left",
                      paddingTop: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Col
                      xs={24 - colLogo}
                      sm={24 - colLogo}
                      md={24 - colLogo}
                      lg={24 - colLogo}
                      xl={24 - colLogo}
                    >
                      <h3>
                      {apiForm.typeSol === 'PN' ? 
                        "FORMULARIO DE ONBOARDING - PERSONA NATURAL" 
                      : 
                        "FORMULARIO DE ONBOARDING - PERSONA JURÍDICA"
                      }
                      </h3>
                    </Col>
                    <Col
                      className="logo-col"
                      xs={colLogo}
                      sm={colLogo}
                      md={colLogo}
                      lg={colLogo}
                      xl={colLogo}
                    >
                      <Logo currentUser={{ userId: user.id, subclienteId: '0' }} isForm={true} />
                    </Col>
                  </Row>
                  <Row
                    className="date"
                    gutter={[0, 6]}
                    style={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      marginTop: "0px",
                      marginBottom: "0px",
                      textAlign: "left",
                      paddingBottom: "15px",
                      paddingLeft: "10px",
                    }}
                  >
                    <Col span={2} style={{ textAlign: "left" }}>
                      Fecha:
                    </Col>
                    <Col span={4}>
                      <div
                        className="formDate"
                        style={{
                          marginLeft: "5px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: "#e8e8e8",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {date}
                      </div>
                    </Col>
                    <Col span={13} style={{ textAlign: "right" }}>
                      Folio:
                    </Col>
                    <Col span={5}>
                      <div
                        className="formDate"
                        style={{
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: "#e8e8e8",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "10px",
                          marginLeft: "8px",
                        }}
                      >
                        {apiForm.folio !== null ? apiForm.folio : ""}
                      </div>
                    </Col>
                  </Row>
                  {apiForm.status === "SENT" && format === "html" ? (
                    <>
                      <br />
                      <h3 style={{ textAlign: "center" }}>
                        Estimado(a){/* {registerData.nombre} */}
                        {apiForm.recipient.record.name}, le informamos que su declaración fue correctamente
                        completada, agradecemos su tiempo y disposición.
                        <br />
                        Hemos enviado una copia de la declaración realizada al
                        correo electrónico registrado:<br/><br/>
                        {apiForm.recipient.record.email}
                      </h3>
                    </>
                  ) : (
                  <>
                    <Row gutter={[20]}>
                      <Col className="summary" style={{textAlign: "justify", margin: 10}}>
                        <p>
                          En cumplimiento con lo establecido por la Ley N°30424 (norma que regula la responsabilidad administrativa de las personas jurídicas en el proceso penal), {clientName} ha adoptado un Modelo de Prevención de Delitos que tiene como objetivo evitar la comisión de delitos de corrupción, lavado de activos, entre otros. El presente formato es requerido para cumplir con las exigencias establecidas en la Ley mencionada y es parte de nuestro proceso del conocimiento diligente de las personas con las cuales nos relacionamos. 
                        </p>
                        <p>
                          Agradecemos completar el formato. Este ser revisado y firmado por un Representante Legal.
                        </p>
                      </Col>
                    </Row>
                    <Row
                      className="lineamiento subheader"
                      style={{ marginTop: "0px" }}
                    >
                      <Col xl={24}>I. INFORMACIÓN GENERAL</Col>
                    </Row>
                   
                    <Row gutter={[20]} className="summary" type={format === 'html' ? 'flex' : null}>
                      <Col className="subsection-title" xs={24}>
                        Identificación
                        <hr/>
                      </Col>
                      {renderFormItemObj({
                        section: basicInformation.name,
                        customTitle: apiForm.typeSol === 'PN' ? "Nombre Completo" : "Razón Social"
                      })}

                      {renderFormItemObj({
                        section: basicInformation.tipoDocumento,
                        options: apiForm.typeSol === 'PN' ? tipoDocOptions : tipoDocEntityOptions
                      })}

                      {renderFormItemObj({
                        section: basicInformation.nroDocumento,
                      })}

                      {apiForm.typeSol === 'PJ' &&
                        renderFormItemObj({
                          section: basicInformation.tipoEmpresa,
                          options: [
                            { val: "Privada", text: "Privada " },
                            { val: "Pública", text: "Pública" },
                            { val: "Mixta", text: "Mixta" },
                            { val: "Entidad sin ánimo de lucro", text: "Entidad sin ánimo de lucro" },
                          ]
                        })
                      }

                      {apiForm.typeSol === 'PN' && renderFormItemObj({
                        section: basicInformation.fecNacimiento,
                      })}

                      {renderFormItemObj({
                          section: apiForm.typeSol === 'PN' ? basicInformation.nationality : basicInformation.paisConst,
                          options:
                            paises.map(item => {
                              return {val: item.country, text: item.country}
                          })
                        })
                      }

                      {renderFormItemObj({
                        section: basicInformation.propositoRel,
                        options: [
                          {val: "Productos", text: "Productos"},
                          {val: "Servicios", text: "Servicios"},
                          {val: "Productos y Servicios", text: "Productos y Servicios"},
                          {val: "Donación", text: "Donación"},
                        ]
                      })}

                      {apiForm.typeSol === 'PN' &&
                        renderFormItemObj({
                          section: basicInformation.ocupacion,
                          options:[
                            { val: "Empleado dependiente", text: "Empleado dependiente" },
                            { val: "Independiente / Empresario", text: "Independiente / Empresario" },
                            { val: "Otro", text: "Otro" },
                          ]
                        })
                      }

                      {basicInformation.ocupacion.val === 'Otro' && apiForm.typeSol === 'PN' &&
                        renderFormItemObj({
                        cols: format === 'pdf' ? 16 : 12,
                        section: basicInformation.ocupacionOtro,
                      })}

                      {/* {apiForm.typeSol === 'PJ' &&
                        renderFormItemObj({
                          section: basicInformation.tipoSociedad,
                          options: [
                            { val: "Sociedades Colectivas Civiles", text: "Sociedades Colectivas Civiles" },
                            { val: "Empresa individual de responsabilidad limitada ", text: "Empresa individual de responsabilidad limitada" },
                            { val: "Sociedad Anónima abierta", text: "Sociedad Anónima abierta" },
                            { val: "Sociedad Anónima Cerrada", text: "Sociedad Anónima Cerrada" },
                            { val: "Sociedad anónima con garantía recíproca", text: "Sociedad anónima con garantía recíproca" },
                            { val: "Sociedad anónima deportiva profesional", text: "Sociedad anónima deportiva profesional" },
                            { val: "Sociedad Colectiva Comercial", text: "Sociedad Colectiva Comercial" },
                            { val: "Sociedad de Responsabilidad Limitada", text: "Sociedad de Responsabilidad Limitada" },
                            { val: "Sociedad En Comandita por acciones", text: "Sociedad En Comandita por acciones" },
                            { val: "Sociedad en comandita simple", text: "Sociedad en comandita simple" },
                            { val: "Sociedad por Acciones (SpA)", text: "Sociedad por Acciones (SpA)" },
                            { val: "Sociedades en Comanditas Civiles", text: "Sociedades en Comanditas Civiles" },
                            { val: "Otra", text: "Otra" }
                          ]
                        })
                      } */}

                      

                      {apiForm.typeSol === 'PJ' && basicInformation.tipoSociedad.val === 'Otra' &&
                        renderFormItemObj({
                        section: basicInformation.tipoSociedadOtra,
                      })}

                      {apiForm.typeSol === 'PJ' &&
                        renderFormItemObj({
                        section: basicInformation.mtrz,
                        options: [
                          {val:"SI",text:"Si"},
                          {val:"NO",text:"No"},
                        ]
                      })
                      }
                    </Row>
                    {basicInformation.mtrz.val === "SI" && apiForm.typeSol === 'PJ' && (
                      <Row gutter={[20]} className="summary" type={format === 'html' ? 'flex' : null}>
                        <Col className="subsection-title" span= {24}>
                          Información Casa Matriz
                          <hr/>
                        </Col>
                          {renderFormItemObj({
                              section: basicInformation.mtrzName,
                            })
                          }

                          {renderFormItemObj({
                              section: basicInformation.mtrzPais,
                              options:
                              paises.map(item => {
                              return {val: item.country, text: item.country}
                            })
                            })
                          }

                          {renderFormItemObj({
                              section: basicInformation.mtrzCity,
                            })
                          }

                          {renderFormItemObj({
                            cols: format === 'pdf' ? 24 : 6,
                            section: basicInformation.mtrzDir,
                          })
                          }
                      </Row>
                    )}
                    <Row gutter={[20]} className="summary" type={format === 'html' ? 'flex' : null}>
                      <Col className="subsection-title" span= {24}>
                        Domicilio
                        <hr/>
                      </Col>

                      {/* FIELD PAIS */}
                      {renderFormItemTable({
                        section: basicInformation.pais,
                        options: paises.map(item => {
                          return {val: item.country, text: item.country, code: item.iso2}
                        }),
                        handlerOnChangeObj: async (id, value, obj, item) => {
                            await handlerOnChangeObjAttr(id, value)
                            if (item && item.props && item.props.code === 'PE') {
                              getRegionComunaPromise(item.props.code).then((response) => {
                                setRegionesPais(response)
                              })
                              await handlerOnChangeObjAttr(basicInformation.codigoPais.id, item.props.code)
                            }else{
                              setRegionesPais([])
                              await handlerOnChangeObjAttr(basicInformation.codigoPais.id, null)
                            }
                            setDistritos([])
                            setProvincias([])
                            setFieldsValue({[basicInformation.provincia.id]: null})
                            setFieldsValue({[basicInformation.departamento.id]: null})
                            setFieldsValue({[basicInformation.distrito.id]: null})

                            await handlerOnChangeObjAttr(basicInformation.provincia.id, null)
                            await handlerOnChangeObjAttr(basicInformation.departamento.id, null)
                            await handlerOnChangeObjAttr(basicInformation.distrito.id, null)

                            await handlerOnChangeObjAttr(basicInformation.codigoDepartamento.id, null)
                            await handlerOnChangeObjAttr(basicInformation.codigoProvincia.id, null)
                            await handlerOnChangeObjAttr(basicInformation.codigoDistrito.id, null)
                        }
                      })}
                      {/* FIELD DEPARTAMENTO */}
                      {renderFormItemTable({
                        section: regionesPais.length > 0 ? basicInformation.departamento : {...basicInformation.departamento, type: 'input', maxLength:30, required: false} ,
                        options: regionesPais.map((item) => {
                          return {val: item.name, text: item.name, code: item.code}
                        }),
                        handlerOnChangeObj: async (id, value, obj, item) => {
                            await handlerOnChangeObjAttr(id, value)
                            if (item && item.props && item.props.code) {
                              let prov = regionesPais.filter(e => e.code === item.props.code)
                              if (prov && prov.length > 0){
                                setProvincias(prov[0].provincias)
                              }else{
                                setProvincias([])
                              }
                              await handlerOnChangeObjAttr(basicInformation.codigoDepartamento.id, item.props.code)
                            }else {
                              setProvincias([])
                              await handlerOnChangeObjAttr(basicInformation.codigoDepartamento.id, null)
                            }
                            setFieldsValue({[basicInformation.provincia.id]: null})

                            await handlerOnChangeObjAttr(basicInformation.provincia.id, null)
                            handlerOnChangeObjAttr(basicInformation.codigoProvincia.id, null)
                        }
                      })}
                      {/* FIELD PROVINCIA */}
                      {renderFormItemTable({
                        section: provincias.length > 0 ? basicInformation.provincia : {...basicInformation.provincia, type: 'input', maxLength:30, required: false} ,
                        options: provincias.map((item) => {
                          let name = camelizerHelper(item.name)
                          let cod = camelizerHelper(item.code)
                          return {val: name, text: name, code: cod}
                        }),
                        handlerOnChangeObj: async (id, value, obj, item) => {
                            await handlerOnChangeObjAttr(id, value)
                            if (item && item.props && item.props.code) {
                              let com = provincias.filter(e => e.code === item.props.code)
                              if (com && com.length > 0){
                                setDistritos(com[0].comunas)
                              }else{
                                setDistritos([])
                              }
                              await handlerOnChangeObjAttr(basicInformation.codigoProvincia.id, item.props.code)
                            }else {
                              setDistritos([])
                              await handlerOnChangeObjAttr(basicInformation.codigoProvincia.id, null)
                            }
                            setFieldsValue({[basicInformation.distrito.id]: null})

                            await handlerOnChangeObjAttr(basicInformation.distrito.id, null)
                            handlerOnChangeObjAttr(basicInformation.codigoDistrito.id, null)
                        }
                      })}
                      {/* FIELD PROVINCIA */}
    
                      {/* FIELD DISTRITO */}
                      {renderFormItemTable({
                        section: distritos.length > 0 ? basicInformation.distrito : {...basicInformation.distrito, type: 'input', maxLength:30, required: false} ,
                        options: distritos.map((item) => {
                          return {val: camelizerHelper(item.name) , text: camelizerHelper(item.name), code: item.code}
                        }),
                        handlerOnChangeObj: async (id, value, obj, item) => {
                          await handlerOnChangeObjAttr(id, value)
                          if (item && item.props && item.props.code) {
                            handlerOnChangeObjAttr(basicInformation.codigoDistrito.id, item.props.code)
                          }else {
                            handlerOnChangeObjAttr(basicInformation.codigoDistrito.id, null)
                          }
                        }
                      })}

                      {renderFormItemObj({
                        cols: format === 'pdf' ? 24 : 6,
                        section: basicInformation.dir,
                      })}
                    </Row>
                    <Row gutter={[20]} className="summary" type={format === 'html' ? 'flex' : null}>
                      <Col className="subsection-title" span= {24}>
                        {apiForm.typeSol === 'PN' ? "Datos de contacto" : "Contacto Comercial"}
                        <hr/>
                      </Col>

                      {apiForm.typeSol === 'PJ' &&
                        renderFormItemObj({
                          section: basicInformation.contactName,
                        })
                      }

                      {apiForm.typeSol === 'PJ' &&
                        renderFormItemObj({
                          section: basicInformation.cargo,
                        })
                      }

                      {renderFormItemObj({
                        section: basicInformation.mail,
                        validator: emailValidator
                      })}

                      {renderFormItemObj({
                        section: basicInformation.tel,
                        validator: numTelValidator
                      })}

                      {renderFormItemObj({
                        section: basicInformation.cel,
                        validator: numCelValidator
                      })}
                    </Row>
                    <Row gutter={[20]} className="summary" type={format === 'html' ? 'flex' : null}>
                      <Col className="subsection-title" span= {24}>
                        Contacto de facturación y pagos (para envío de documentos como OC, HES, entre otros)
                        <hr/>
                      </Col>
                        {renderFormItemObj({
                          section: basicInformation.nomFacturacion,
                        })}

                        {apiForm.typeSol === 'PJ' && 
                          renderFormItemObj({
                          section: basicInformation.cargoFacturacion,
                        })}

                        {renderFormItemObj({
                          section: basicInformation.mailFacturacion,
                          validator: emailValidator
                        })}

                        { renderFormItemObj({
                          section: basicInformation.telFacturacion,
                          validator: numTelValidator
                        })}
                      {renderFormItemObj({
                        section: basicInformation.celFacturacion,
                        validator: numCelValidator
                      })}
                    </Row>
                    {apiForm.typeSol === 'PJ' && (
                      <Row gutter={[20]} className="summary" type={format === 'html' ? 'flex' : null}>
                        <Col className="subsection-title" span= {24}>
                          Información del Representante Legal que firma el documento
                          <hr/>
                        </Col>
                        {renderFormItemObj({
                          section: basicInformation.nombreRepresentante,
                        })}

                        {renderFormItemObj({
                          section: basicInformation.tipoDocRepresentante,
                          options: tipoDocOptions
                        })}

                        {renderFormItemObj({
                            section: basicInformation.docRepresentante,
                            validator: docValidator(basicInformation.tipoDocRepresentante.val, "persona")
                        })}

                        {renderFormItemObj({
                          section: basicInformation.cargoRepresentante,
                        })}
                        {renderFormItemObj({
                          section: basicInformation.isShareholder,
                          options: [
                            {val: 'SI', text: 'Si'},
                            {val: 'NO', text: 'No'},
                          ]
                        })}
                        {renderFormItemObj({
                          section: basicInformation.correoRepresentante,
                          validator: emailValidator
                        })}
                      </Row>
                    )}
                    <Row className="lineamiento subheader">
                      <Col>
                        II. INFORMACIÓN SOBRE ACTIVIDAD ECONÓMICA
                      </Col>
                    </Row>
                    <Row gutter={[20]} className="summary" type={format === 'html' ? 'flex' : null} align="middle">
                      {renderFormItemTable({
                        section: infoActEco.fecInicioAct,
                        handlerOnChangeObj: (id, value) => {
                        handlerOnChangeObjAttr(id, value);
                          if (value) {
                            var now = moment(new Date());
                            var end = moment(value, "DD/MM/YYYY");
                            var duration = moment.duration(now.diff(end));
                            var months = "" + Math.floor(duration.asMonths());
                              if (months < 0) {
                                months = "0";
                              }
                            setFieldsValue({ [infoActEco.experienciaMercado.id]: months });
                            handlerOnChangeObjAttr(infoActEco.experienciaMercado.id, months);
                          }
                        }
                      })}
                      {renderFormItemObj({
                        section: infoActEco.experienciaMercado,
                      })}
                    </Row>
                    <div className="content">
                      {format === "html" && !signed && (
                        <>
                        <Row className="summary" gutter={[20]} type={format === 'html' ? 'flex' : null} align="middle">
                            {renderFormItemTable({
                              section: prodActObj.actEconomica,
                              cols: 19,
                              options: SIIactivities.map(item => {
                                return {val: item.codigo+"|"+ camelizerHelper(item.descripcion), text: item.codigo+" - "+ camelizerHelper(item.descripcion)}
                              }),
                            })}
                            <Col className="button-col" xl={2}>
                              <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTableLocal('prodActObj', 'infoActEco', 'actividadesRecords')} icon="plus" > Añadir </Button>
                            </Col>
                            <Col className="button-col" xl={2}>
                              <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('prodActObj')}> Limpiar </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                      {infoActEco.actividadesRecords.val.length > 0 ?
                        <Row className="summary">
                          <Table columns={actEcoColumns} dataSource={infoActEco.actividadesRecords.val} size="middle" pagination={false} />
                        </Row>
                        : infoActEco.actividadesRecords.val.length < 1 &&
                          validarRegistros && (
                            <Row>
                              <Col
                                span={24}
                                style= {{color: 'red'}}
                                className="missing-registers ant-form-explain"
                              >
                                {t("messages.aml.registersRequired")}
                              </Col>
                            </Row>
                      )}

                        {/* {format === "html" && !signed && basicInformation.propositoRel.val === 'Donación' && (
                          <>
                            <Row gutter={[20]} className="summary" type={format === 'html' ? 'flex' : null} align="middle">
                              {renderFormItemTable({
                                section: donacionActObj.donacion,
                                cols: 19,
                                options: SIIactivities.filter(item => item.codigo === "949100" || item.codigo === "949200" || item.codigo === "949901" || item.codigo === "949902" || item.codigo === "949903" || item.codigo === "949909" )
                                .map(item => {
                                  return {val: item.codigo+"|"+ item.descripcion, text: item.codigo+" - "+ item.descripcion}
                                }),
                              })}
                              <Col className="button-col" xl={2}>
                                <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTableLocal('donacionActObj', 'infoActEco', 'donacionesRecords')} icon="plus" > Añadir </Button>
                              </Col>
                              <Col className="button-col" xl={2}>
                                <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('donacionActObj')}> Limpiar </Button>
                              </Col>
                            </Row>
                          </>
                        )} */}
                      {/* {infoActEco.donacionesRecords.val.length > 0 ?
                        <Row className="summary">
                          <Table columns={donacionColumns} dataSource={infoActEco.donacionesRecords.val} size="middle" pagination={false} />
                        </Row>
                        : infoActEco.donacionesRecords.val.length < 1 &&
                          validarRegistros && basicInformation.propositoRel.val === 'Donación' && (
                            <Row>
                              <Col
                                span={24}
                                style= {{color: 'red'}}
                                className="missing-registers ant-form-explain"
                              >
                                {t("messages.aml.registersRequired")}
                              </Col>
                            </Row>
                        )} */}
                        
                    </div>
                      
                    <Row className="subheader">
                      <Col xl={24}>
                        III. INFORMACIÓN BANCARIA
                      </Col>
                    </Row>
                    <Row className="summary">
                      A continuación complete la información bancaria correspondiente, en caso utilice Bancos Internacionales señale el código SWIFT / IBAN
                    </Row>
                    <Row className="summary" gutter={[20]} type={format === 'html' ? 'flex' : null}>
                      {renderFormItemObj({
                        section: infoBancaria.tipoProducto,
                        options: [
                          { val: "Cuenta de Ahorros", text: "Cuenta de Ahorros" },
                          { val: "Cuenta Corriente", text: "Cuenta Corriente" }
                        ]
                      })}
                      {renderFormItemObj({
                        section: infoBancaria.tipoMoneda,
                        options: [
                          { val: "PEN", text: "PEN" },
                          { val: "USD", text: "USD" },
                          apiForm.typeSol === 'PJ' && { val: "EURO", text: "EURO" }
                        ].filter(Boolean)
                      })}
                      {renderFormItemObj({
                        section: infoBancaria.pais,
                        options: paises.map(item => {
                          return {val: item.country, text: item.country}
                        })
                      })}
                      {renderFormItemObj({
                        section: infoBancaria.nroCuenta,
                      })}
                      {renderFormItemObj({
                        section: infoBancaria.codSwift,
                      })}
                      {renderFormItemObj({
                        section: infoBancaria.cci,
                      })}
                      {renderFormItemObj({
                        section: infoBancaria.banco,
                        options: banksList
                      })}
                      {infoBancaria.banco.val === 'Otro' && renderFormItemObj({
                        section: infoBancaria.otroBanco,
                      })}
                      {apiForm.typeSol === 'PN' && renderFormItemObj({
                        section: infoBancaria.hasFactura,
                        options: [
                          {val: 'Si', text: 'Si'},
                          {val: 'No', text: 'No'}
                        ]
                      })}
                      {(apiForm.typeSol === 'PJ' || infoBancaria.hasFactura.val === 'Si') && renderFormItemObj({
                        section: infoBancaria.cuentaDetraccion
                      })}
                    </Row>
                  <Row className="subheader">
                    <Col xl={24}>
                      IV. PERSONA EXPUESTA POLÍTICAMENTE O PÚBLICAMENTE (PEP)
                    </Col>
                  </Row>
                  <Row className="summary">
                    <Col xl={24}>
                      {apiForm.typeSol === 'PN' ?
                      <div>
                        Los PEP son personas naturales, nacionales o extranjeras, que cumplen o que en los últimos cinco (5) años hayan cumplido funciones públicas destacadas o funciones prominentes en una organización internacional, sea en el territorio nacional o extranjero, y cuyas circunstancias financieras puedan ser objeto de un interés. Asimismo, se considera como PEP al colaborador directo de la máxima autoridad de la institución. Será considerado bajo la presente definición a aquellas personas que se encuentren postulando a cualquier cargo de función pública, sea este designado por voto popular o por cualquier otro medio. También serán considerados como PEP al cónyuge o conviviente, y familiares hasta segundo grado de consanguinidad y segundo grado de afinidad. Por ejemplo: jefes de estado, funcionarios públicos, políticos, funcionarios gubernamentales, altos ejecutivos de empresas estatales, candidatos, funcionarios de partidos políticos.
                        <br/><br/>
                        Yo, declaro que:
                      </div>
                      :
                      <div>
                        <p>
                          Los PEP son personas naturales, nacionales o extranjeras, que cumplen o que en los últimos cinco (5) años hayan cumplido funciones públicas destacadas o funciones prominentes en una organización internacional, sea en el territorio nacional o extranjero, y cuyas circunstancias financieras puedan ser objeto de un interés. Asimismo, se considera como PEP al colaborador directo de la máxima autoridad de la institución. Será considerado bajo la presente definición a aquellas personas que se encuentren postulando a cualquier cargo de función pública, sea este designado por voto popular o por cualquier otro medio. También serán considerados como PEP al cónyuge o conviviente, y familiares hasta segundo grado de consanguinidad y segundo grado de afinidad. Por ejemplo: jefes de estado, funcionarios públicos, políticos, funcionarios gubernamentales, altos ejecutivos de empresas estatales, candidatos, funcionarios de partidos políticos.
                        </p>
                          Los cargos considerados dentro del Grupo relacionado, son los siguientes:
                        <br/><br/>
                        <ul>
                          <li>
                            Representante Legal
                          </li>
                          <li>
                            Alta Gerencia
                          </li>
                          <li>
                            Accionista
                          </li>
                          <li>
                            Directores
                          </li>
                        </ul>
                        En nombre de la empresa que represento y sus entidades relacionadas, declaro que:
                      </div>
                      }
                    </Col>
                  </Row>
                  <Row className="summary">
                    <Col span={20}>
                      <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                        <u>PREGUNTA 1:</u> {apiForm.typeSol === 'PN' ? 
                        'Me desempeño actualmente o he desempeñado en los últimos 5 años, alguno de los cargos indicados en Funciones Públicas destacadas.'
                        : 
                        'Una o más personas consideradas dentro del grupo relacionado, desempeñan actualmente o han desempeñado en los últimos 5 años, alguno de los cargos indicados en Funciones Públicas destacadas.'
                        }
                      </div>
                    </Col>
                    {renderFormItem({
                      label: "",
                      colClassName: "switch-col",
                      itemClassName: "radio-item-flat",
                      name: infoPep.isPublic.id,
                      labelCol: 0,
                      wrapperCol: 0,
                      offset: 1,
                      initialValue: infoPep.isPublic.val,
                      rules: [
                        {
                          required: true,
                          message: t("messages.aml.dontForgetSelect"),
                        },
                      ],
                      wrapperCols: 3,
                      item: (
                        <Radio.Group
                          onChange={({ target }) =>
                            handlerOnChangeObjAttr(
                              infoPep.isPublic.id,
                              target.value
                            )
                          }
                        >
                          <Radio style={radioStyle} value={true}>
                            Sí
                          </Radio>
                          <Radio style={radioStyle} value={false}>
                            No
                          </Radio>
                        </Radio.Group>
                      ),
                    })
                    }
                  </Row>
                  {infoPep.isPublic.val && (
                      <div className="content">
                        {format === "html" && !signed && (
                          <>
                            {apiForm.typeSol === 'PJ' ?
                              <Row gutter={[20]} className="summary" type={format === 'html' ? 'flex' : null}>
                                <Col className="subsection-title" span= {24}>
                                  Datos de la persona relacionada
                                  <hr/>
                                </Col>
                                {renderFormItemTable({
                                  section: pepPublicObj.grupo,
                                  options:[
                                    {val: "Representante Legal", text: "Representante Legal"},
                                    {val: "Alta Gerencia", text: "Alta Gerencia"},
                                    {val: "Accionista", text: "Accionista"},
                                    {val: "Directores", text: "Directores"},
                                  ],
                                  cols: 6
                                })}

                                {renderFormItemTable({
                                  section: pepPublicObj.name,
                                  cols: 6
                                })}

                                {renderFormItemTable({
                                  section: pepPublicObj.tipoId,
                                  options: tipoDocOptions,
                                  cols: 6
                                })}

                                {renderFormItemTable({
                                  section: pepPublicObj.nroIdentificacion,
                                  validator: docValidator(pepPublicObj.tipoId.val, "persona"),
                                  cols: 6
                                })}

                                {renderFormItemTable({
                                  section: pepPublicObj.orgPublic,
                                  cols: 6
                                })}

                                {renderFormItemTable({
                                  section: pepPublicObj.pais,
                                  cols: 6,
                                  options:
                                  paises.map(item => {
                                    return {val: item.country, text: item.country}
                                  })
                                })}

                                {renderFormItemTable({
                                  section: pepPublicObj.cargo,
                                  customTitle: 'Cargo del PEP',
                                  cols: 6
                                })}

                                {renderFormItemTable({
                                  section: pepPublicObj.fecTermino,
                                  cols: 6
                                })}
                              </Row>
                            :
                              <Row gutter={[20]} className="summary" type={format === 'html' ? 'flex' : null}>
                                <Col className="subsection-title" span= {24}>
                                    Datos de la persona relacionada
                                    <hr/>
                                </Col>
                                {renderFormItemTable({
                                  section: pepPublicObj.cargoPerNat,
                                  cols: 6
                                })}

                                {renderFormItemTable({
                                  section: pepPublicObj.orgPublicPerNat,
                                  cols: 6
                                })}

                                {renderFormItemTable({
                                  section: pepPublicObj.paisPerNat,
                                  cols: 6,
                                  options:
                                  paises.map(item => {
                                    return {val: item.country, text: item.country}
                                  })
                                })}

                                {renderFormItemTable({
                                  section: pepPublicObj.fecTerminoPerNat,
                                  cols: 6
                                })}
                              </Row>
                            }
                            <Row className="button-row">
                              {infoPep.publicRecords.val.length < 1 &&
                                validarRegistros && (
                                  <Col
                                    span={24}
                                    style= {{color: 'red'}}
                                    className="missing-registers ant-form-explain"
                                  >
                                    {t("messages.aml.registersRequired")}
                                  </Col>
                                )}

                              <Col className="addRelation" xl={3}>
                                <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTable('pepPublicObj', 'infoPep', 'publicRecords')} icon="plus" > Añadir </Button>
                              </Col>
                              <Col className="addRelation" xl={3}>
                                <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('pepPublicObj')}> Limpiar </Button>
                              </Col>
                            </Row>
                          </>
                        )}

                        {infoPep.publicRecords.val.length > 0 && format === "html" ?
                          <Row className="summary">
                            <Table columns={getPepPublicColumns()} dataSource={infoPep.publicRecords.val} size="middle" pagination={false}/>
                          </Row>
                          :
                          toDescriptionsPdf( infoPep.publicRecords.val, pepPublicObj, apiForm.typeSol )
                        }
                      </div>
                  )}

                  <Row className="summary">
                    <Col span={20}>
                      {apiForm.typeSol === 'PN' ?
                        <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                          <u>PREGUNTA 2:</u> Soy cónyuge(s) o pariente(s) hasta segundo grado de consanguinidad (abuelo(a), padre, madre, hijo(a), hermano(a), nieto(a)) o afinidad (abuelos y abuelas del cónyuge, Nietos y nietas del cónyuge. Hermanos y hermanas del cónyuge (cuñados)) de personas que se desempeñan actualmente o hayan desempeñado, en los últimos 5 años, alguno de los cargos indicados en Funciones Públicas destacadas.
                        </div>
                        :
                        <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                          <u>PREGUNTA 2:</u> Una o más personas consideradas dentro del grupo relacionado, son cónyuge(s) o pariente(s) hasta segundo grado de consanguinidad (abuelo(a), padre, madre, hijo(a), hermano(a), nieto(a)) o afinidad (abuelo(a) del cónyuge, nieto(a) del cónyuge, padres, hermano (a) del cónyuge (cuñados)) de personas que se desempeñan actualmente o hayan desempeñado en los últimos 5 años, en alguno de los cargos indicados en Funciones Públicas destacadas.
                        </div>
                      }
                    </Col>
                    {renderFormItem({
                      label: "",
                      colClassName: "switch-col",
                      itemClassName: "radio-item-flat",
                      name: infoPep.hasFamily.id,
                      labelCol: 0,
                      wrapperCol: 0,
                      offset: 1,
                      initialValue: infoPep.hasFamily.val,
                      rules: [
                        {
                          required: true,
                          message: t("messages.aml.dontForgetSelect"),
                        },
                      ],
                      wrapperCols: 3,
                      item: (
                        <Radio.Group
                          onChange={({ target }) =>
                            handlerOnChangeObjAttr(
                              infoPep.hasFamily.id,
                              target.value
                            )
                          }
                        >
                          <Radio style={radioStyle} value={true}>
                            Sí
                          </Radio>
                          <Radio style={radioStyle} value={false}>
                            No
                          </Radio>
                        </Radio.Group>
                      ),
                    })}
                  </Row>

                  {infoPep.hasFamily.val && (
                    <div className="content">
                      {format === "html" && !signed && (
                        <>
                        {apiForm.typeSol === 'PJ' ?
                          <Row gutter={[20]} className="summary" type={format === 'html' ? 'flex' : null}>
                            <Col className="subsection-title" span= {24}>
                                Datos de la persona relacionada
                                <hr/>
                            </Col>

                            {renderFormItemTable({
                              section: pepFamilyObj.grupo,
                              options:[
                                {val: "Representante Legal", text: "Representante Legal"},
                                {val: "Alta Gerencia", text: "Alta Gerencia"},
                                {val: "Accionista", text: "Accionista"},
                                {val: "Directores", text: "Directores"},
                              ],
                              cols: 6
                            })}

                            {renderFormItemTable({
                              section: pepFamilyObj.name,
                              cols: 6
                            })}

                            {renderFormItemTable({
                              section: pepFamilyObj.tipoDoc,
                              cols: 6,
                              options: tipoDocOptions
                            })}

                            {renderFormItemTable({
                              section: pepFamilyObj.nroId,
                              validator: docValidator(pepFamilyObj.tipoDoc.val, "persona"),
                              cols: 6
                            })}

                            <Col className="subsection-title" span= {24}>
                                Datos del pariente que posee el cargo de PEP
                                <hr/>
                            </Col>

                            {renderFormItemTable({
                              section: pepFamilyObj.parentesco,
                              cols: 6,
                              options: relationshipOptionsParauco
                            })}

                            {renderFormItemTable({
                              section: pepFamilyObj.nombrePariente,
                              cols: 6
                            })}

                            {renderFormItemTable({
                              section: pepFamilyObj.tipoDocPariente,
                              cols: 6,
                              options: tipoDocOptions
                            })}

                            {renderFormItemTable({
                              section: pepFamilyObj.nroIdPariente,
                              validator: docValidator(pepFamilyObj.tipoDocPariente.val, "persona"),
                              cols: 6
                            })}

                            {renderFormItemTable({
                              section: pepFamilyObj.orgPublic,
                              cols: 6
                            })}
                            {renderFormItemTable({
                              section: pepFamilyObj.pais,
                              cols: 6,
                              options:
                              paises.map(item => {
                                return {val: item.country, text: item.country}
                              })
                            })}

                            {renderFormItemTable({
                              section: pepFamilyObj.cargo,
                              cols: 6
                            })}

                            {renderFormItemTable({
                              section: pepFamilyObj.fecTermino,
                              cols: 6
                            })}
                          </Row>
                        :
                        <Row className="summary" gutter={[20]} type={format === 'html' ? 'flex' : null}>
                          {renderFormItemTable({
                            section: pepFamilyObj.parentescoPerNat,
                            cols: 6,
                            options: relationshipOptionsParauco
                          })}

                          {renderFormItemTable({
                            section: pepFamilyObj.namePerNat,
                            cols: 6
                          })}

                          {renderFormItemTable({
                            section: pepFamilyObj.tipoDocPerNat,
                            cols: 6,
                            options: tipoDocOptions
                          })}

                          {renderFormItemTable({
                            section: pepFamilyObj.nroIdPerNat,
                            validator: docValidator(pepFamilyObj.tipoDocPerNat.val, "persona"),
                            cols: 6
                          })}

                          {renderFormItemTable({
                            section: pepFamilyObj.cargoPerNat,
                            cols: 6
                          })}

                          {renderFormItemTable({
                            section: pepFamilyObj.orgPublicPerNat,
                            cols: 6
                          })}

                          {renderFormItemTable({
                            section: pepFamilyObj.paisPerNat,
                            cols: 6,
                            options:
                            paises.map(item => {
                              return {val: item.country, text: item.country}
                            })
                          })}

                          {renderFormItemTable({
                            section: pepFamilyObj.fecTerminoPerNat,
                            cols: 6
                          })}
                        </Row>
                        }

                          <Row className="button-row">
                            {infoPep.familyRecords.val.length < 1 &&
                              validarRegistros && (
                                <Col
                                  span={24}
                                  style= {{color: 'red'}}
                                  className="missing-registers ant-form-explain"
                                >
                                  {t("messages.aml.registersRequired")}
                                </Col>
                              )}

                            <Col className="addRelation" xl={3}>
                              <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTable('pepFamilyObj', 'infoPep', 'familyRecords')} icon="plus" > Añadir </Button>
                            </Col>
                            <Col className="addRelation" xl={3}>
                              <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('pepFamilyObj')}> Limpiar </Button>
                            </Col>
                          </Row>
                        </>
                      )}

                      {infoPep.familyRecords.val.length > 0 && format === "html" ?
                        <Row className="summary">
                          <Table columns={getPepFamilyColumns()} dataSource={infoPep.familyRecords.val} size="middle" pagination={false} ></Table>
                        </Row>
                        :
                        toDescriptionsPdf( infoPep.familyRecords.val, pepFamilyObj, apiForm.typeSol )
                      }
                    </div>
                  )}

                  {/* <Row className="summary">
                    <Col span={20}>
                      {apiForm.typeSol === 'PN' ?
                      <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                        <u>PREGUNTA 3:</u> He celebrado un pacto de actuación conjunta que otorgue poder de voto suficiente para influir en sociedades constituidas en Chile, con una persona que desempeñe actualmente o haya desempeñado en el último año, alguno de los cargos indicados en Funciones Públicas destacadas.
                      </div>
                      :
                      <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                        <u>PREGUNTA 3:</u> Una o más personas consideradas dentro del grupo relacionado, han celebrado un pacto de actuación conjunta que otorgue poder de voto suficiente para influir en sociedades constituidas en Chile, con una persona que desempeñe actualmente o haya desempeñado en el último año, en alguno de los cargos indicados en Funciones Públicas destacadas.
                      </div>
                      }
                    </Col>
                    {renderFormItem({
                      label: "",
                      colClassName: "switch-col",
                      itemClassName: "radio-item-flat",
                      name: infoPep.hasRelation.id,
                      labelCol: 0,
                      wrapperCol: 0,
                      offset: 1,
                      initialValue: infoPep.hasRelation.val,
                      rules: [
                        {
                          required: true,
                          message: t("messages.aml.dontForgetSelect"),
                        },
                      ],
                      wrapperCols: 3,
                      item: (
                        <Radio.Group
                          onChange={({ target }) =>
                            handlerOnChangeObjAttr(
                              infoPep.hasRelation.id,
                              target.value
                            )
                          }
                        >
                          <Radio style={radioStyle} value={true}>
                            Sí
                          </Radio>
                          <Radio style={radioStyle} value={false}>
                            No
                          </Radio>
                        </Radio.Group>
                      ),
                    })}
                  </Row> */}

                  {/* {infoPep.hasRelation.val && (
                      <div className="content">
                        {format === "html" && !signed && (
                          <>
                            {apiForm.typeSol === 'PJ' ?
                              <Row className="summary" gutter={[20]} type={format === 'html' ? 'flex' : null}>
                                  <Col className="subsection-title" span= {24}>
                                      Datos de la persona relacionada
                                      <hr/>
                                  </Col>
                                    {renderFormItemTable({
                                      section: pepRelationObj.grupo,
                                      options:[
                                        {val: "Rep. Legal", text: "Representante Legal"},
                                        {val: "Ben. Final", text: "Beneficiario Final"},
                                        {val: "Controlador Efectivo", text: "Controlador Efectivo"},
                                      ],
                                      cols: 6
                                    })}

                                    {renderFormItemTable({
                                      section: pepRelationObj.name,
                                      cols: 6
                                    })}

                                    {renderFormItemTable({
                                      section: pepRelationObj.tipoId,
                                      options: tipoDocOptions,
                                      cols: 6
                                    })}

                                    {renderFormItemTable({
                                      section: pepRelationObj.nroIdentificacion,
                                      validator: docValidator(pepRelationObj.tipoId.val, "persona"),
                                      cols: 6
                                    })}

                                  <Col className="subsection-title" span= {24}>
                                    Datos de la persona con quien se tiene el pacto de actuación conjunta y que posee el cargo de PEP
                                    <hr/>
                                  </Col>
                                  {renderFormItemTable({
                                    section: pepRelationObj.namePac,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.tipoDocPac,
                                    options: tipoDocOptions,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.nroIdPac,
                                    validator: docValidator(pepRelationObj.tipoDocPac.val, "persona"),
                                    cols: 6,
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.orgPublico,
                                    cols: 6,
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.pais,
                                    options:
                                    paises.map(item => {
                                      return {val: item.country, text: item.country}
                                    }),
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.cargoPac,
                                    customTitle: "Cargo del PEP",
                                    cols: 6,
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.fecTermino,
                                    cols: 6,
                                  })}

                                  <Col className="subsection-title" span= {24}>
                                    Datos de la empresa que dio origen al pacto de actuación conjunta
                                    <hr/>
                                  </Col>

                                  {renderFormItemTable({
                                    section: pepRelationObj.nameEmp,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.tipoIdEmp,
                                    options: tipoDocOptions,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.nroIdEmp,
                                    validator: docValidator(pepRelationObj.tipoIdEmp.val, "empresa"),
                                    cols: 6,
                                  })}
                            
                                </Row>
                            :
                                <Row className="summary" gutter={[20]} type={format === 'html' ? 'flex' : null}>
                                  <Col className="subsection-title" span= {24}>
                                    Datos de la empresa que dio origen al pacto de actuación conjunta
                                    <hr/>
                                  </Col>
                                  {renderFormItemTable({
                                    section: pepRelationObj.empresaPerNat,
                                    cols: 6
                                  })}
                                  {renderFormItemTable({
                                    section: pepRelationObj.tipoIdEmpPerNat,
                                    options: tipoDocOptions,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.nroIdEmpPerNat,
                                    validator: docValidator(pepRelationObj.tipoIdEmpPerNat.val, "empresa"),
                                    cols: 6,
                                  })}
                                  <Col className="subsection-title" span= {24}>
                                    Datos de la persona con quien se tiene el pacto de actuación conjunta y que posee el cargo de PEP
                                    <hr/>
                                  </Col>
                                 
                                  {renderFormItemTable({
                                    section: pepRelationObj.pepNamePerNat,
                                    cols: 6,
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.tipoDocPepPerNat,
                                    options: tipoDocOptions,
                                    cols: 6,
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.nroIdPepPerNat,
                                    validator: docValidator(pepRelationObj.tipoDocPepPerNat.val, "persona"),
                                    cols: 6,
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.cargoPepPerNat,
                                    cols: 6,
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.orgPublicoPerNat,
                                    cols: 6,
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.paisPerNat,
                                    options:
                                    paises.map(item => {
                                      return {val: item.country, text: item.country}
                                    }),
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: pepRelationObj.fecTerminoPerNat,
                                    cols: 6,
                                  })}
                                  
                                </Row>
                            }

                            <Row className="button-row">
                              {infoPep.relationRecords.val.length < 1 &&
                                validarRegistros && (
                                  <Col
                                    span={24}
                                    style= {{color: 'red'}}
                                    className="missing-registers ant-form-explain"
                                  >
                                    {t("messages.aml.registersRequired")}
                                  </Col>
                                )}

                              <Col className="addRelation" xl={3}>
                                <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTable('pepRelationObj', 'infoPep', 'relationRecords')} icon="plus" > Añadir </Button>
                              </Col>
                              <Col className="addRelation" xl={3}>
                                <Button type="primary" htmlType="button" icon="delete" onClick={()=>handleOnClear('pepRelationObj')}> Limpiar </Button>
                              </Col>
                            </Row>
                          </>
                        )}

                        {infoPep.relationRecords.val.length > 0 && format === "html" ?
                          <Row className="summary">
                            <Table columns={getPepRelationColumns()} dataSource={infoPep.relationRecords.val} size="middle" pagination={false} />
                          </Row>
                          :
                          toDescriptionsPdf( infoPep.relationRecords.val, pepRelationObj, apiForm.typeSol )
                        }
                      </div>
                      )}   */}

                      <Row className="summary">
                        <Col span={21}>
                          <strong>Funciones públicas destacadas:</strong>
                          <br/><br/>
                          <ol>
                            <li> Presidentes (jefes de estado o de gobierno), Presidente del Consejo de Ministros, ministros, viceministros y secretarios generales del poder ejecutivo.</li>
                            <li> Gobernadores regionales y alcaldes o máxima autoridad de gobiernos regionales y locales, vicegobernadores regionales, regidores y gerentes de los gobiernos regionales y locales.</li>
                            <li> Congresistas de la República/Parlamento/Poder Legislativo, miembros de la oficialía mayor del Congreso.</li>
                            <li> Presidente del Poder Judicial y Presidente de la Corte Suprema de Justicia; jueces supremos, jueces superiores (vocales), jueces especializados o mixtos, gerente general, secretario general y cargos equivalentes en el fuero militar.</li>
                            <li> Fiscal de la Nación, fiscales supremos, fiscales adjuntos supremos, fiscales superiores, fiscales adjuntos superiores y secretario general del Ministerio Público - Fiscalía de la Nación. </li>
                            <li> Embajadores y cónsules.</li>
                            <li> Oficiales de más alto rango de las fuerzas armadas y de las fuerzas de seguridad pública.</li>
                            <li> Jefes o cargos similares y miembros de los órganos de dirección y control de empresas de propiedad estatal.</li>
                            <li> Los funcionarios o empleados con categoría o función no inferior a la de director general o nacional, de entidades de la administración pública nacional, centralizada o descentralizada.</li>
                            <li> La máxima autoridad, secretarios generales y jefes de gabinete de organismos públicos reguladores, supervisores, especializados y/o ejecutores.</li>
                            <li> La máxima autoridad, secretarios generales y jefes de gabinete de asesores de organismos constitucionalmente autónomos.</li>
                            <li> Jefes de logística, o funcionarios o empleados públicos encargados de las contrataciones y adquisiciones, en el marco de la normativa aplicable en materia de contrataciones con el Estado.</li>
                            <li> Jefes, directores, directores adjuntos, miembros de la junta directiva o cargos similares de organismos internacionales. Debe entenderse por organizaciones internacionales a aquellas entidades establecidas mediante acuerdos políticos oficiales entre los distintos Estados, los cuales tienen el estatus de tratados internacionales, su existencia es reconocida por ley en sus propios Estados y que dichas organizaciones se puedan diferenciar del país en donde se encuentren radicadas, tales como la Organización de las Naciones Unidas, la Organización Mundial del Comercio, etc.</li>
                          </ol>
                          <p>
                            También se considera como personas expuestas políticamente a los colaboradores directos de la máxima autoridad de la institución u organización que cuente con facultades de decisión resolutiva, por lo tanto se excluye a los funcionarios de niveles intermedios o inferiores. 
                          </p>
                        </Col>
                      </Row>
                      <Row
                        className="lineamiento subheader"
                      >
                        <Col xl={24}>V. REPRESENTACIÓN ANTE FUNCIONARIOS O SERVIDORES PÚBLICOS</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={20}>
                          ¿Representará a {clientName} y filiales ante funcionarios o servidores públicos?
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item-flat",
                          name: representacionFuncPublic.isRepresentative.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 1,
                          initialValue: representacionFuncPublic.isRepresentative.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(
                                  representacionFuncPublic.isRepresentative.id,
                                  target.value
                                )
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })}
                      </Row>
                      {representacionFuncPublic.isRepresentative.val && (
                        <Row className="summary">
                          {!signed && (
                            apiForm.typeSol === 'PN' ?
                            <div>
                              <p>
                                En caso afirmativo, el Proveedor toma conocimiento que tendrá especialmente prohibido:
                              </p>
                              <ol type="a">
                                <li>
                                  Realizar cualquier actividad que pueda relacionarse con delitos de corrupción, colusión o tráfico de influencias. Tal prohibición se hará especialmente exigible en aquellos casos en que el proveedor actúe en representación de {clientName} y filiales o, en general, si gestiona asuntos suyos frente a terceros, con o sin representación.
                                </li>
                                <li>
                                  No efectuar a nombre de {clientName} y filiales, o en beneficio de ellas, cualquier especie de pago indebido o ilegítimo a funcionarios de la administración pública, y dar cumplimiento efectivo a la Política de Interacción con Funcionarios Públicos de {clientName} y filiales, disponible en la página web www.parauco.com
                                </li>
                                <li>
                                  Incumplir o entorpecer el correcto funcionamiento de los procedimientos de administración y auditoría que permitan a {clientName} prevenir en la comisión de delitos.
                                </li>
                              </ol>
                            </div>
                            :
                            <div>
                              <p>
                                En caso afirmativo, el Proveedor declara que:
                              </p>
                              <p>
                                Ha diseñado y efectivamente implementado un modelo de prevención de delitos conforme la Ley N°30424  sobre responsabilidad administrativa en el proceso penal, adecuado a su objeto social, giro, tamaño, complejidad, recursos y a las actividades que desarrolla. En los demás casos, el Proveedor declara que, al menos, ha establecido protocolos y procedimientos para prevenir y detectar conductas delictivas en el contexto de las actividades que desarrolla.
                              </p>
                              <p>
                                El Proveedor toma conocimiento de que tendrá especialmente prohibido: 
                              </p>
                              <ol type="a">
                                <li>
                                  Realizar cualquier actividad que pueda relacionarse con delitos de corrupción, colusión o tráfico de influencias e incurrir en ellos, especialmente aquellos de la ley N° 30424, e incurrir en ellos. Tal prohibición se hará especialmente exigible en aquellos casos en que el prestador actúe en representación de {clientName} y filiales o, en general, si gestiona asuntos suyos frente a terceros, con o sin representación.
                                </li>
                                <li>
                                  No efectuar a nombre de {clientName} y filiales, o en beneficio de ellas, cualquier especie de pago indebido o ilegítimo a funcionarios de la administración pública, y dar cumplimiento efectivo a la Política de Interacción con Funcionarios Públicos de {clientName} y filiales, disponible en la página web www.parauco.com
                                </li>
                                <li>
                                  Incumplir o entorpecer el correcto funcionamiento de los procedimientos de administración y auditoría recursos financieros que permiten a la Compañía prevenir su utilización en la comisión de delitos.
                                </li>
                              </ol>
                            </div>
                          )}
                        </Row>
                      )}
                      <Row
                        className="lineamiento subheader"
                      >
                        <Col xl={24}>VI. CONFLICTO DE INTERÉS</Col>
                      </Row>
                      <Row className="summary">
                        <Col span={24}>
                        {apiForm.typeSol === 'PN' ?
                          <div>
                            Un conflicto de interés, se entenderá, entre otros como: “La incompatibilidad que se produce en todo acto o negociación entre {clientName} y un tercero, sea éste persona natural o jurídica, cuando entre este último y quien evalúa, realiza o decide dicho acto y/o participa en la administración, supervisión, control o fiscalización de los procesos derivados del mismo, existen relaciones de negocios, parentesco o afectividad, que hagan presumir falta de independencia o imparcialidad, todo lo cual potencia la posibilidad de beneficiar, directa o indirectamente, e indebida e indistintamente, a la persona relacionada participante con trabajadores en alguno de los actos antes descritos”.
                          </div>
                          :
                          <div>
                            Un conflicto de interés, se entenderá, entre otros como: “La incompatibilidad que se produce en todo acto o negociación entre {clientName} y un tercero, sea éste persona natural o jurídica, cuando entre este último y quien evalúa, realiza o decide dicho acto y/o participa en la administración, supervisión, control o fiscalización de los procesos derivados del mismo, existen relaciones de negocios, parentesco o afectividad, que hagan presumir falta de independencia o imparcialidad, todo lo cual potencia la posibilidad de beneficiar, directa o indirectamente, e indebida e indistintamente, a la persona relacionada y/o al personal de la Empresa participante con trabajadores en alguno de los actos antes descritos”.
                          </div>
                        }
                        </Col>
                      </Row>
                      <Row className="summary">
                        <Col span={20}>
                        {apiForm.typeSol === 'PN' ? 
                          <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                            ¿Posee un vínculo de parentesco hasta segundo grado de consanguinidad (abuelo(a), padre, madre, hijo(a), hermano(a), nieto(a)) o afinidad (abuelos y abuelas del cónyuge, Nietos y nietas del cónyuge. Hermanos y hermanas del cónyuge (cuñados)) con algún Gerente, Ejecutivo Principal o Director de {clientName} y filiales? 
                          </div>
                          :
                          <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                            ¿Su organización posee algún miembro de la alta dirección en un cargo de responsabilidad que <strong>posee un vínculo de parentesco hasta segundo grado de consanguinidad (abuelo(a), padre, madre, hijo(a), hermano(a), nieto(a)) o afinidad (Abuelos y abuelas del cónyuge, Nietos y nietas del cónyuge. Hermanos y hermanas del cónyuge (cuñados))</strong> con algún Gerente, Ejecutivo Principal o Director de {clientName} y filiales? 
                          </div>
                        }
                        </Col>
                        {renderFormItem({
                          label: "",
                          colClassName: "switch-col",
                          itemClassName: "radio-item-flat",
                          name: conflictoInteres.hasFamily.id,
                          labelCol: 0,
                          wrapperCol: 0,
                          offset: 1,
                          initialValue: conflictoInteres.hasFamily.val,
                          rules: [
                            {
                              required: true,
                              message: t("messages.aml.dontForgetSelect"),
                            },
                          ],
                          wrapperCols: 3,
                          item: (
                            <Radio.Group
                              onChange={({ target }) =>
                                handlerOnChangeObjAttr(
                                  conflictoInteres.hasFamily.id,
                                  target.value
                                )
                              }
                            >
                              <Radio style={radioStyle} value={true}>
                                Sí
                              </Radio>
                              <Radio style={radioStyle} value={false}>
                                No
                              </Radio>
                            </Radio.Group>
                          ),
                        })
                        }
                      </Row>
                      {conflictoInteres.hasFamily.val && (
                          <div className="content">
                            {format === "html" && !signed && (
                              <>
                               
                                  {apiForm.typeSol === 'PN' ?
                                    <Row className="summary" gutter={[20]} type={format === 'html' ? 'flex' : null}>
                                      <Col className="subsection-title" span= {24}>
                                        Datos de la persona relacionada a {clientName}
                                        <hr/>
                                      </Col>
                                    
                                      {renderFormItemTable({
                                        section: conFamilyObj.name,
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: conFamilyObj.tipoId,
                                        options: tipoDocOptions,
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: conFamilyObj.nroIdentificacion,
                                        validator: docValidator(conFamilyObj.tipoId.val, "persona"),
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: conFamilyObj.cargo,
                                        cols: 6
                                      })}
                                      {renderFormItemTable({
                                        section: conFamilyObj.parentesco,
                                        options: relationshipOptionsParauco,
                                        cols: 6,
                                      })}
                                  
                                    </Row>
                                  :
                                    <Row  className="summary" gutter={[20]} type={format === 'html' ? 'flex' : null}>
                                      <Col className="subsection-title" span= {24}>
                                        Datos de la persona relacionada en la empresa proveedora
                                        <hr/>
                                      </Col>
                                        {renderFormItemTable({
                                          section: conFamilyObj.name,
                                          cols: 6
                                        })}

                                        {renderFormItemTable({
                                          section: conFamilyObj.tipoId,
                                          options: tipoDocOptions,
                                          cols: 6
                                        })}

                                        {renderFormItemTable({
                                          section: conFamilyObj.nroIdentificacion,
                                          validator: docValidator(conFamilyObj.tipoId.val, "persona"),
                                          cols: 6
                                        })}

                                        {renderFormItemTable({
                                          section: conFamilyObj.cargo,
                                          cols: 6
                                        })}
                                        {renderFormItemTable({
                                          section: conFamilyObj.parentesco,
                                          options: relationshipOptionsParauco,
                                          cols: 6,
                                        })}
                                    
                                        <Col className="subsection-title" span= {24}>
                                          Datos de la persona relacionada en {clientName}
                                          <hr/>
                                        </Col>
                                     
                                        {renderFormItemTable({
                                          section: conFamilyObj.nombreRelacionado,
                                          cols: 6
                                        })}

                                        {renderFormItemTable({
                                          section: conFamilyObj.tipoDocRelacionado,
                                          options: tipoDocOptions,
                                          cols: 6
                                        })}

                                        {renderFormItemTable({
                                          section: conFamilyObj.nroIdRelacionado,
                                          validator: docValidator(conFamilyObj.tipoDocRelacionado.val, "persona"),
                                          cols: 6
                                        })}

                                        {renderFormItemTable({
                                          section: conFamilyObj.cargoRelacionado,
                                          cols: 6
                                        })}
                                      
                                    </Row>
                                  }
                              
                                <Row className="button-row">
                                  {conflictoInteres.familyRecords.val.length < 1 &&
                                    validarRegistros && (
                                      <Col
                                        span={24}
                                        style= {{color: 'red'}}
                                        className="missing-registers ant-form-explain"
                                      >
                                        {t("messages.aml.registersRequired")}
                                      </Col>
                                    )}

                                  <Col className="addRelation" xl={3}>
                                    <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTable('conFamilyObj', 'conflictoInteres', 'familyRecords')} icon="plus" > Añadir </Button>
                                  </Col>
                                  <Col className="addRelation" xl={3}>
                                    <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('conFamilyObj')}> Limpiar </Button>
                                  </Col>
                                </Row>
                              </>
                            )}

                            {conflictoInteres.familyRecords.val.length > 0 && format === "html" ?
                              <Row className="summary">
                                <Table columns={getConflictFamilyColumns()} dataSource={conflictoInteres.familyRecords.val} size="middle" pagination={false} />
                              </Row>
                              :
                              toDescriptionsPdf( conflictoInteres.familyRecords.val, conFamilyObj, apiForm.typeSol )
                            }
                          </div>
                        )}
                        
                        <Row className="summary">
                          <Col span={20}>
                          {apiForm.typeSol === 'PN' ?
                            <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                              ¿Tiene acciones o participaciones en una sociedad o posee una sociedad conjunta con cualquier Gerente, Ejecutivo Principal o Director de {clientName} o sus Filiales?
                            </div>
                            :
                            <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                              ¿Su organización posee algún miembro de la alta dirección en un cargo de responsabilidad, que posea <strong>acciones o participaciones en una sociedad o tenga una sociedad conjunta</strong> con cualquier Gerente, Ejecutivo Principal o Director de {clientName} o sus Filiales?
                            </div>
                          }
                          </Col>
                          {renderFormItem({
                            label: "",
                            colClassName: "switch-col",
                            itemClassName: "radio-item-flat",
                            name: conflictoInteres.hasShares.id,
                            labelCol: 0,
                            wrapperCol: 0,
                            offset: 1,
                            initialValue: conflictoInteres.hasShares.val,
                            rules: [
                              {
                                required: true,
                                message: t("messages.aml.dontForgetSelect"),
                              },
                            ],
                            wrapperCols: 3,
                            item: (
                              <Radio.Group
                                onChange={({ target }) =>
                                  handlerOnChangeObjAttr(
                                    conflictoInteres.hasShares.id,
                                    target.value
                                  )
                                }
                              >
                                <Radio style={radioStyle} value={true}>
                                  Sí
                                </Radio>
                                <Radio style={radioStyle} value={false}>
                                  No
                                </Radio>
                              </Radio.Group>
                            ),
                          })
                          }
                        </Row>
                        {conflictoInteres.hasShares.val && (
                          <div className="content">
                            {format === "html" && !signed && (
                              <>
                                <Row className="summary" gutter={[20]} type={format === 'html' ? 'flex' : null}>
                                  <Col className="subsection-title" span= {24}>
                                  {apiForm.typeSol === 'PN' ? 'Datos de la persona relacionada en '+clientName : 'Datos de la persona relacionada en la empresa proveedora'}
                                    <hr/>
                                  </Col>
                                  {renderFormItemTable({
                                    section: conShareholderObj.name,
                                    cols: 6
                                  })}
                                  {renderFormItemTable({
                                    section: conShareholderObj.tipoDoc,
                                    options: tipoDocOptions,
                                    cols: 6
                                  })}
                                  {renderFormItemTable({
                                    section: conShareholderObj.nroIdentificacion,
                                    validator: docValidator(conShareholderObj.tipoDoc.val),
                                    cols: 6
                                  })}
                                  {renderFormItemTable({
                                    section: conShareholderObj.cargo,
                                    customTitle: apiForm.typeSol === 'PN' ? "Cargo del PEP" : "Cargo",
                                    cols: 6
                                  })}

                                  <Col className="subsection-title" span= {24}>
                                    {apiForm.typeSol === 'PN' ? 'Datos de la sociedad en conjunto' : 'Datos de la sociedad'}
                                    <hr/>
                                  </Col>
                                  {renderFormItemTable({
                                    section: conShareholderObj.nameEmp,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: conShareholderObj.tipoIdEmp,
                                    options: tipoDocEntityOptions,
                                    cols: 6
                                  })}

                                  {renderFormItemTable({
                                    section: conShareholderObj.nroIdEmp,
                                    validator: docValidator(conShareholderObj.tipoIdEmp.val),
                                    cols: 6
                                  })}
                                  
                                  {apiForm.typeSol === 'PJ' &&
                                    <>
                                      <Col className="subsection-title" span= {24}>
                                      Datos de la persona relacionada en {clientName}
                                      <hr/>
                                      </Col>
                                      {renderFormItemTable({
                                        section: conShareholderObj.nombreColab,
                                        cols: 6
                                      })}
                                      {renderFormItemTable({
                                        section: conShareholderObj.tipoDocColab,
                                        options: tipoDocOptions,
                                        cols: 6
                                      })}
                                      {renderFormItemTable({
                                        section: conShareholderObj.nroIdColab,
                                        validator: docValidator(conShareholderObj.tipoDocColab.val, "persona"),
                                        cols: 6
                                      })}
                                      {renderFormItemTable({
                                        section: conShareholderObj.cargoColab,
                                        cols: 6
                                      })}
                                    </>
                                  }
                                </Row>
                                <Row className="button-row">
                                  {conflictoInteres.shareRecords.val.length < 1 &&
                                    validarRegistros && (
                                      <Col
                                        span={24}
                                        style= {{color: 'red'}}
                                        className="missing-registers ant-form-explain"
                                      >
                                        {t("messages.aml.registersRequired")}
                                      </Col>
                                    )}

                                  <Col className="addRelation" xl={3}>
                                    <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTable('conShareholderObj', 'conflictoInteres', 'shareRecords')} icon="plus" > Añadir </Button>
                                  </Col>
                                  <Col className="addRelation" xl={3}>
                                    <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('conShareholderObj')}> Limpiar </Button>
                                  </Col>
                                </Row>
                              </>
                            )}

                            {conflictoInteres.shareRecords.val.length > 0 && format === "html" ?
                              <Row className="summary">
                                <Table columns={getConflictShareColumns()} dataSource={conflictoInteres.shareRecords.val} size="middle" pagination=   {false} />
                              </Row>
                              :
                              toDescriptionsPdf( conflictoInteres.shareRecords.val, conShareholderObj, apiForm.typeSol )
                            }
                          </div>
                        )}
                        <Row className="summary">
                          <Col span={20}>
                          {apiForm.typeSol === 'PN' ?
                            <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                             ¿Ocupa o ha ocupado en un cargo de responsabilidad, Gerente, Ejecutivo Principal o Director durante los últimos 5 años en {clientName} o sus Filiales?
                            </div>
                            :
                            <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                              ¿Su organización posee algún miembro de la alta dirección en un cargo de responsabilidad, Gerente, Ejecutivo Principal o Director que durante los <strong>últimos 5 años haya ejercido funciones</strong> en {clientName} o sus Filiales?
                            </div>
                          }
                          </Col>
                          {renderFormItem({
                            label: "",
                            colClassName: "switch-col",
                            itemClassName: "radio-item-flat",
                            name: conflictoInteres.hasRelation.id,
                            labelCol: 0,
                            wrapperCol: 0,
                            offset: 1,
                            initialValue: conflictoInteres.hasRelation.val,
                            rules: [
                              {
                                required: true,
                                message: t("messages.aml.dontForgetSelect"),
                              },
                            ],
                            wrapperCols: 3,
                            item: (
                              <Radio.Group
                                onChange={({ target }) =>
                                  handlerOnChangeObjAttr(
                                    conflictoInteres.hasRelation.id,
                                    target.value
                                  )
                                }
                              >
                                <Radio style={radioStyle} value={true}>
                                  Sí
                                </Radio>
                                <Radio style={radioStyle} value={false}>
                                  No
                                </Radio>
                              </Radio.Group>
                            ),
                          })
                          }
                        </Row>
                        {conflictoInteres.hasRelation.val && (
                          <div className="content">
                            {format === "html" && !signed && (
                              <>
                                <Row className="summary" gutter={[20]} type={format === 'html' ? 'flex' : null}>
                                  {apiForm.typeSol === 'PJ'  ?
                                    <>
                                      <Col className="subsection-title" span= {24}>
                                        Datos de la persona relacionada en la empresa proveedora
                                        <hr/>
                                      </Col>
                                      {renderFormItemTable({
                                        section: conHasRelationObj.nombreRelacionado,
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: conHasRelationObj.tipoDocRelacionado,
                                        options: tipoDocOptions,
                                        cols: 6
                                      })}
                                      {renderFormItemTable({
                                        section: conHasRelationObj.nroIdRelacionado,
                                        validator: docValidator(conHasRelationObj.tipoDocRelacionado.val, "persona"),
                                        cols: 6
                                      })}
                                      {renderFormItemTable({
                                        section: conHasRelationObj.cargoRelacionado,
                                        cols: 6
                                      })}
                                      <Col className="subsection-title" span= {24}>
                                        Función ocupada en {clientName}
                                        <hr/>
                                      </Col>
                                      {renderFormItemTable({
                                        section: conHasRelationObj.cargo,
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: conHasRelationObj.fechaTermino,
                                        cols: 6
                                      })}
                                    </>
                                    :
                                    <>
                                      <Col className="subsection-title" span= {24}>
                                        Función ocupada en {clientName}
                                        <hr/>
                                      </Col>
                                      {renderFormItemTable({
                                        section: conHasRelationObj.cargoPerNat,
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: conHasRelationObj.fechaTerminoPerNat,
                                        cols: 6
                                      })}
                                    </>
                                  }
                                  
                                </Row>
                                <Row className="button-row">
                                  {conflictoInteres.relationRecords.val.length < 1 &&
                                    validarRegistros && (
                                      <Col
                                        span={24}
                                        style= {{color: 'red'}}
                                        className="missing-registers ant-form-explain"
                                      >
                                        {t("messages.aml.registersRequired")}
                                      </Col>
                                    )}

                                  <Col className="addRelation" xl={3}>
                                    <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTable('conHasRelationObj', 'conflictoInteres', 'relationRecords')} icon="plus" > Añadir </Button>
                                  </Col>
                                  <Col className="addRelation" xl={3}>
                                    <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('conHasRelationObj')}> Limpiar </Button>
                                  </Col>
                                </Row>
                              </>
                            )}
                            {conflictoInteres.relationRecords.val.length > 0 && format === "html" ?
                              <Row className="summary">
                                <Table columns={getConflictRelationColumns()} dataSource={conflictoInteres.relationRecords.val} size="middle" pagination={false} />
                              </Row>
                              :
                                toDescriptionsPdf( conflictoInteres.relationRecords.val, conHasRelationObj, apiForm.typeSol )
                            }
                          </div>
                        )}

                        <Row className="summary">
                          <Col span={20}>
                          {apiForm.typeSol === 'PN' ?
                            <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                              ¿Ocupa el cargo de Director en {clientName} o filiales?
                            </div>
                            :
                            <div style={{backgroundColor:'#ededed', borderRadius:10, padding:10}}>
                              ¿Algún miembro de la plana gerencial, representantes legales, accionistas o directores ocupa el cargo de Director en {clientName} o filiales?
                            </div>
                          }
                          </Col>
                          {renderFormItem({
                            label: "",
                            colClassName: "switch-col",
                            itemClassName: "radio-item-flat",
                            name: conflictoInteres.isDirector.id,
                            labelCol: 0,
                            wrapperCol: 0,
                            offset: 1,
                            initialValue: conflictoInteres.isDirector.val,
                            rules: [
                              {
                                required: true,
                                message: t("messages.aml.dontForgetSelect"),
                              },
                            ],
                            wrapperCols: 3,
                            item: (
                              <Radio.Group
                                onChange={({ target }) =>
                                  handlerOnChangeObjAttr(
                                    conflictoInteres.isDirector.id,
                                    target.value
                                  )
                                }
                              >
                                <Radio style={radioStyle} value={true}>
                                  Sí
                                </Radio>
                                <Radio style={radioStyle} value={false}>
                                  No
                                </Radio>
                              </Radio.Group>
                            ),
                          })
                          }
                        </Row>
                        {conflictoInteres.isDirector.val && (
                          <div className="content">
                            {!signed && (
                              apiForm.typeSol === 'PN' ?
                                <Row className="summary">
                                  {renderFormItemObj({
                                    section: conflictoInteres.directorHireDate,
                                    cols: 6
                                  })}
                                </Row>
                              :
                              format === "html" &&
                                <>
                                  <Row className="summary" gutter={[20]} type={format === 'html' ? 'flex' : null}>
                                    <Col className="subsection-title" span= {24}>
                                      Datos de la persona relacionada en la empresa proveedora
                                      <hr/>
                                    </Col>
                                    {renderFormItemTable({
                                      section: conIsDirectorObj.nombre,
                                      cols: 6
                                    })}
                                    {renderFormItemTable({
                                      section: conIsDirectorObj.tipoDoc,
                                      options: tipoDocOptions,
                                      cols: 6
                                    })}
                                    {renderFormItemTable({
                                      section: conIsDirectorObj.nroIdentificacion,
                                      validator: docValidator(conIsDirectorObj.tipoDoc.val, "persona"),
                                      cols: 6
                                    })}
                                  </Row>
                                  <Row className="button-row">
                                    {conflictoInteres.isDirectorRecords.val.length < 1 &&
                                      validarRegistros && (
                                        <Col
                                          span={24}
                                          style= {{color: 'red'}}
                                          className="missing-registers ant-form-explain"
                                        >
                                          {t("messages.aml.registersRequired")}
                                        </Col>
                                    )}

                                    <Col className="addRelation" xl={3}>
                                      <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTable('conIsDirectorObj', 'conflictoInteres', 'isDirectorRecords')} icon="plus" > Añadir </Button>
                                    </Col>
                                    <Col className="addRelation" xl={3}>
                                      <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('conIsDirectorObj')}> Limpiar </Button>
                                    </Col>
                                  </Row>
                                  
                                </>
                            )}

                            {conflictoInteres.isDirectorRecords.val.length > 0 && format === "html" ?
                                <Row className="summary">
                                  <Table columns={getConflictDirectorColumns()} dataSource={conflictoInteres.isDirectorRecords.val} size="middle" pagination={false} />
                                </Row>
                              :
                              toDescriptionsPdf( conflictoInteres.isDirectorRecords.val, conIsDirectorObj, apiForm.typeSol )
                            }
                          </div>
                        )}
                        {apiForm.typeSol === 'PJ' &&
                          <>
                            <Row
                              className="lineamiento subheader"
                            >
                              <Col xl={24}>VII. ACCIONISTAS</Col>
                            </Row>
                            <Row className="summary">
                              <Col span={20}>
                                Se entenderá como Accionistas a la(s) persona(s) natural(es) o personas jurídicas que poseen una participación igual o mayor al 25% del capital social, aporte o participación.
                              </Col>
                              {renderFormItem({
                                label: "",
                                colClassName: "switch-col",
                                itemClassName: "radio-item-flat",
                                name: accionistas.isAccionista.id,
                                labelCol: 0,
                                wrapperCol: 0,
                                offset: 1,
                                initialValue: accionistas.isAccionista.val,
                                rules: [
                                  {
                                    required: true,
                                    message: t("messages.aml.dontForgetSelect"),
                                  },
                                ],
                                wrapperCols: 3,
                                item: (
                                  <Radio.Group
                                    onChange={({ target }) =>
                                      handlerOnChangeObjAttr(
                                        accionistas.isAccionista.id,
                                        target.value
                                      )
                                    }
                                  >
                                    <Radio style={radioStyle} value={true}>
                                      Sí
                                    </Radio>
                                    <Radio style={radioStyle} value={false}>
                                      No
                                    </Radio>
                                  </Radio.Group>
                                ),
                              })
                              }
                            </Row>
                            {accionistas.isAccionista.val && (
                              <div className="content">
                                {format === "html" && !signed && (
                                  <>
                                    <Row className="summary" gutter={[20]} type={format === 'html' ? 'flex' : null}>
                                      {renderFormItemTable({
                                        section: accionistaObj.tipoPersona,
                                        options:[
                                          {val: "Persona Natural", text:"Persona Natural"},
                                          {val: "Persona Jurídica", text:"Persona Jurídica"}
                                        ],
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: accionistaObj.name,
                                        
                                        cols: 6
                                      })}
                                      {renderFormItemTable({
                                        section: accionistaObj.tipoId,
                                        options: accionistaObj.tipoPersona.val === 'Persona Natural' ? tipoDocOptions : tipoDocEntityOptions,
                                        
                                        cols: 6
                                      })}
                                      {renderFormItemTable({
                                        section: accionistaObj.nroIdentificacion,
                                        validator: docValidator(accionistaObj.tipoId.val),
                                        cols: 6
                                      })}
                                      {renderFormItemTable({
                                        section: accionistaObj.nacionalidad,
                                        options:
                                            paises.map(item => {
                                              return {val: item.country, text: item.country}
                                          }),
                                        cols: 6
                                      })}

                                      {renderFormItemTable({
                                        section: accionistaObj.participacion,
                                        cols: 6
                                      })}
                                      
                                    </Row>
                                    <Row className="button-row">
                                      {accionistas.accionistaRecords.val.length < 1 &&
                                        validarRegistros && (
                                          <Col
                                            span={24}
                                            style= {{color: 'red'}}
                                            className="missing-registers ant-form-explain"
                                          >
                                            {t("messages.aml.registersRequired")}
                                          </Col>
                                      )}
    
                                      <Col className="addRelation" xl={3}>
                                        <Button type="primary" htmlType="button" onClick={()=> handleOnAddAttrTable('accionistaObj', 'accionistas', 'accionistaRecords')} icon="plus" > Añadir </Button>
                                      </Col>
                                      <Col className="addRelation" xl={3}>
                                        <Button type="primary" htmlType="button" icon="delete" onClick={(e)=>handleOnClear('accionistaObj')}> Limpiar </Button>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                                {accionistas.accionistaRecords.val.length > 0 && format === "html" ?
                                  <Row className="summary">
                                    <Table columns={accionistaColumns} dataSource={accionistas.accionistaRecords.val} size="middle" pagination={false} />
                                  </Row>
                                  :
                                    toDescriptionsPdf( accionistas.accionistaRecords.val, accionistaObj, apiForm.typeSol )
                                }
                              </div>
                            )}
                          </>
                        }
                        <Row
                          className="lineamiento subheader"
                        >
                          <Col span={24}>{apiForm.typeSol === 'PJ' ? 'VIII.' : 'VII.'} DECLARACIÓN COMPLEMENTARIA EN TEMAS DE CUMPLIMIENTO</Col>
                        </Row>
                        <Row className="summary">
                          {!signed && (
                            apiForm.typeSol === 'PN' ?
                            <div>
                                Bajo la gravedad de juramento, como persona natural declaro en mi propio nombre que:
                                <br/><br/>
                              <ol type="1">
                                <li>
                                  Conozco y garantizo cumplir el Modelo de Prevención de Delitos, Código de Conducta de Proveedores de {clientName} y sus filiales, cuyos contenidos han sido publicados en la página web www.parauco.com.
                                </li>
                                <li>
                                  Mantendré controles y medidas preventivas adecuadas para evitar la comisión de los delitos referidos en la Ley N° 30.424 durante toda la vigencia de la relación comercial o contractual.
                                </li>
                                <li>
                                  Conocemos la existencia de las leyes y reglamentos anti corrupción, lavado de activos, financiamiento del terrorismo, entre otros delitos en el Perú y su exigibilidad.
                                </li>
                                <li>
                                  Cumplimos con la legislación aplicable a nuestro giro de negocio
                                </li>
                                <li>
                                  Los recursos, los fondos, dinero, productos y activos destinados a la ejecución de las relaciones comerciales y contractuales con {clientName} y sus filiales  son de procedencia lícita y están ligados al desarrollo normal de nuestras actividades, no provienen de  ninguna actividad ilícita de las contempladas en el Código Penal de Perú, o en cualquier norma que lo sustituya, adicione o modifique.
                                </li>
                                <li>
                                  No formo parte de las listas internacionales vinculantes para Perú de conformidad con el derecho internacional (listas del Consejo de Seguridad de las Naciones Unidas) o en las listas de la OFAC y demás listas inhibitorias internacionales o locales o bases de datos públicas relacionadas con información sobre delitos de lavado de activos, financiación del terrorismo o sus delitos fuente.
                                </li>
                                <li>
                                  No he sido sancionado y no me encuentro en un proceso de investigación por los delitos de corrupción, lavado de activos, financiamiento del terrorismo y otros delitos precedentes. 
                                </li>
                                <li>
                                  Proporcionaremos a Parque Arauco o a sus auditores externos la información y documentación que estos puedan requerir a fin de verificar el cumplimiento de la legislación vigente en materia de prevención de actos de corrupción en el ámbito público y privado, lavado de activos y financiamiento del terrorismo, comprometiéndose a cooperar plenamente con el desarrollo de dicha verificación.
                                </li>
                                <li>
                                  No efectuaré a nombre de {clientName} y filiales, o en beneficio de ellas, cualquier especie de pago indebido o ilegítimo a funcionarios de la administración pública (de cualquier tipo de repartición), y dar cumplimiento efectivo a la Política de Interacción con Funcionarios Públicos de {clientName} y filiales, disponible en la página web www.parauco.com.
                                </li>
                                <li>
                                  En caso se generen cambios de las circunstancias descritas anteriormente, dicha situación se comunicará dentro de 24 horas al Encargado de Prevención al correo electrónico mpd@parauco.com. 
                                </li>
                              </ol>
                              Conocemos, declaramos y aceptamos que:
                              <ol type="i">
                                <li>{clientName} y sus filiales está facultada para efectuar las verificaciones que considere pertinentes y están en la obligación legal de solicitar las aclaraciones que estime pertinentes en el evento en que se presenten circunstancias con base en las cuales pueda tener dudas razonables sobre nuestras operaciones, así como del origen de nuestros activos, evento en el cual suministraremos las aclaraciones que sean del caso.
                                </li>
                                <li>Las declaraciones que se disponen en este documento sobrevivirán a la celebración y suscripción de cualquier relación contractual o comercial con {clientName} y filiales, durante toda la vigencia de las mismas. 
                                </li>
                                <li>En caso de incumplimiento, incongruencia o discrepancia respecto de las obligaciones y declaraciones incorporadas en el presente documento, {clientName} y filiales podrá dar por terminada, con justa causa, cualquier relación comercial o contractual que exista, sin derecho a indemnización alguna para las Sociedades.</li>
                              </ol>
                            </div>
                          :
                            <div>
                              Bajo la gravedad de juramento, como persona natural y/o actuando en calidad de representante legal de la empresa cuyos datos se incluyen en este formulario, según aplique, declaro en mi propio nombre y en la sociedad que represento, su matriz, afiliadas, filiales, directores, administradores, representantes legales, accionistas, asociados o socios que directa o indirectamente tengan el 25% o más del capital social, aporte o participación, que:
                              <ol type="1">
                                <li>
                                  Conocemos y garantizamos dar cumplimiento al modelo de prevención de delitos, Código de Conducta de Proveedores de {clientName} y sus filiales, cuyos contenidos han sido publicados en la página web www.parauco.com.
                                </li>
                                <li>
                                  Conocemos la existencia de las leyes y reglamentos anti corrupción, lavado de activos, financiamiento del terrorismo, entre otros delitos en el Perú y su exigibilidad (Ley 30424 y sus modificatorias).
                                </li>
                                <li>
                                  Cumplimos con la legislación aplicable a nuestro giro de negocio.
                                </li>
                                <li>
                                  Es obligación del Proveedor mantener controles y medidas preventivas adecuadas para evitar la comisión de los delitos referidos en la Ley N° 30424 durante toda la vigencia de la relación contractual.
                                </li>
                                <li>
                                  Nuestros  recursos, los fondos, dinero, productos y activos destinados a la ejecución de las relaciones comerciales y contractuales con {clientName} y sus filiales  son de procedencia lícita y están ligados al desarrollo normal de nuestras actividades, no provienen de  ninguna actividad ilícita de las contempladas en el Código Penal de Perú, o en cualquier norma que lo sustituya, adicione o modifique.
                                </li>
                                <li>
                                  No nos encontramos designados o mencionados en las listas internacionales vinculantes para Perú de conformidad con el derecho internacional (listas del Consejo de Seguridad de las Naciones Unidas) o en las listas de la OFAC y demás listas inhibitorias internacionales o locales o bases de datos públicas relacionadas con información sobre delitos de lavado de activos, financiación del terrorismo o sus delitos fuente.
                                </li>
                                <li>
                                  No hemos sido sancionados y no nos encontramos en un proceso de investigación por los delitos de corrupción, lavado de activos, financiamiento del terrorismo y otros delitos comprendidos en la Ley 30424.
                                </li>
                                <li>
                                  Proporcionaremos a {clientName} o a sus auditores externos la información y documentación que estos puedan requerir a fin de verificar el cumplimiento de la legislación vigente en materia de prevención de actos de corrupción en el ámbito público y privado, lavado de activos y financiamiento del terrorismo, comprometiéndose a cooperar plenamente con el desarrollo de dicha verificación.
                                </li>
                                <li>
                                  El Proveedor se compromete a no efectuar a nombre de {clientName} y filiales, o en beneficio de ellas, cualquier especie de pago indebido o ilegítimo a funcionarios de la administración pública (de cualquier tipo de repartición), y dar cumplimiento efectivo a la Política de Interacción con Funcionarios Públicos de {clientName} y filiales, disponible en la página web www.parauco.com.
                                </li>
                                <li>
                                  En caso se generen cambios de las circunstancias descritas anteriormente, dicha situación se comunicará dentro de 24 horas al Encargado de Prevención al correo electrónico mpd@parauco.com. 
                                </li>
                              </ol>
                              Conocemos, declaramos y aceptamos que:
                              <ol type='i'>
                                <li>
                                  {clientName} y sus filiales está facultada para efectuar las verificaciones que considere pertinentes y están en la obligación legal de solicitar las aclaraciones que estime pertinentes en el evento en que se presenten circunstancias con base en las cuales pueda tener dudas razonables sobre nuestras operaciones, así como del origen de nuestros activos, evento en el cual suministraremos las aclaraciones que sean del caso
                                </li>
                                <li>
                                  Las declaraciones y garantías que se disponen en este documento sobrevivirán a la celebración y suscripción de cualquier relación contractual o comercial con {clientName} y filiales, durante toda la vigencia de las mismas. 
                                </li>
                                <li>
                                  En caso de incumplimiento, incongruencia o discrepancia respecto de las obligaciones y declaraciones incorporadas en el presente documento, {clientName} y filiales podrá dar por terminada, con justa causa, cualquier relación comercial o contractual que exista, sin derecho a indemnización alguna para las Sociedades.
                                </li>
                              </ol>
                            </div>
                          )}
                        </Row>
                        <Row
                          className="lineamiento subheader"
                        >
                          <Col xl={24}>{apiForm.typeSol === 'PJ' ? 'IX.' : 'VIII.'} SALUD, SEGURIDAD Y MEDIO AMBIENTE</Col>
                        </Row>
                        <Row className="summary">
                          A continuación indique si o no
                        </Row>
                        <Row className="summary">
                          <Col span={20}>
                          {apiForm.typeSol==='PN' ?
                            <div>
                              1.- ¿Respeta la normativa ambiental y controla los impactos ambientales donde quiera que opere?
                            </div>
                            :
                            <div>
                              1.- Conocemos y cumplimos la normativa ambiental aplicable a la totalidad de nuestras operaciones sobre gestión de residuos, emisiones, aguas residuales, entre otros, en el ámbito que nos compete como proveedor de {clientName}.
                            </div>
                          }
                          </Col>
                          {renderFormItem({
                            label: "",
                            colClassName: "switch-col",
                            itemClassName: "radio-item-flat",
                            name: saludMedioambiente.hasEnvRegulations.id,
                            labelCol: 0,
                            wrapperCol: 0,
                            offset: 1,
                            initialValue: saludMedioambiente.hasEnvRegulations.val,
                            rules: [
                              {
                                required: true,
                                message: t("messages.aml.dontForgetSelect"),
                              },
                            ],
                            wrapperCols: 3,
                            item: (
                              <Radio.Group
                                onChange={({ target }) =>
                                  handlerOnChangeObjAttr(
                                    saludMedioambiente.hasEnvRegulations.id,
                                    target.value
                                  )
                                }
                              >
                                <Radio style={radioStyle} value={true}>
                                  Sí
                                </Radio>
                                <Radio style={radioStyle} value={false}>
                                  No
                                </Radio>
                              </Radio.Group>
                            ),
                          })
                          }
                        </Row>
                        <Row className="summary">
                          <Col span={20}>
                          {apiForm.typeSol === 'PN' ?
                            <div>
                              2.- ¿Cumple los derechos humanos, previene delitos como trata de personas, trabajo forzoso, trabajo infantil y se compromete a cumplir todas las leyes aplicables y a respetar los derechos humanos internacionalmente reconocidos, donde quiera que opere? 
                            </div>
                            :
                            <div>
                              2.- Conocemos y controlamos los impactos ambientales generados, monitoreando información sobre la gestión de residuos, emisiones, aguas residuales, entre otros, aplicables a los impactos asociados a nuestra operación, en el ámbito que nos compete como proveedor de {clientName}.
                            </div>
                          }
                          </Col>
                          {renderFormItem({
                            label: "",
                            colClassName: "switch-col",
                            itemClassName: "radio-item-flat",
                            name: saludMedioambiente.hasHumanRegulations.id,
                            labelCol: 0,
                            wrapperCol: 0,
                            offset: 1,
                            initialValue: saludMedioambiente.hasHumanRegulations.val,
                            rules: [
                              {
                                required: true,
                                message: t("messages.aml.dontForgetSelect"),
                              },
                            ],
                            wrapperCols: 3,
                            item: (
                              <Radio.Group
                                onChange={({ target }) =>
                                  handlerOnChangeObjAttr(
                                    saludMedioambiente.hasHumanRegulations.id,
                                    target.value
                                  )
                                }
                              >
                                <Radio style={radioStyle} value={true}>
                                  Sí
                                </Radio>
                                <Radio style={radioStyle} value={false}>
                                  No
                                </Radio>
                              </Radio.Group>
                            ),
                          })
                          }
                        </Row>
                        {apiForm.typeSol === 'PJ' &&
                          <Row className="summary">
                            <Col span={20}>
                              <div>
                                3.- Contamos con una política o procedimientos de salud y seguridad en el trabajo que cumple con la normativa.
                              </div>
                            </Col>
                            {renderFormItem({
                              label: "",
                              colClassName: "switch-col",
                              itemClassName: "radio-item-flat",
                              name: saludMedioambiente.hasHealthPolicies.id,
                              labelCol: 0,
                              wrapperCol: 0,
                              offset: 1,
                              initialValue: saludMedioambiente.hasHealthPolicies.val,
                              rules: [
                                {
                                  required: true,
                                  message: t("messages.aml.dontForgetSelect"),
                                },
                              ],
                              wrapperCols: 3,
                              item: (
                                <Radio.Group
                                  onChange={({ target }) =>
                                    handlerOnChangeObjAttr(
                                      saludMedioambiente.hasHealthPolicies.id,
                                      target.value
                                    )
                                  }
                                >
                                  <Radio style={radioStyle} value={true}>
                                    Sí
                                  </Radio>
                                  <Radio style={radioStyle} value={false}>
                                    No
                                  </Radio>
                                </Radio.Group>
                              ),
                            })
                            }
                          </Row>
                        }
                      {apiForm.typeSol === 'PJ' &&
                        <>
                          <Row className="subheader">
                            <Col xl={24}>
                              X. ÉTICA Y CONDUCTA
                            </Col>
                          </Row>
                          <Row className="summary" style={{marginBottom:20}}>
                            A continuación indique si o no:
                          </Row>
                          <Row className="summary">
                            <Col span={20}>
                              <div>
                                1.- Contamos con un Reglamento interno y/o Código de conducta u otros lineamientos y capacitación a los colaboradores sobre temas de prevención de delitos de corrupción, soborno, lavado de activos y financiamiento del terrorismo.
                              </div>
                            </Col>
                            {renderFormItem({
                              label: "",
                              colClassName: "switch-col",
                              itemClassName: "radio-item-flat",
                              name: eticaConducta.hasRules.id,
                              labelCol: 0,
                              wrapperCol: 0,
                              offset: 1,
                              initialValue: eticaConducta.hasRules.val,
                              rules: [
                                {
                                  required: true,
                                  message: t("messages.aml.dontForgetSelect"),
                                },
                              ],
                              wrapperCols: 3,
                              item: (
                                <Radio.Group
                                  onChange={({ target }) =>
                                    handlerOnChangeObjAttr(
                                      eticaConducta.hasRules.id,
                                      target.value
                                    )
                                  }
                                >
                                  <Radio style={radioStyle} value={true}>
                                    Sí
                                  </Radio>
                                  <Radio style={radioStyle} value={false}>
                                    No
                                  </Radio>
                                </Radio.Group>
                              ),
                            })
                            }
                          </Row>
                          <Row className="summary">
                            <Col span={20}>
                              <div>
                                2.- Contamos con canales, líneas y/u otro mecanismo que permita realizar reportes o denuncias anónimas y confidenciales, conocido por toda la organización.
                              </div>
                            </Col>
                            {renderFormItem({
                              label: "",
                              colClassName: "switch-col",
                              itemClassName: "radio-item-flat",
                              name: eticaConducta.hasChannels.id,
                              labelCol: 0,
                              wrapperCol: 0,
                              offset: 1,
                              initialValue: eticaConducta.hasChannels.val,
                              rules: [
                                {
                                  required: true,
                                  message: t("messages.aml.dontForgetSelect"),
                                },
                              ],
                              wrapperCols: 3,
                              item: (
                                <Radio.Group
                                  onChange={({ target }) =>
                                    handlerOnChangeObjAttr(
                                      eticaConducta.hasChannels.id,
                                      target.value
                                    )
                                  }
                                >
                                  <Radio style={radioStyle} value={true}>
                                    Sí
                                  </Radio>
                                  <Radio style={radioStyle} value={false}>
                                    No
                                  </Radio>
                                </Radio.Group>
                              ),
                            })
                            }
                          </Row>
                          <Row className="summary">
                            <Col span={20}>
                              <div>
                                3.- Contamos con una política o procedimiento para nuestros colaboradores y contratistas la cual previene y sanciona casos de acoso u hostigamiento sexual laboral y discriminación.
                              </div>
                            </Col>
                            {renderFormItem({
                              label: "",
                              colClassName: "switch-col",
                              itemClassName: "radio-item-flat",
                              name: eticaConducta.hasPolicies.id,
                              labelCol: 0,
                              wrapperCol: 0,
                              offset: 1,
                              initialValue: eticaConducta.hasPolicies.val,
                              rules: [
                                {
                                  required: true,
                                  message: t("messages.aml.dontForgetSelect"),
                                },
                              ],
                              wrapperCols: 3,
                              item: (
                                <Radio.Group
                                  onChange={({ target }) =>
                                    handlerOnChangeObjAttr(
                                      eticaConducta.hasPolicies.id,
                                      target.value
                                    )
                                  }
                                >
                                  <Radio style={radioStyle} value={true}>
                                    Sí
                                  </Radio>
                                  <Radio style={radioStyle} value={false}>
                                    No
                                  </Radio>
                                </Radio.Group>
                              ),
                            })
                            }
                          </Row>
                          <Row className="summary">
                            <Col span={20}>
                              <div>
                                4.- Nuestra empresa respeta los derechos humanos, previene delitos como trata de personas, trabajo forzoso, trabajo infantil y se compromete a cumplir todas las leyes aplicables y a respetar los derechos humanos internacionalmente reconocidos, donde quiera que opere.
                              </div>
                            </Col>
                            {renderFormItem({
                              label: "",
                              colClassName: "switch-col",
                              itemClassName: "radio-item-flat",
                              name: eticaConducta.defeatHumanRights.id,
                              labelCol: 0,
                              wrapperCol: 0,
                              offset: 1,
                              initialValue: eticaConducta.defeatHumanRights.val,
                              rules: [
                                {
                                  required: true,
                                  message: t("messages.aml.dontForgetSelect"),
                                },
                              ],
                              wrapperCols: 3,
                              item: (
                                <Radio.Group
                                  onChange={({ target }) =>
                                    handlerOnChangeObjAttr(
                                      eticaConducta.defeatHumanRights.id,
                                      target.value
                                    )
                                  }
                                >
                                  <Radio style={radioStyle} value={true}>
                                    Sí
                                  </Radio>
                                  <Radio style={radioStyle} value={false}>
                                    No
                                  </Radio>
                                </Radio.Group>
                              ),
                            })
                            }
                          </Row>
                          <Row className="summary">
                            <Col span={20}>
                              <div>
                                5.- Contamos con controles internos para asegurar el cumplimiento de las obligaciones contractuales y legales derivadas de nuestra relación comercial con {clientName}.
                              </div>
                            </Col>
                            {renderFormItem({
                              label: "",
                              colClassName: "switch-col",
                              itemClassName: "radio-item-flat",
                              name: eticaConducta.hasControls.id,
                              labelCol: 0,
                              wrapperCol: 0,
                              offset: 1,
                              initialValue: eticaConducta.hasControls.val,
                              rules: [
                                {
                                  required: true,
                                  message: t("messages.aml.dontForgetSelect"),
                                },
                              ],
                              wrapperCols: 3,
                              item: (
                                <Radio.Group
                                  onChange={({ target }) =>
                                    handlerOnChangeObjAttr(
                                      eticaConducta.hasControls.id,
                                      target.value
                                    )
                                  }
                                >
                                  <Radio style={radioStyle} value={true}>
                                    Sí
                                  </Radio>
                                  <Radio style={radioStyle} value={false}>
                                    No
                                  </Radio>
                                </Radio.Group>
                              ),
                            })
                            }
                          </Row>
                        </>
                      }
                      <Row className="subheader">
                        <Col xl={24}>
                         {apiForm.typeSol === 'PJ' ? 'XI.' : 'IX.'} PROTECCIÓN DE DATOS PERSONALES
                        </Col>
                      </Row>
                      {apiForm.typeSol === 'PN' ?
                        <Row className="summary">
                          ARAUCO MALLS PERÚ S.A.C. con RUC N° 20510641516, domiciliada en Av. José Larco N° 1301, oficina 502 - 503, distrito de Miraflores, provincia y departamento de Lima, en cumplimiento con lo estipulado en la Ley Nº 29733, Ley de Protección de Datos Personales y su Reglamento, aprobado mediante Decreto Supremo Nº 003-2013-JUS, pone a su conocimiento que con el registro y firma de este documento, autorizo expresamente a ARAUCO MALLS PERÚ S.A.C., el tratamiento de los datos personales consignados, para los fines descritos en el párrafo precedente correspondiente al cumplimiento de Ley N°30424 (norma que regula la responsabilidad administrativa de las personas jurídicas). De acuerdo con el numeral 10 del artículo 14 de la  Ley de Protección de Datos Personales, la información que se trate para fines del sistema de prevención de lavado de activos y financiamiento del terrorismo, el consentimiento no es requerido. La información que usted brinde en este formulario será tratada en cumplimiento de los deberes de confidencialidad, seguridad de la información y demás aplicables según la legislación de la materia. ARAUCO MALLS PERÚ S.A.C. ha adoptado las medidas de seguridad legales, técnicas y organizativas necesarias para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales. ARAUCO MALLS PERÚ S.A.C. se compromete a no utilizar los datos personales para un fin distinto, salvo autorización expresa del proveedor.
                        </Row>
                        :
                        <Row className="summary">
                          ARAUCO MALLS PERÚ S.A.C. (en adelante, “AMP”), con RUC N° 20510641516, domiciliada en Av. José Larco N° 1301, oficina 502 - 503, distrito de Miraflores, provincia y departamento de Lima, en cumplimiento con lo estipulado en la Ley Nº 29733, Ley de Protección de Datos Personales y su Reglamento, aprobado mediante Decreto Supremo Nº 003-2013-JUS, pone a su conocimiento que con el registro y firma de este documento, autorizo expresamente a AMP, el tratamiento de los datos personales consignados, para los fines descritos en el párrafo precedente correspondiente al cumplimiento de Ley N°30424 (norma que regula la responsabilidad administrativa de las personas jurídicas). De acuerdo con el numeral 10 del artículo 14 de la  Ley de Protección de Datos Personales, la información que se trate para fines del sistema de prevención de lavado de activos y financiamiento del terrorismo, el consentimiento no es requerido. La información que usted brinde en este formulario será tratada en cumplimiento de los deberes de confidencialidad, seguridad de la información y demás aplicables según la legislación de la materia. AMP ha adoptado las medidas de seguridad legales, técnicas y organizativas necesarias para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales. AMP se compromete a no utilizar los datos personales para un fin distinto, salvo autorización expresa del proveedor.
                        </Row>
                      }
                      <Row className="subheader">
                        <Col xl={24}>
                          {apiForm.typeSol === 'PJ' ? 'XII.' : 'X.'} DOCUMENTOS ADJUNTOS
                        </Col>
                      </Row>
                      <Row className="summary" style={{marginBottom:20}}>
                        A continuación, adjunte:
                        <ol type="i">
                        {apiForm.typeSol === 'PN' ?
                          <>
                            <li>
                              Ficha RUC (con fecha no mayor a un año)
                            </li>
                            <li>
                              Certificado Bancario emitido por el banco del proveedor
                            </li>
                            <li>
                              Certificado del Banco de la Nación (para cuenta de detracción), en el caso emitan facturas
                            </li>
                            <li>
                              Copia del DNI por ambos lados
                            </li>
                          </>
                          :
                          <>
                            <li>
                            Ficha RUC (con fecha no mayor a un año)
                            </li>
                            <li>
                              Certificado Bancario emitido por el banco del proveedor
                            </li>
                            <li>
                              Certificado del Banco de la Nación (para cuenta de detracción)
                            </li>
                          </>
                          }
                        </ol>
                        Recuerde que debe indicar el nombre del documento que adjuntará. Puede adjuntar documentos en formato pdf, jpg, Word, Excel cuyo peso no supere los 2MB por archivo.
                      </Row>
                      { format === 'pdf' ?
                        <Row>
                          { apiForm?.files?.length > 0 ?
                            <List
                              size="small"
                              header="Documentos adjuntos"
                              itemLayout="horizontal"
                              dataSource={apiForm.files}
                              renderItem={item => (
                                <List.Item>
                                  <List.Item.Meta
                                      description={item.name}
                                  />
                                  <List.Item.Meta
                                    description={item.originalName}
                                  />
                                </List.Item>
                              )}
                            />
                            :
                            <div style={{textAlign: 'center'}}>
                              No se adjuntaron documentos como sustento.
                            </div>
                          }
                        </Row>
                        : format === 'html' &&
                        <>
                          <Row className="summary" type='flex' align="middle">
                              {renderFormItemTable({
                                section: attch.fileName,
                                options: apiForm.typeSol === 'PN' ? [
                                  {val: "Ficha RUC (con fecha no mayor a un año)", text: "Ficha RUC (con fecha no mayor a un año)"},
                                  {val: "Certificado Bancario emitido por el banco del proveedor", text: "Certificado Bancario emitido por el banco del proveedor"},
                                  {val: "Certificado del Banco de la Nación (para cuenta de detracción), en el caso emitan facturas", text: "Certificado del Banco de la Nación (para cuenta de detracción), en el caso emitan facturas"},
                                  {val: "Copia del DNI por ambos lados", text: "Copia del DNI por ambos lados"}
                                ]
                                :
                                [
                                  {val: "Ficha RUC (con fecha no mayor a un año)", text: "Ficha RUC (con fecha no mayor a un año)"},
                                  {val: "Certificado Bancario emitido por el banco del proveedor", text: "Certificado Bancario emitido por el banco del proveedor"},
                                  {val: "Certificado del Banco de la Nación (para cuenta de detracción)", text: "Certificado del Banco de la Nación (para cuenta de detracción)"},
                                ],
                                cols: 12
                              })}

                              <Col 
                                span={6} 
                                offset={1}
                              >
                                <Upload {...propsUpload}>
                                  <Button style={{marginTop:15}} disabled={ !attch.fileName.val || tmpFilesList.length > 8 } >
                                    <Icon type="upload" /> Subir Archivo
                                  </Button>
                                </Upload>
                              </Col>
                          </Row>
                          {tmpFilesList.length > 8 &&
                            <Col
                              span={24}
                              style={{paddingTop: 20}}
                              className="missing-registers ant-form-explain"
                            >
                              El máximo de archivos adjuntos es de 9.
                            </Col>
                          }
                          {((apiForm.typeSol === 'PN' && tmpFilesList.length < 4) || 
                          (apiForm.typeSol === 'PJ' && tmpFilesList.length < 3)) && 
                          validarRegistros && (
                            <Col
                              span={24}
                              style={{paddingTop: 20}}
                              className="missing-registers ant-form-explain"
                            >
                              Se requieren al menos {apiForm.typeSol === 'PN' ?'4':'3'} archivos adjuntos.
                            </Col>
                          )}
                          <Row className="summary">
                            <List
                              size="small"
                              header="Documentos adjuntos"
                              itemLayout="horizontal"
                              dataSource={tmpFilesList.map((file, index)=> {return {fileName: file.name, docName: fileNames[index], index: index}})}
                              renderItem={item => (
                                <List.Item
                                  actions={[<a onClick={() => handleListDeleted(item.index)}>Eliminar</a>]}
                                >
                                  <List.Item.Meta
                                      description={item.docName}
                                  />
                                  <List.Item.Meta
                                    description={item.fileName}
                                  />
                                </List.Item>
                              )}
                            />
                          </Row>
                        </>
                      }
                      <Row className="subheader">
                        <Col xl={24}>
                          {apiForm.typeSol === 'PJ' ? 'XIII.' : 'XI.'} DECLARACIÓN DE RESPONSABILIDAD
                        </Col>
                      </Row>
                      <Row className="summary" style={{marginBottom:20}}>
                        <p>
                          La información consignada en este formulario es considerada como Declaración Jurada. Entiendo que la omisión, distorsión o inexactitud de la información será suficiente para que {clientName} y filiales pueda poner fin a la relación comercial/contractual con la empresa, sin responsabilidad legal. En constancia de haber leído, entendido y aceptado lo anterior, firmo el presente documento:
                        </p>
                      </Row>
                      {hasSign && format === "pdf" && !signed && apiForm?.status !== 'SENT' &&
                          <Row className="button-row">
                            <Col className="submitTrabajador" offset={18} style={{
                              fontSize: 4,
                              paddingTop: 60,
                              paddingRight: 30,
                              color: 'rgba(255,255,255,0)'
                            }}>
                              Firma_aqui
                            </Col>
                          </Row>
                      }
                      {format === "html" && !signed && apiForm?.status !== 'SENT' &&
                        <>
                          <Row className="">
                            <Col className="submitTrabajador" xl={24}>
                              <Button type="primary" htmlType="submit" disabled={openSigner}
                                        icon={openSigner ? 'loading' : 'file-protect'}>
                                  {hasSign ? 'Firmar' : 'Enviar'}
                              </Button>
                            </Col>
                          </Row>

                          {hasSign && signData?.embedUrl && signData?.provider === "DOCUSIGN" &&
                              <Modal visible={true} title="Firma" width={1200}
                                      onCancel={closeHandlerSigner}
                                      footer={[<Button
                                          onClick={closeHandlerSigner}>{t('messages.aml.btnClose')}</Button>]}
                              >
                                <iframe id="iframeDocument" src={signData.embedUrl} width="100%"
                                        height="480"></iframe>
                              </Modal>
                          }
                        </>
                      }
                  </>
                  )}
                </Form>
              </div>
            ) : (
              <h2 style={{ textAlign: "center" }}>Formulario no encontrado</h2>
            )}
          </div>
        )}
      </div>
    </FormLayout>
  );
};

export default withRouter(Form.create()(FormProv));
