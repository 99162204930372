import {FileManagerService} from "../services";

export default (filePath, destinationPath) => new Promise((resolve, reject) => {
    FileManagerService.validateMoveClientFile(filePath, destinationPath)
        .then(response => {
            resolve(response)
        })
        .catch(err => {
            reject(err.response)
})
})