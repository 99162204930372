// validarRUC.js
export default function validarRUCEmpresa(ruc) {
        // Verificar que tenga exactamente 11 dígitos
        const regex = /^\d{11}$/;
        if (!regex.test(ruc)) {
          return false;
        }
      
        // El RUC para empresas debe comenzar con "20"
        if (!ruc.startsWith("20")) {
          return false;
        }
      
        // Algoritmo de validación del dígito verificador
        const pesos = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
        let suma = 0;
      
        for (let i = 0; i < 10; i++) {
          suma += parseInt(ruc[i], 10) * pesos[i];
        }
      
        const resto = suma % 11;
        const digitoVerificador = (11 - resto) % 10;
      
        // Comparar con el último dígito del RUC
        return digitoVerificador === parseInt(ruc[10], 10);
      }
      