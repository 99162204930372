export const prodActOptions = [
        { name: "Accesorios", val: "Accesorios" },
        { name: "Alimentos y bebidas", val: "Alimentos y bebidas" },
        { name: "Áreas comunes", val: "Áreas comunes" },
        { name: "Automóviles", val: "Automóviles" },
        { name: "Belleza y cuidado personal", val: "Belleza y cuidado personal" },
        { name: "Bodegas", val: "Bodegas" },
        { name: "Cajas de compensación", val: "Cajas de compensación" },
        { name: "Calzado y vestuario", val: "Calzado y vestuario" },
        { name: "Casinos", val: "Casinos" },
        { name: "Cine", val: "Cine" },
        { name: "Clínicas y centros de salud", val: "Clínicas y centros de salud" },
        { name: "Computación y electrónica", val: "Computación y electrónica" },
        { name: "Deporte y outdoor", val: "Deporte y outdoor" },
        { name: "Educación", val: "Educación" },
        { name: "Entretenimiento familiar", val: "Entretenimiento familiar" },
        { name: "Entretenimiento adulto (discotecas y pubs)", val: "Entretenimiento adulto (discotecas y pubs)" },
        { name: "Farmacia", val: "Farmacia" },
        { name: "Food court", val: "Food court" },
        { name: "Gimnasios", val: "Gimnasios" },
        { name: "Hipódromos", val: "Hipódromos" },
        { name: "Hogar y decoración", val: "Hogar y decoración" },
        { name: "Hotel", val: "Hotel" },
        { name: "Inmobiliarias y constructoras", val: "Inmobiliarias y constructoras" },
        { name: "Joyería y relojería", val: "Joyería y relojería" },
        { name: "Juegos de azar / casa de cambio", val: "Juegos de azar / casa de cambio" },
        { name: "Maletería", val: "Maletería" },
        { name: "Mejoramiento del hogar", val: "Mejoramiento del hogar" },
        { name: "Oficinas", val: "Oficinas" },
        { name: "Organizaciones de caridad benéficas o sin ánimos de lucro", val: "Organizaciones de caridad benéficas o sin ánimos de lucro" },
        { name: "Otros comidas", val: "Otros comidas" },
        { name: "Otros retail", val: "Otros retail" },
        { name: "Publicidad", val: "Publicidad" },
        { name: "Restaurantes", val: "Restaurantes" },
        { name: "Servicios", val: "Servicios" },
        { name: "Servicios financieros y bancos", val: "Servicios financieros y bancos" },
        { name: "Supermercado", val: "Supermercado" },
        { name: "Telecomunicaciones", val: "Telecomunicaciones" },
        { name: "Terminal de buses", val: "Terminal de buses" },
        { name: "Tienda departamental", val: "Tienda departamental" },
        { name: "Usuarios de zonas francas", val: "Usuarios de zonas francas" }
      ]

      export const banksList = [
        { val: "Banco de Crédito del Perú", text: "Banco de Crédito del Perú" },
        { val: "Interbank", text: "Interbank" },
        { val: "Citibank del Perú S.A.", text: "Citibank del Perú S.A." },
        { val: "Scotiabank Perú S.A.", text: "Scotiabank Perú S.A." },
        { val: "BBVA Banco Continental", text: "BBVA Banco Continental" },
        { val: "BCI Perú S.A.", text: "BCI Perú S.A." },
        { val: "Banco de la Nación", text: "Banco de la Nación" },
        { val: "Santander Perú S.A.", text: "Santander Perú S.A." },
        { val: "Banco de Comercio", text: "Banco de Comercio" },
        { val: "Banco Financiero del Perú", text: "Banco Financiero del Perú" },
        { val: "BIF", text: "BIF" },
        { val: "GNB PERU S.A", text: "GNB PERU S.A" },
        { val: "Banco Falabella", text: "Banco Falabella" },
        { val: "Caja Piura", text: "Caja Piura" },
        { val: "Otro", text: "Otro"}
      ]
      

      export const SIIactivities = [
        {
          codigo: "111",
          descripcion: "CULTIVO DE CEREALES (EXCEPTO ARROZ), LEGUMBRES Y SEMILLAS OLEAGINOSAS"
        },
        {
          codigo: "112",
          descripcion: "CULTIVO DE ARROZ"
        },
        {
          codigo: "113",
          descripcion: "CULTIVO DE HORTALIZAS Y MELONES, RAÍCES Y TUBÉRCULOS."
        },
        {
          codigo: "114",
          descripcion: "CULTIVO DE CAÑA DE AZÚCAR"
        },
        {
          codigo: "115",
          descripcion: "CULTIVO DE TABACO"
        },
        {
          codigo: "116",
          descripcion: "CULTIVO DE PLANTAS DE FIBRA"
        },
        {
          codigo: "119",
          descripcion: "CULTIVO DE OTRAS PLANTAS NO PERENNES"
        },
        {
          codigo: "121",
          descripcion: "CULTIVO DE UVA"
        },
        {
          codigo: "122",
          descripcion: "CULTIVO DE FRUTAS TROPICALES Y SUBTROPICALES"
        },
        {
          codigo: "123",
          descripcion: "CULTIVO DE CÍTRICOS"
        },
        {
          codigo: "124",
          descripcion: "CULTIVO DE FRUTAS DE PEPITA Y DE HUESO"
        },
        {
          codigo: "125",
          descripcion: "CULTIVO DE OTROS FRUTOS Y NUECES DE ÁRBOLES Y ARBUSTOS"
        },
        {
          codigo: "126",
          descripcion: "CULTIVO DE FRUTOS OLEAGINOSOS"
        },
        {
          codigo: "127",
          descripcion: "CULTIVO DE PLANTAS CON LAS QUE SE PREPARAN BEBIDAS"
        },
        {
          codigo: "128",
          descripcion: "CULTIVO DE ESPECIAS Y DE PLANTAS AROMÁTICAS, MEDICINALES Y FARMACEUTICAS"
        },
        {
          codigo: "129",
          descripcion: "CULTIVO DE OTRAS PLANTAS NO PERENNES"
        },
        {
          codigo: "130",
          descripcion: "PROPAGACIÓN DE PLANTAS"
        },
        {
          codigo: "141",
          descripcion: "CRÍA DE GANADO BOVINO Y BÚFALOS"
        },
        {
          codigo: "142",
          descripcion: "CRÍA DE CABALLOS Y OTROS EQUINOS"
        },
        {
          codigo: "143",
          descripcion: "CRÍA DE CAMELLOS Y OTROS CAMÉLIDOS"
        },
        {
          codigo: "144",
          descripcion: "CRÍA DE OVEJAS Y CABRAS"
        },
        {
          codigo: "145",
          descripcion: "CRÍA DE CERDOS"
        },
        {
          codigo: "146",
          descripcion: "CRÍA DE AVES DE CORRAL"
        },
        {
          codigo: "149",
          descripcion: "CRÍA DE OTROS ANIMALES"
        },
        {
          codigo: "150",
          descripcion: "CULTIVO DE PRODUCTOS AGRÍCOLAS EN COMBINACIÓN CON LA CRÍA DE ANIMALES"
        },
        {
          codigo: "161",
          descripcion: "ACTIVIDADES DE APOYO A LA AGRICULTURA"
        },
        {
          codigo: "162",
          descripcion: "ACTIVIDADES DE APOYO A LA GANADERÍA"
        },
        {
          codigo: "163",
          descripcion: "ACTIVIDADES POSCOSECHA"
        },
        {
          codigo: "164",
          descripcion: "TRATAMIENTO DE SEMILLAS PARA PROPAGACIÓN"
        },
        {
          codigo: "170",
          descripcion: "CAZA ORDINARIA Y MEDIANTE TRAMPAS Y ACTIVIDADES DE SERVICIOS CONEXAS"
        },
        {
          codigo: "210",
          descripcion: "SILVICULTURA Y OTRAS ACTIVIDADES FORESTALES"
        },
        {
          codigo: "220",
          descripcion: "EXTRACCIÓN DE MADERA"
        },
        {
          codigo: "230",
          descripcion: "RECOLECCIÓN DE PRODUCTOS FORESTALES DISTINTOS DE LA MADERA"
        },
        {
          codigo: "240",
          descripcion: "SERVICIOS DE APOYO A LA SILVICULTURA"
        },
        {
          codigo: "311",
          descripcion: "PESCA MARÍTIMA"
        },
        {
          codigo: "312",
          descripcion: "PESCA DE AGUA DULCE"
        },
        {
          codigo: "321",
          descripcion: "ACUICULTURA MARÍTIMA"
        },
        {
          codigo: "322",
          descripcion: "ACUICULTURA DE AGUA DULCE"
        },
        {
          codigo: "510",
          descripcion: "EXTRACCIÓN DE CARBÓN DE PIEDRA"
        },
        {
          codigo: "520",
          descripcion: "EXTRACCIÓN DE LIGNITO"
        },
        {
          codigo: "610",
          descripcion: "EXTRACCIÓN DE PETRÓLEO CRUDO"
        },
        {
          codigo: "620",
          descripcion: "EXTRACCIÓN DE GAS NATURAL"
        },
        {
          codigo: "710",
          descripcion: "EXTRACCIÓN DE MINERALES DE HIERRO"
        },
        {
          codigo: "721",
          descripcion: "EXTRACCIÓN DE MINERALES DE URANIO Y TORIO"
        },
        {
          codigo: "729",
          descripcion: "EXTRACCIÓN DE OTROS MINERALES METALÍFEROS NO FERROSOS"
        },
        {
          codigo: "810",
          descripcion: "EXTRACCIÓN DE PIEDRA, ARENA Y ARCILLA"
        },
        {
          codigo: "891",
          descripcion: "EXTRACCIÓN DE MINERALES PARA LA FABRICACIÓN DE ABONOS Y PRODUCTOS QUÍMICOS"
        },
        {
          codigo: "892",
          descripcion: "EXTRACCIÓN DE TURBA"
        },
        {
          codigo: "893",
          descripcion: "EXTRACCIÓN DE SAL"
        },
        {
          codigo: "899",
          descripcion: "EXPLOTACIÓN DE OTRAS MINAS Y CANTERAS N.C.P."
        },
        {
          codigo: "910",
          descripcion: "ACTIVIDADES DE APOYO PARA LA EXTRACCIÓN DE PETRÓLEO Y GAS NATURAL"
        },
        {
          codigo: "990",
          descripcion: "ACTIVIDADES DE APOYO PARA OTRAS ACTIVIDADES DE EXPLOTACIÓN DE MINAS Y CANTERAS"
        },
        {
          codigo: "1010",
          descripcion: "ELABORACIÓN Y CONSERVACIÓN DE CARNE"
        },
        {
          codigo: "1020",
          descripcion: "ELABORACIÓN Y CONSERVACIÓN DE PESCADOS, CRUSTÁCEOS Y MOLUSCOS"
        },
        {
          codigo: "1030",
          descripcion: "ELABORACIÓN Y CONSERVACIÓN DE FRUTAS, LEGUMBRES Y HORTALIZAS"
        },
        {
          codigo: "1040",
          descripcion: "ELABORACIÓN DE ACEITES Y GRASAS DE ORIGEN VEGETAL Y ANIMAL"
        },
        {
          codigo: "1050",
          descripcion: "ELABORACIÓN DE PRODUCTOS LÁCTEOS"
        },
        {
          codigo: "1061",
          descripcion: "ELABORACIÓN DE PRODUCTOS DE MOLINERÍA."
        },
        {
          codigo: "1062",
          descripcion: "ELABORACIÓN DE ALMIDONES Y PRODUCTOS DERIVADOS DEL ALMIDÓN."
        },
        {
          codigo: "1071",
          descripcion: "ELABORACIÓN DE PRODUCTOS DE PANADERÍA"
        },
        {
          codigo: "1072",
          descripcion: "ELABORACIÓN DE AZÚCAR"
        },
        {
          codigo: "1073",
          descripcion: "ELABORACIÓN DE CACAO Y CHOCOLATE Y DE PRODUCTOS DE CONFITERÍA"
        },
        {
          codigo: "1074",
          descripcion: "ELABORACIÓN DE MACARRONES, FIDEOS, ALCUZCUS Y PRODUCTOS FARINÁCEOS SIMILARES"
        },
        {
          codigo: "1075",
          descripcion: "ELABORACIÓN DE COMIDAS Y PLATOS PREPARADOS"
        },
        {
          codigo: "1079",
          descripcion: "ELABORACIÓN DE OTROS PRODUCTOS ALIMENTICIOS N.C.P."
        },
        {
          codigo: "1080",
          descripcion: "ELABORACIÓN DE PIENSOS PREPARADOS PARA ANIMALES"
        },
        {
          codigo: "1101",
          descripcion: "DESTILACIÓN, RECTIFICACIÓN Y MEZCLA DE BEBIDAS ALCOHÓLICAS"
        },
        {
          codigo: "1102",
          descripcion: "ELABORACIÓN DE VINOS"
        },
        {
          codigo: "1103",
          descripcion: "ELABORACIÓN DE BEBIDAS MALTEADAS Y DE MALTA"
        },
        {
          codigo: "1104",
          descripcion: "ELABORACIÓN DE BEBIDAS NO ALCOHÓLICAS; PRODUCCIÓN DE AGUAS MINERALES Y OTRAS AGUAS EMBOTELLADAS"
        },
        {
          codigo: "1200",
          descripcion: "ELABORACIÓN DE PRODUCTOS DE TABACO"
        },
        {
          codigo: "1311",
          descripcion: "PREPARACIÓN E HILATURA DE FIBRAS TEXTILES"
        },
        {
          codigo: "1312",
          descripcion: "TEJEDURA DE PRODUCTOS TEXTILES"
        },
        {
          codigo: "1313",
          descripcion: "ACABADO DE PRODUCTOS TEXTILES"
        },
        {
          codigo: "1391",
          descripcion: "FABRICACIÓN DE TEJIDOS DE PUNTO Y GANCHILLO"
        },
        {
          codigo: "1392",
          descripcion: "FABRICACIÓN DE ARTÍCULOS CONFECCIONADOS DE MATERIALES TEXTILES, EXCEPTO PRENDAS DE VESTIR"
        },
        {
          codigo: "1393",
          descripcion: "FABRICACIÓN DE TAPICES Y ALFOMBRAS"
        },
        {
          codigo: "1394",
          descripcion: "FABRICACIÓN DE CUERDAS, CORDELES, BRAMANTES Y REDES"
        },
        {
          codigo: "1399",
          descripcion: "FABRICACIÓN DE OTROS PRODUCTOS TEXTILES N.C.P."
        },
        {
          codigo: "1410",
          descripcion: "FABRICACIÓN DE PRENDAS DE VESTIR, EXCEPTO PRENDAS DE PIEL"
        },
        {
          codigo: "1420",
          descripcion: "FABRICACIÓN DE ARTÍCULOS DE PIEL"
        },
        {
          codigo: "1430",
          descripcion: "FABRICACIÓN DE ARTÍCULOS DE PUNTO Y GANCHILLO"
        },
        {
          codigo: "1511",
          descripcion: "CURTIDO Y ADOBO DE CUEROS; ADOBO Y TEÑIDO DE PIELES"
        },
        {
          codigo: "1512",
          descripcion: "FABRICACIÓN DE MALETAS, BOLSOS DE MANO, Y ARTÍCULOS SIMILARES, Y DE ARTÍCULOS DE TALABARTERÍA Y GUARNICIONERÍA"
        },
        {
          codigo: "1520",
          descripcion: "FABRICACIÓN DE CALZADO"
        },
        {
          codigo: "1610",
          descripcion: "ASERRADOS Y ACEPILLADURA DE MADERA"
        },
        {
          codigo: "1621",
          descripcion: "FABRICACIÓN DE HOJAS DE MADERA PARA ENCHAPADO Y TABLEROS A BASE DE MADERA"
        },
        {
          codigo: "1622",
          descripcion: "FABRICACIÓN DE PARTES Y PIEZAS DE CARPINTERÍA PARA EDIFICIOS Y CONSTRUCCIONES"
        },
        {
          codigo: "1623",
          descripcion: "FABRICACIÓN DE RECIPIENTES DE MADERA"
        },
        {
          codigo: "1629",
          descripcion: "FABRICACIÓN DE OTROS PRODUCTOS DE MADERA; FABRICACIÓN DE ARTÍCULOS DE CORCHO, PAJA Y MATERIALES TRENZABLES."
        },
        {
          codigo: "1701",
          descripcion: "FABRICACIÓN DE PASTA DE MADERA, PAPEL Y CARTÓN"
        },
        {
          codigo: "1702",
          descripcion: "FABRICACIÓN DEL PAPEL Y CARTÓN ONDULADO Y DE ENVASES DE PAPEL Y CARTÓN"
        },
        {
          codigo: "1709",
          descripcion: "FABRICACIÓN DE OTROS ARTÍCULOS DEL PAPEL Y CARTÓN"
        },
        {
          codigo: "1811",
          descripcion: "IMPRESIÓN"
        },
        {
          codigo: "1812",
          descripcion: "ACTIVIDADES DE SERVICIOS RELACIONADAS CON LA IMPRESIÓN"
        },
        {
          codigo: "1820",
          descripcion: "REPRODUCCIÓN DE GRABACIONES"
        },
        {
          codigo: "1910",
          descripcion: "FABRICACIÓN DE PRODUCTOS DE HORNOS DE COQUE"
        },
        {
          codigo: "1920",
          descripcion: "FABRICACIÓN DE PRODUCTOS DE LA REFINACIÓN DEL PETRÓLEO"
        },
        {
          codigo: "2011",
          descripcion: "FABRICACIÓN DE SUSTANCIAS QUÍMICAS BÁSICAS"
        },
        {
          codigo: "2012",
          descripcion: "FABRICACIÓN DE ABONOS Y COMPUESTOS DE NITRÓGENO"
        },
        {
          codigo: "2013",
          descripcion: "FABRICACIÓN DE PLÁSTICOS Y DE CAUCHO SINTÉTICO EN FORMAS PRIMARIAS"
        },
        {
          codigo: "2021",
          descripcion: "FABRICACIÓN DE PLAGUICIDAS Y OTROS PRODUCTOS QUÍMICOS DE USO AGROPECUARIO"
        },
        {
          codigo: "2022",
          descripcion: "FABRICACIÓN DE PINTURAS, BARNICES Y PRODUCTOS DE REVESTIMIENTO SIMILARES, TINTAS DE IMPRENTA Y MASILLAS"
        },
        {
          codigo: "2023",
          descripcion: "FABRICACIÓN DE JABONES Y DETERGENTES, PREPARADOS PARA LIMPIAR Y PULIR, PERFUMES Y PREPARADOS DE TOCADOR."
        },
        {
          codigo: "2029",
          descripcion: "FABRICACIÓN DE OTROS PRODUCTOS QUÍMICOS N.C.P. (INCLUYE EXPLOSIVOS)"
        },
        {
          codigo: "2030",
          descripcion: "FABRICACIÓN DE FIBRAS ARTIFICIALES"
        },
        {
          codigo: "2100",
          descripcion: "FABRICACIÓN DE PRODUCTOS FARMACÉUTICOS, SUSTANCIAS QUÍMICAS MEDICINALES Y PRODUCTOS BOTÁNICOS DE USO FARMACÉUTICO"
        },
        {
          codigo: "2211",
          descripcion: "FABRICACIÓN DE CUBIERTAS Y CÁMARAS DE CAUCHO; RECAUCHUTADO Y RENOVACIÓN DE CUBIERTAS DE CAUCHO"
        },
        {
          codigo: "2219",
          descripcion: "FABRICACIÓN DE OTROS PRODUCTOS DE CAUCHO"
        },
        {
          codigo: "2220",
          descripcion: "FABRICACIÓN DE PRODUCTOS DE PLÁSTICO"
        },
        {
          codigo: "2310",
          descripcion: "FABRICACIÓN DE VIDRIO Y DE PRODUCTOS DE VIDRIO"
        },
        {
          codigo: "2391",
          descripcion: "FABRICACIÓN DE PRODUCTOS REFRACTARIOS"
        },
        {
          codigo: "2392",
          descripcion: "FABRICACIÓN DE MATERIALES DE CONSTRUCCIÓN DE ARCILLA"
        },
        {
          codigo: "2393",
          descripcion: "FABRICACIÓN DE OTROS PRODUCTOS DE PORCELANA Y DE CERÁMICA"
        },
        {
          codigo: "2394",
          descripcion: "FABRICACIÓN DE CEMENTO, CAL Y YESO"
        },
        {
          codigo: "2395",
          descripcion: "FABRICACIÓN DE ARTÍCULOS DE HORMIGÓN, DE CEMENTO Y DE YESO"
        },
        {
          codigo: "2396",
          descripcion: "CORTE, TALLA Y ACABADO DE LA PIEDRA"
        },
        {
          codigo: "2399",
          descripcion: "FABRICACIÓN DE OTROS PRODUCTOS MINERALES NO METÁLICOS N.C.P."
        },
        {
          codigo: "2410",
          descripcion: "INDUSTRIAS BÁSICAS DE HIERRO Y ACERO"
        },
        {
          codigo: "2420",
          descripcion: "FABRICACIÓN DE PRODUCTOS PRIMARIOS DE METALES PRECIOSOS Y OTROS METALES NO FERROSOS"
        },
        {
          codigo: "2431",
          descripcion: "FUNDICIÓN DE HIERRO Y ACERO"
        },
        {
          codigo: "2432",
          descripcion: "FUNDICIÓN DE METALES NO FERROSOS"
        },
        {
          codigo: "2511",
          descripcion: "FABRICACIÓN DE PRODUCTOS METÁLICOS PARA USO ESTRUCTURAL"
        },
        {
          codigo: "2512",
          descripcion: "FABRICACIÓN DE TANQUES, DEPÓSITOS Y RECIPIENTES DE METAL"
        },
        {
          codigo: "2513",
          descripcion: "FABRICACIÓN DE LOS GENERADORES DEL VAPOR, EXCEPTO CALDERAS DE AGUA CALIENTE PARA CALEFACCIÓN CENTRAL"
        },
        {
          codigo: "2520",
          descripcion: "FABRICACIÓN DE ARMAS Y MUNICIONES"
        },
        {
          codigo: "2591",
          descripcion: "FORJA, PRENSADO, ESTAMPADO Y LAMINADO DE METALES; PULVIMETALURGIA"
        },
        {
          codigo: "2592",
          descripcion: "TRATAMIENTO Y REVESTIMIENTO DE METALES; MAQUINADO"
        },
        {
          codigo: "2593",
          descripcion: "FABRICACIÓN DE ARTÍCULOS DE CUCHILLERÍA, HERRAMIENTAS DE MANO Y ARTÍCULOS DE FERRETERÍA"
        },
        {
          codigo: "2599",
          descripcion: "FABRICACIÓN DE OTROS PRODUCTOS ELABORADOS DE METAL N.C.P."
        },
        {
          codigo: "2610",
          descripcion: "FABRICACIÓN DE COMPONENTES Y TABLEROS ELECTRÓNICOS"
        },
        {
          codigo: "2620",
          descripcion: "FABRICACIÓN DE ORDENADORES Y EQUIPO PERIFÉRICO"
        },
        {
          codigo: "2630",
          descripcion: "FABRICACIÓN DE EQUIPOS DE COMUNICACIONES"
        },
        {
          codigo: "2640",
          descripcion: "FABRICACIÓN DE APARATOS ELECTRÓNICOS DE CONSUMO"
        },
        {
          codigo: "2651",
          descripcion: "FABRICACIÓN DE EQUIPO DE MEDICIÓN, PRUEBA, NAVEGACIÓN Y CONTROL"
        },
        {
          codigo: "2652",
          descripcion: "FABRICACIÓN DE RELOJES"
        },
        {
          codigo: "2660",
          descripcion: "FABRICACIÓN DE EQUIPO DE IRRADIACIÓN Y EQUIPO ELECTRÓNICO DE USO MÉDICO Y TERAPÉUTICO"
        },
        {
          codigo: "2670",
          descripcion: "FABRICACIÓN DE INSTRUMENTOS ÓPTICOS Y EQUIPO FOTOGRÁFICO"
        },
        {
          codigo: "2680",
          descripcion: "FABRICACIÓN DE SOPORTES MAGNÉTICOS Y ÓPTICOS"
        },
        {
          codigo: "2710",
          descripcion: "FABRICACIÓN DE MOTORES, GENERADORES Y TRANSFORMADORES ELÉCTRICOS Y APARATOS DE DISTRIBUCIÓN Y CONTROL DE LA ENERGÍA ELÉCTRICA"
        },
        {
          codigo: "2720",
          descripcion: "FABRICACIÓN DE PILAS, BATERÍAS Y ACUMULADORES"
        },
        {
          codigo: "2731",
          descripcion: "FABRICACIÓN DE CABLES DE FIBRA ÓPTICA"
        },
        {
          codigo: "2732",
          descripcion: "FABRICACIÓN DE OTROS HILOS Y CABLES ELÉCTRICOS"
        },
        {
          codigo: "2733",
          descripcion: "FABRICACIÓN DE DISPOSITIVOS DE CABLEADO"
        },
        {
          codigo: "2740",
          descripcion: "FABRICACIÓN DE EQUIPO ELÉCTRICO DE ILUMINACIÓN"
        },
        {
          codigo: "2750",
          descripcion: "FABRICACIÓN DE APARATOS DE USO DOMÉSTICO"
        },
        {
          codigo: "2790",
          descripcion: "FABRICACIÓN DE OTROS TIPOS DE EQUIPO ELÉCTRICO"
        },
        {
          codigo: "2811",
          descripcion: "FABRICACIÓN DE MOTORES Y TURBINAS, EXCEPTO MOTORES PARA AERONAVES, VEHÍCULOS AUTOMOTORES Y MOTOCICLETAS"
        },
        {
          codigo: "2812",
          descripcion: "FABRICACIÓN DE EQUIPO DE PROPULSIÓN DE FLUIDOS"
        },
        {
          codigo: "2813",
          descripcion: "FABRICACIÓN DE BOMBAS, COMPRESORES, GRIFOS Y VÁLVULAS"
        },
        {
          codigo: "2814",
          descripcion: "FABRICACIÓN DE COJINETES, ENGRANAJES, TRENES DE ENGRANAJES Y PIEZAS DE TRANSMISIÓN"
        },
        {
          codigo: "2815",
          descripcion: "FABRICACIÓN DE HORNOS, HOGARES Y QUEMADORES"
        },
        {
          codigo: "2816",
          descripcion: "FABRICACIÓN DE EQUIPO DE ELEVACIÓN Y MANIPULACIÓN"
        },
        {
          codigo: "2817",
          descripcion: "FABRICACIÓN DE MAQUINARIA Y EQUIPO DE OFICINA (EXCEPTO ORDENADORES Y EQUIPO PERIFÉRICO)"
        },
        {
          codigo: "2818",
          descripcion: "FABRICACIÓN DE HERRAMIENTAS DE MANO MOTORIZADAS"
        },
        {
          codigo: "2819",
          descripcion: "FABRICACIÓN DE OTROS TIPOS DE MAQUINARIA DE USO GENERAL"
        },
        {
          codigo: "2821",
          descripcion: "FABRICACIÓN DE MAQUINARIA AGROPECUARIA Y FORESTAL"
        },
        {
          codigo: "2822",
          descripcion: "FABRICACIÓN DE MAQUINARIA PARA LA CONFORMACIÓN DE METALES Y DE MÁQUINAS HERRAMIENTA"
        },
        {
          codigo: "2823",
          descripcion: "FABRICACIÓN DE MAQUINARIA METALÚRGICA"
        },
        {
          codigo: "2824",
          descripcion: "FABRICACIÓN DE MAQUINARIA PARA EXPLOTACIÓN DE MINAS Y CANTERAS Y PARA OBRAS DE CONSTRUCCIÓN"
        },
        {
          codigo: "2825",
          descripcion: "FABRICACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE ALIMENTOS, BEBIDAS Y TABACO"
        },
        {
          codigo: "2826",
          descripcion: "FABRICACIÓN DE MAQUINARIA PARA LA ELABORACIÓN DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CUEROS"
        },
        {
          codigo: "2829",
          descripcion: "FABRICACIÓN DE OTROS TIPOS DE MAQUINARIA DE USO ESPECIAL"
        },
        {
          codigo: "2910",
          descripcion: "FABRICACIÓN DE VEHÍCULOS AUTOMOTORES"
        },
        {
          codigo: "2920",
          descripcion: "FABRICACIÓN DE CARROCERÍAS PARA VEHÍCULOS AUTOMOTORES; FABRICACIÓN DE REMOLQUES Y SEMIRREMOLQUES"
        },
        {
          codigo: "2930",
          descripcion: "FABRICACIÓN DE PARTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS AUTOMOTORES"
        },
        {
          codigo: "3011",
          descripcion: "CONSTRUCCIÓN DE BUQUES Y ESTRUCTURAS FLOTANTES"
        },
        {
          codigo: "3012",
          descripcion: "CONSTRUCCIÓN DE EMBARCACIONES DE RECREO Y DEPORTE"
        },
        {
          codigo: "3020",
          descripcion: "FABRICACIÓN DE LOCOMOTORAS Y DE MATERIAL RODANTE"
        },
        {
          codigo: "3030",
          descripcion: "FABRICACIÓN DE AERONAVES Y NAVES ESPACIALES Y MAQUINARIA CONEXA"
        },
        {
          codigo: "3040",
          descripcion: "FABRICACIÓN DE VEHÍCULOS MILITARES DE COMBATE"
        },
        {
          codigo: "3091",
          descripcion: "FABRICACIÓN DE MOTOCICLETAS"
        },
        {
          codigo: "3092",
          descripcion: "FABRICACIÓN DE BICICLETAS Y DE SILLONES DE RUEDAS PARA INVÁLIDOS"
        },
        {
          codigo: "3099",
          descripcion: "FABRICACIÓN DE OTROS TIPOS DE EQUIPO DE TRANSPORTE N.C.P."
        },
        {
          codigo: "3100",
          descripcion: "FABRICACIÓN DE MUEBLES"
        },
        {
          codigo: "3211",
          descripcion: "FABRICACIÓN DE JOYAS Y ARTÍCULOS CONEXOS"
        },
        {
          codigo: "3212",
          descripcion: "FABRICACIÓN DE BISUTERÍA Y ARTÍCULOS CONEXOS"
        },
        {
          codigo: "3220",
          descripcion: "FABRICACIÓN DE INSTRUMENTOS DE MÚSICA"
        },
        {
          codigo: "3230",
          descripcion: "FABRICACIÓN DE ARTÍCULOS DE DEPORTE"
        },
        {
          codigo: "3240",
          descripcion: "FABRICACIÓN DE JUEGOS Y JUGUETES"
        },
        {
          codigo: "3250",
          descripcion: "FABRICACIÓN DE INSTRUMENTOS Y MATERIALES MÉDICOS Y ODONTOLÓGICOS"
        },
        {
          codigo: "3290",
          descripcion: "OTRAS INDUSTRIAS MANUFACTURERAS N.C.P."
        },
        {
          codigo: "3311",
          descripcion: "REPARACIÓN DE PRODUCTOS ELABORADOS DE METAL"
        },
        {
          codigo: "3312",
          descripcion: "REPARACIÓN DE MAQUINARIA"
        },
        {
          codigo: "3313",
          descripcion: "REPARACIÓN DE EQUIPO ELECTRÓNICO Y ÓPTICO"
        },
        {
          codigo: "3314",
          descripcion: "REPARACIÓN DE EQUIPO ELÉCTRICO"
        },
        {
          codigo: "3315",
          descripcion: "REPARACIÓN DE EQUIPO DE TRANSPORTE, EXCEPTO VEHÍCULOS AUTOMOTORES"
        },
        {
          codigo: "3319",
          descripcion: "REPARACIÓN DE OTROS TIPOS DE EQUIPO"
        },
        {
          codigo: "3320",
          descripcion: "INSTALACIÓN DE MAQUINARIA Y EQUIPO INDUSTRIALES"
        },
        {
          codigo: "3510",
          descripcion: "GENERACIÓN, TRANSMISIÓN Y DISTRIBUCIÓN DE ENERGÍA ELÉCTRICA"
        },
        {
          codigo: "3520",
          descripcion: "FABRICACIÓN DEL GAS; DISTRIBUCIÓN DE COMBUSTIBLES GASEOSOS POR TUBERÍAS"
        },
        {
          codigo: "3530",
          descripcion: "SUMINISTRO DE VAPOR Y AIRE ACONDICIONADO"
        },
        {
          codigo: "3600",
          descripcion: "CAPTACIÓN, TRATAMIENTO Y DISTRIBUCIÓN DE AGUA"
        },
        {
          codigo: "3700",
          descripcion: "EVACUACIÓN DE AGUAS RESIDUALES"
        },
        {
          codigo: "3811",
          descripcion: "RECOGIDA DE DESECHOS NO PELIGROSOS"
        },
        {
          codigo: "3812",
          descripcion: "RECOGIDA DE DESECHOS PELIGROSOS"
        },
        {
          codigo: "3821",
          descripcion: "TRATAMIENTO Y ELIMINACIÓN DE DESECHOS NO PELIGROSOS"
        },
        {
          codigo: "3822",
          descripcion: "TRATAMIENTO Y ELIMINACIÓN DE DESECHOS PELIGROSOS"
        },
        {
          codigo: "3830",
          descripcion: "RECUPERACIÓN DE MATERIALES"
        },
        {
          codigo: "3900",
          descripcion: "ACTIVIDADES DE DESCONTAMINACIÓN Y OTROS SERVICIOS DE GESTIÓN DE DESECHOS"
        },
        {
          codigo: "4100",
          descripcion: "CONSTRUCCIÓN DE EDIFICIOS"
        },
        {
          codigo: "4210",
          descripcion: "CONSTRUCCIÓN DE CARRETERAS Y LÍNEAS DE FERROCARRIL"
        },
        {
          codigo: "4220",
          descripcion: "CONSTRUCCIÓN DE PROYECTOS DE SERVICIO PÚBLICO"
        },
        {
          codigo: "4290",
          descripcion: "CONSTRUCCIÓN DE OTRAS OBRAS DE INGENIERÍA CIVIL"
        },
        {
          codigo: "4311",
          descripcion: "DEMOLICIÓN"
        },
        {
          codigo: "4312",
          descripcion: "PREPARACIÓN DEL TERRENO"
        },
        {
          codigo: "4321",
          descripcion: "INSTALACIONES ELÉCTRICAS"
        },
        {
          codigo: "4322",
          descripcion: "INSTALACIONES DE FONTANERÍA, CALEFACCIÓN Y AIRE ACONDICIONADO"
        },
        {
          codigo: "4329",
          descripcion: "OTRAS INSTALACIONES PARA OBRAS DE CONSTRUCCIÓN"
        },
        {
          codigo: "4330",
          descripcion: "TERMINACIÓN Y ACABADO DE EDIFICIOS"
        },
        {
          codigo: "4390",
          descripcion: "OTRAS ACTIVIDADES ESPECIALIZADAS DE LA CONSTRUCCIÓN"
        },
        {
          codigo: "4510",
          descripcion: "VENTA DE VEHÍCULOS AUTOMOTORES"
        },
        {
          codigo: "4520",
          descripcion: "MANTENIMIENTO Y REPARACIÓN DE VEHÍCULOS AUTOMOTORES"
        },
        {
          codigo: "4530",
          descripcion: "VENTA DE PARTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS AUTOMOTORES"
        },
        {
          codigo: "4540",
          descripcion: "VENTA, MANTENIMIENTO Y REPARACIÓN DE MOTOCICLETAS Y DE SUS PARTES, PIEZAS Y ACCESORIOS"
        },
        {
          codigo: "4610",
          descripcion: "VENTA AL POR MAYOR A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATA"
        },
        {
          codigo: "4620",
          descripcion: "VENTA AL POR MAYOR DE MATERIAS PRIMAS AGROPECUARIAS Y ANIMALES VIVOS"
        },
        {
          codigo: "4630",
          descripcion: "VENTA AL POR MAYOR DE ALIMENTOS, BEBIDAS Y TABACO"
        },
        {
          codigo: "4641",
          descripcion: "VENTA AL POR MAYOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO"
        },
        {
          codigo: "4649",
          descripcion: "VENTA AL POR MAYOR DE OTROS ENSERES DOMÉSTICOS"
        },
        {
          codigo: "4651",
          descripcion: "VENTA AL POR MAYOR DE ORDENADORES, EQUIPO PERIFÉRICO Y PROGRAMAS DE INFORMÁTICA"
        },
        {
          codigo: "4652",
          descripcion: "VENTA AL POR MAYOR DE EQUIPO, PARTES Y PIEZAS ELECTRÓNICOS Y DE TELECOMUNICACIONES"
        },
        {
          codigo: "4653",
          descripcion: "VENTA AL POR MAYOR DE MAQUINARIA, EQUIPO Y MATERIALES AGROPECUARIOS"
        },
        {
          codigo: "4659",
          descripcion: "VENTA AL POR MAYOR DE OTROS TIPOS DE MAQUINARIA Y EQUIPO"
        },
        {
          codigo: "4661",
          descripcion: "VENTA AL POR MAYOR DE COMBUSTIBLES SÓLIDOS, LÍQUIDOS Y GASEOSOS Y PRODUCTOS CONEXOS"
        },
        {
          codigo: "4662",
          descripcion: "VENTA AL POR MAYOR DE METALES Y MINERALES METALÍFEROS"
        },
        {
          codigo: "4663",
          descripcion: "VENTA AL POR MAYOR DE MATERIALES DE CONSTRUCCIÓN, ARTÍCULOS DE FERRETERÍA Y EQUIPO Y MATERIALES DE FONTANERÍA Y CALEFACCIÓN"
        },
        {
          codigo: "4669",
          descripcion: "VENTA AL POR MAYOR DE DESPERDICIOS, DESECHOS, CHATARRA Y OTROS PRODUCTOS N.C.P."
        },
        {
          codigo: "4690",
          descripcion: "VENTA AL POR MAYOR NO ESPECIALIZADA"
        },
        {
          codigo: "4711",
          descripcion: "VENTA AL POR MENOR EN COMERCIOS NO ESPECIALIZADOS CON PREDOMINIO DE LA VENTA DE ALIMENTOS, BEBIDAS O TABACO"
        },
        {
          codigo: "4719",
          descripcion: "OTRAS ACTIVIDADES DE VENTA AL POR MENOR EN COMERCIOS NO ESPECIALIZADOS"
        },
        {
          codigo: "4721",
          descripcion: "VENTA AL POR MENOR DE ALIMENTOS EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4722",
          descripcion: "VENTA AL POR MENOR DE BEBIDAS EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4723",
          descripcion: "VENTA AL POR MENOR DE PRODUCTOS DE TABACO EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4730",
          descripcion: "VENTA AL POR MENOR DE COMBUSTIBLES PARA VEHÍCULOS AUTOMOTORES EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4741",
          descripcion: "VENTA AL POR MENOR DE ORDENADORES, EQUIPO PERIFÉRICO, PROGRAMAS INFORMÁTICOS Y EQUIPO DE TELECOMUNICACIONES EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4742",
          descripcion: "VENTA AL POR MENOR DE EQUIPO DE SONIDO Y DE VÍDEO EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4751",
          descripcion: "VENTA AL POR MENOR DE PRODUCTOS TEXTILES EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4752",
          descripcion: "VENTA AL POR MENOR DE ARTÍCULOS DE FERRETERÍA, PINTURAS Y PRODUCTOS DE VIDRIO EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4753",
          descripcion: "VENTA AL POR MENOR DE TAPICES, ALFOMBRAS Y CUBRIMIENTOS PARA PAREDES Y PISOS EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4759",
          descripcion: "VENTA AL POR MENOR DE APARATOS ELÉCTRICOS DE USO DOMÉSTICO, MUEBLES, EQUIPO DE ILUMINACIÓN Y OTROS ENSERES DOMÉSTICOS EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4761",
          descripcion: "VENTA AL POR MENOR DE LIBROS, PERIÓDICOS Y ARTÍCULOS DE PAPELERÍA EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4762",
          descripcion: "VENTA AL POR MENOR DE GRABACIONES DE MÚSICA Y DE VÍDEO EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4763",
          descripcion: "VENTA AL POR MENOR DE EQUIPO DE DEPORTE EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4764",
          descripcion: "VENTA AL POR MENOR DE JUEGOS Y JUGUETES EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4771",
          descripcion: "VENTA AL POR MENOR DE PRENDAS DE VESTIR, CALZADO Y ARTÍCULOS DE CUERO EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4772",
          descripcion: "VENTA AL POR MENOR DE PRODUCTOS FARMACÉUTICOS Y MEDICINALES, COSMÉTICOS Y ARTÍCULOS DE TOCADOR EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4773",
          descripcion: "VENTA AL POR MENOR DE OTROS PRODUCTOS NUEVOS EN COMERCIOS ESPECIALIZADOS"
        },
        {
          codigo: "4774",
          descripcion: "VENTA AL POR MENOR DE ARTÍCULOS DE SEGUNDA MANO"
        },
        {
          codigo: "4781",
          descripcion: "VENTA AL POR MENOR DE ALIMENTOS, BEBIDAS Y TABACO EN PUESTOS DE VENTA Y MERCADOS"
        },
        {
          codigo: "4782",
          descripcion: "VENTA AL POR MENOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO EN PUESTOS DE VENTA Y MERCADOS"
        },
        {
          codigo: "4789",
          descripcion: "VENTA AL POR MENOR DE OTROS PRODUCTOS EN PUESTOS DE VENTA Y MERCADOS"
        },
        {
          codigo: "4791",
          descripcion: "VENTA AL POR MENOR POR CORREO Y POR INTERNET"
        },
        {
          codigo: "4799",
          descripcion: "OTRAS ACTIVIDADES DE VENTA AL POR MENOR NO REALIZADAS EN COMERCIOS, PUESTOS DE VENTA O MERCADOS"
        },
        {
          codigo: "4911",
          descripcion: "TRANSPORTE INTERURBANO DE PASAJEROS POR FERROCARRIL"
        },
        {
          codigo: "4912",
          descripcion: "TRANSPORTE DE CARGA POR FERROCARRIL"
        },
        {
          codigo: "4921",
          descripcion: "TRANSPORTE URBANO Y SUBURBANO DE PASAJEROS POR VÍA TERRESTRE"
        },
        {
          codigo: "4922",
          descripcion: "OTRAS ACTIVIDADES DE TRANSPORTE POR VÍA TERRESTRE"
        },
        {
          codigo: "4923",
          descripcion: "TRANSPORTE DE CARGA POR CARRETERA"
        },
        {
          codigo: "4930",
          descripcion: "TRANSPORTE POR TUBERÍAS"
        },
        {
          codigo: "5011",
          descripcion: "TRANSPORTE DE PASAJEROS MARÍTIMO Y DE CABOTAJE"
        },
        {
          codigo: "5012",
          descripcion: "TRANSPORTE DE CARGA MARÍTIMO Y DE CABOTAJE"
        },
        {
          codigo: "5021",
          descripcion: "TRANSPORTE DE PASAJEROS POR VÍAS DE NAVEGACIÓN INTERIORES"
        },
        {
          codigo: "5022",
          descripcion: "TRANSPORTE DE CARGA, POR VÍAS DE NAVEGACIÓN INTERIORES"
        },
        {
          codigo: "5110",
          descripcion: "TRANSPORTE DE PASAJEROS POR VÍA AÉREA"
        },
        {
          codigo: "5120",
          descripcion: "TRANSPORTE DE CARGA POR VÍA AÉREA"
        },
        {
          codigo: "5210",
          descripcion: "ALMACENAMIENTO Y DEPÓSITO"
        },
        {
          codigo: "5221",
          descripcion: "ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE TERRESTRE"
        },
        {
          codigo: "5222",
          descripcion: "ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE ACUÁTICO"
        },
        {
          codigo: "5223",
          descripcion: "ACTIVIDADES DE SERVICIOS VINCULADAS AL TRANSPORTE AÉREO"
        },
        {
          codigo: "5224",
          descripcion: "MANIPULACIÓN DE CARGA"
        },
        {
          codigo: "5229",
          descripcion: "OTRAS ACTIVIDADES DE APOYO AL TRANSPORTE"
        },
        {
          codigo: "5310",
          descripcion: "ACTIVIDADES POSTALES"
        },
        {
          codigo: "5320",
          descripcion: "ACTIVIDADES DE MENSAJERÍA"
        },
        {
          codigo: "5510",
          descripcion: "ACTIVIDADES DE ALOJAMIENTO PARA ESTANCIAS CORTAS"
        },
        {
          codigo: "5520",
          descripcion: "ACTIVIDADES DE CAMPAMENTOS, PARQUES DE VEHÍCULOS RECREATIVOS Y PARQUES DE CARAVANAS"
        },
        {
          codigo: "5590",
          descripcion: "OTRAS ACTIVIDADES DE ALOJAMIENTO"
        },
        {
          codigo: "5610",
          descripcion: "ACTIVIDADES DE RESTAURANTES Y DE SERVICIO MÓVIL DE COMIDAS"
        },
        {
          codigo: "5621",
          descripcion: "SUMINISTRO DE COMIDAS POR ENCARGO"
        },
        {
          codigo: "5629",
          descripcion: "OTRAS ACTIVIDADES DE SERVICIO DE COMIDAS"
        },
        {
          codigo: "5630",
          descripcion: "ACTIVIDADES DE SERVICIO DE BEBIDAS"
        },
        {
          codigo: "5811",
          descripcion: "EDICIÓN DE LIBROS"
        },
        {
          codigo: "5812",
          descripcion: "EDICIÓN DE DIRECTORIOS Y LISTAS DE CORREO"
        },
        {
          codigo: "5813",
          descripcion: "EDICIÓN DE PERIÓDICOS, REVISTAS Y OTRAS PUBLICACIONES PERIÓDICAS"
        },
        {
          codigo: "5819",
          descripcion: "OTRAS ACTIVIDADES DE EDICIÓN"
        },
        {
          codigo: "5820",
          descripcion: "EDICIÓN DE PROGRAMAS DE INFORMÁTICOS"
        },
        {
          codigo: "5911",
          descripcion: "ACTIVIDADES DE PRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN"
        },
        {
          codigo: "5912",
          descripcion: "ACTIVIDADES DE POSTPRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN"
        },
        {
          codigo: "5913",
          descripcion: "ACTIVIDADES DE DISTRIBUCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VÍDEOS Y PROGRAMAS DE TELEVISIÓN"
        },
        {
          codigo: "5914",
          descripcion: "ACTIVIDADES DE EXHIBICIÓN DE PELÍCULAS CINEMATOGRÁFICAS Y CINTAS DE VÍDEO"
        },
        {
          codigo: "5920",
          descripcion: "ACTIVIDADES DE GRABACIÓN DE SONIDO Y EDICIÓN DE MÚSICA"
        },
        {
          codigo: "6010",
          descripcion: "TRANSMISIONES DE RADIO"
        },
        {
          codigo: "6020",
          descripcion: "PROGRAMACIÓN Y TRANSMISIONES DE TELEVISIÓN"
        },
        {
          codigo: "6110",
          descripcion: "ACTIVIDADES DE TELECOMUNICACIONES ALÁMBRICAS"
        },
        {
          codigo: "6120",
          descripcion: "ACTIVIDADES DE TELECOMUNICACIONES INALÁMBRICAS"
        },
        {
          codigo: "6130",
          descripcion: "ACTIVIDADES DE TELECOMUNICACIONES POR SATÉLITE"
        },
        {
          codigo: "6190",
          descripcion: "OTRAS ACTIVIDADES DE TELECOMUNICACIÓN"
        },
        {
          codigo: "6201",
          descripcion: "PROGRAMACIÓN INFORMÁTICA"
        },
        {
          codigo: "6202",
          descripcion: "CONSULTORÍA DE INFORMÁTICA Y DE GESTIÓN DE INSTALACIONES INFORMÁTICAS"
        },
        {
          codigo: "6209",
          descripcion: "OTRAS ACTIVIDADES DE TECNOLOGÍA DE LA INFORMACIÓN Y DE SERVICIOS INFORMÁTICOS"
        },
        {
          codigo: "6311",
          descripcion: "PROCESAMIENTO DE DATOS, HOSPEDAJE Y ACTIVIDADES CONEXAS"
        },
        {
          codigo: "6312",
          descripcion: "PORTALES WEB"
        },
        {
          codigo: "6391",
          descripcion: "ACTIVIDADES DE AGENCIAS DE NOTICIAS"
        },
        {
          codigo: "6399",
          descripcion: "OTRAS ACTIVIDADES DE SERVICIOS DE INFORMACIÓN N.C.P."
        },
        {
          codigo: "6411",
          descripcion: "BANCA CENTRAL"
        },
        {
          codigo: "6419",
          descripcion: "OTROS TIPOS DE INTERMEDIACIÓN MONETARIA (COMPRA Y VENTA DE DIVISAS)"
        },
        {
          codigo: "6420",
          descripcion: "ACTIVIDADES DE SOCIEDADES DE CARTERA"
        },
        {
          codigo: "6430",
          descripcion: "FONDOS Y SOCIEDADES DE INVERSIÓN Y ENTIDADES FINANCIERAS SIMILARES"
        },
        {
          codigo: "6491",
          descripcion: "ARRENDAMIENTO FINANCIERO"
        },
        {
          codigo: "6492",
          descripcion: "OTRAS ACTIVIDADES DE CONCESIÓN DE CRÉDITO"
        },
        {
          codigo: "6499",
          descripcion: "OTRAS ACTIVIDADES DE SERVICIOS FINANCIEROS, EXCEPTO LAS DE SEGUROS Y FONDOS DE PENSIONES, N.C.P."
        },
        {
          codigo: "6511",
          descripcion: "SEGUROS DE VIDA"
        },
        {
          codigo: "6512",
          descripcion: "SEGUROS GENERALES"
        },
        {
          codigo: "6520",
          descripcion: "REASEGUROS"
        },
        {
          codigo: "6530",
          descripcion: "FONDOS DE PENSIONES"
        },
        {
          codigo: "6611",
          descripcion: "ADMINISTRACIÓN DE MERCADOS FINANCIEROS"
        },
        {
          codigo: "6612",
          descripcion: "CORRETAJE DE VALORES Y DE CONTRATOS DE PRODUCTOS BÁSICOS"
        },
        {
          codigo: "6619",
          descripcion: "OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SERVICIOS FINANCIEROS"
        },
        {
          codigo: "6621",
          descripcion: "EVALUACIÓN DE RIESGOS Y DAÑOS"
        },
        {
          codigo: "6622",
          descripcion: "ACTIVIDADES DE AGENTES Y CORREDORES DE SEGUROS"
        },
        {
          codigo: "6629",
          descripcion: "OTRAS ACTIVIDADES AUXILIARES DE LAS ACTIVIDADES DE SEGUROS Y FONDOS DE PENSIONES"
        },
        {
          codigo: "6630",
          descripcion: "ACTIVIDADES DE GESTIÓN DE FONDOS"
        },
        {
          codigo: "6810",
          descripcion: "ACTIVIDADES INMOBILIARIAS REALIZADAS CON BIENES PROPIOS O ARRENDADOS"
        },
        {
          codigo: "6820",
          descripcion: "ACTIVIDADES INMOBILIARIAS REALIZADAS A CAMBIO DE UNA RETRIBUCIÓN O POR CONTRATA"
        },
        {
          codigo: "6910",
          descripcion: "ACTIVIDADES JURÍDICAS (NOTARIAS)"
        },
        {
          codigo: "6920",
          descripcion: "ACTIVIDADES DE CONTABILIDAD, TENEDURÍA DE LIBROS Y AUDITORÍA; CONSULTORÍA FISCAL"
        },
        {
          codigo: "7010",
          descripcion: "ACTIVIDADES DE OFICINAS CENTRALES"
        },
        {
          codigo: "7020",
          descripcion: "ACTIVIDADES DE CONSULTORÍA DE GESTIÓN"
        },
        {
          codigo: "7110",
          descripcion: "ACTIVIDADES DE ARQUITECTURA E INGENIERÍA Y ACTIVIDADES CONEXAS DE CONSULTORÍA TÉCNICA"
        },
        {
          codigo: "7120",
          descripcion: "ENSAYOS Y ANÁLISIS TÉCNICOS"
        },
        {
          codigo: "7210",
          descripcion: "INVESTIGACIÓN Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS NATURALES Y LA INGENIERÍA"
        },
        {
          codigo: "7220",
          descripcion: "INVESTIGACIÓN Y DESARROLLO EXPERIMENTAL EN EL CAMPO DE LAS CIENCIAS SOCIALES Y LAS HUMANIDADES"
        },
        {
          codigo: "7310",
          descripcion: "PUBLICIDAD"
        },
        {
          codigo: "7320",
          descripcion: "ESTUDIOS DE MERCADO Y ENCUESTAS DE OPINIÓN PÚBLICA"
        },
        {
          codigo: "7410",
          descripcion: "ACTIVIDADES ESPECIALIZADAS DE DISEÑO"
        },
        {
          codigo: "7420",
          descripcion: "ACTIVIDADES DE FOTOGRAFÍA"
        },
        {
          codigo: "7490",
          descripcion: "OTRAS ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS N.C.P."
        },
        {
          codigo: "7500",
          descripcion: "ACTIVIDADES VETERINARIAS"
        },
        {
          codigo: "7710",
          descripcion: "ALQUILER Y ARRENDAMIENTO DE VEHÍCULOS AUTOMOTORES"
        },
        {
          codigo: "7721",
          descripcion: "ALQUILER Y ARRENDAMIENTO DE EQUIPO RECREATIVO Y DEPORTIVO"
        },
        {
          codigo: "7722",
          descripcion: "ALQUILER DE CINTAS DE VÍDEO Y DISCOS"
        },
        {
          codigo: "7729",
          descripcion: "ALQUILER Y ARRENDAMIENTO DE OTROS EFECTOS PERSONALES Y ENSERES DOMÉSTICOS"
        },
        {
          codigo: "7730",
          descripcion: "ALQUILER Y ARRENDAMIENTO DE OTROS TIPOS DE MAQUINARIA, EQUIPO Y BIENES TANGIBLES"
        },
        {
          codigo: "7740",
          descripcion: "ARRENDAMIENTO DE PROPIEDAD INTELECTUAL Y PRODUCTOS SIMILARES, EXCEPTO OBRAS PROTEGIDAS POR DERECHOS DE AUTOR"
        },
        {
          codigo: "7810",
          descripcion: "ACTIVIDADES DE AGENCIAS DE EMPLEO"
        },
        {
          codigo: "7820",
          descripcion: "ACTIVIDADES DE AGENCIAS DE EMPLEO TEMPORAL"
        },
        {
          codigo: "7830",
          descripcion: "OTRAS ACTIVIDADES DE DOTACIÓN DE RECURSOS HUMANOS"
        },
        {
          codigo: "7911",
          descripcion: "ACTIVIDADES DE AGENCIAS DE VIAJES"
        },
        {
          codigo: "7912",
          descripcion: "ACTIVIDADES DE OPERADORES TURÍSTICOS"
        },
        {
          codigo: "7990",
          descripcion: "OTROS SERVICIOS DE RESERVAS Y ACTIVIDADES CONEXAS"
        },
        {
          codigo: "8010",
          descripcion: "ACTIVIDADES DE SEGURIDAD PRIVADA"
        },
        {
          codigo: "8020",
          descripcion: "ACTIVIDADES DE SERVICIO DE SISTEMAS DE SEGURIDAD"
        },
        {
          codigo: "8030",
          descripcion: "ACTIVIDADES DE INVESTIGACIÓN"
        },
        {
          codigo: "8110",
          descripcion: "ACTIVIDADES COMBINADAS DE APOYO A INSTALACIONES"
        },
        {
          codigo: "8121",
          descripcion: "LIMPIEZA GENERAL DE EDIFICIOS"
        },
        {
          codigo: "8129",
          descripcion: "OTRAS ACTIVIDADES DE LIMPIEZA DE EDIFICIOS E INSTALACIONES INDUSTRIALES"
        },
        {
          codigo: "8130",
          descripcion: "ACTIVIDADES DE PAISAJISMO Y SERVICIOS DE MANTENIMIENTO CONEXOS"
        },
        {
          codigo: "8211",
          descripcion: "ACTIVIDADES COMBINADAS DE SERVICIOS ADMINISTRATIVOS DE OFICINA"
        },
        {
          codigo: "8219",
          descripcion: "FOTOCOPIADO, PREPARACIÓN DE DOCUMENTOS Y OTRAS ACTIVIDADES ESPECIALIZADAS DE APOYO DE OFICINA"
        },
        {
          codigo: "8220",
          descripcion: "ACTIVIDADES DE CENTROS DE LLAMADAS"
        },
        {
          codigo: "8230",
          descripcion: "ORGANIZACIÓN DE CONVENCIONES Y EXPOSICIONES COMERCIALES"
        },
        {
          codigo: "8291",
          descripcion: "ACTIVIDADES DE AGENCIAS DE COBRO Y AGENCIAS DE CALIFICACIÓN CREDITICIA"
        },
        {
          codigo: "8292",
          descripcion: "ACTIVIDADES DE ENVASADO Y EMPAQUETADO"
        },
        {
          codigo: "8299",
          descripcion: "OTRAS ACTIVIDADES DE SERVICIOS DE APOYO A LAS EMPRESAS N.C.P."
        },
        {
          codigo: "8411",
          descripcion: "ACTIVIDADES DE LA ADMINISTRACIÓN PÚBLICA EN GENERAL"
        },
        {
          codigo: "8412",
          descripcion: "REGULACIÓN DE LAS ACTIVIDADES DE ORGANISMOS QUE PRESTAN SERVICIOS SANITARIOS, EDUCATIVOS, CULTURALES Y OTROS SERVICIOS SOCIALES, EXCEPTO SERVICIOS DE SEGURIDAD SOCIAL"
        },
        {
          codigo: "8413",
          descripcion: "REGULACIÓN Y FACILITACIÓN DE LA ACTIVIDAD ECONÓMICA"
        },
        {
          codigo: "8421",
          descripcion: "RELACIONES EXTERIORES"
        },
        {
          codigo: "8422",
          descripcion: "ACTIVIDADES DE DEFENSA"
        },
        {
          codigo: "8423",
          descripcion: "ACTIVIDADES DE MANTENIMIENTO DEL ORDEN PÚBLICO Y DE SEGURIDAD"
        },
        {
          codigo: "8430",
          descripcion: "ACTIVIDADES DE PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA"
        },
        {
          codigo: "8510",
          descripcion: "ENSEÑANZA PREESCOLAR Y PRIMARIA"
        },
        {
          codigo: "8521",
          descripcion: "ENSEÑANZA SECUNDARIA DE FORMACIÓN GENERAL"
        },
        {
          codigo: "8522",
          descripcion: "ENSEÑANZA SECUNDARIA DE FORMACIÓN TÉCNICA Y PROFESIONAL"
        },
        {
          codigo: "8530",
          descripcion: "ENSEÑANZA SUPERIOR"
        },
        {
          codigo: "8541",
          descripcion: "EDUCACIÓN DEPORTIVA Y RECREATIVA"
        },
        {
          codigo: "8542",
          descripcion: "ENSEÑANZA CULTURAL"
        },
        {
          codigo: "8549",
          descripcion: "OTROS TIPOS DE ENSEÑANZA N.C.P."
        },
        {
          codigo: "8550",
          descripcion: "ACTIVIDADES DE APOYO A LA ENSEÑANZA"
        },
        {
          codigo: "8610",
          descripcion: "ACTIVIDADES DE HOSPITALES"
        },
        {
          codigo: "8620",
          descripcion: "ACTIVIDADES DE MÉDICOS Y ODONTÓLOGOS"
        },
        {
          codigo: "8690",
          descripcion: "OTRAS ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA"
        },
        {
          codigo: "8710",
          descripcion: "ACTIVIDADES DE ATENCIÓN DE ENFERMERÍA EN INSTITUCIONES"
        },
        {
          codigo: "8720",
          descripcion: "ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES PARA PERSONAS CON RETRASO MENTAL, ENFERMOS MENTALES Y TOXICÓMANOS"
        },
        {
          codigo: "8730",
          descripcion: "ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES PARA PERSONAS DE EDAD PERSONAS CON DISCAPACIDAD"
        },
        {
          codigo: "8790",
          descripcion: "OTRAS ACTIVIDADES DE ATENCIÓN EN INSTITUCIONES"
        },
        {
          codigo: "8810",
          descripcion: "ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO PARA PERSONAS DE EDAD Y PERSONAS CON DISCAPACIDAD"
        },
        {
          codigo: "8890",
          descripcion: "OTRAS ACTIVIDADES DE ASISTENCIA SOCIAL SIN ALOJAMIENTO"
        },
        {
          codigo: "9000",
          descripcion: "ACTIVIDADES CREATIVAS, ARTÍSTICAS Y DE ENTRETENIMIENTO"
        },
        {
          codigo: "9101",
          descripcion: "ACTIVIDADES DE BIBLIOTECAS Y ARCHIVOS"
        },
        {
          codigo: "9102",
          descripcion: "ACTIVIDADES DE MUSEOS Y GESTIÓN DE LUGARES Y EDIFICIOS HISTÓRICOS"
        },
        {
          codigo: "9103",
          descripcion: "ACTIVIDADES DE JARDINES BOTÁNICOS Y ZOOLÓGICOS Y RESERVAS NATURALES"
        },
        {
          codigo: "9200",
          descripcion: "ACTIVIDADES DE JUEGOS DE AZAR Y APUESTAS"
        },
        {
          codigo: "9311",
          descripcion: "GESTIÓN DE INSTALACIONES DEPORTIVAS"
        },
        {
          codigo: "9312",
          descripcion: "ACTIVIDADES DE CLUBES DEPORTIVOS"
        },
        {
          codigo: "9319",
          descripcion: "OTRAS ACTIVIDADES DEPORTIVAS"
        },
        {
          codigo: "9321",
          descripcion: "ACTIVIDADES DE PARQUES DE ATRACCIONES Y PARQUES TEMÁTICOS"
        },
        {
          codigo: "9329",
          descripcion: "OTRAS ACTIVIDADES DE ESPARCIMIENTO Y RECREATIVAS N.C.P."
        },
        {
          codigo: "9411",
          descripcion: "ACTIVIDADES DE ASOCIACIONES EMPRESARIALES Y DE EMPLEADORES (CÁMARA DE COMERCIO, GREMIOS Y OTROS)"
        },
        {
          codigo: "9412",
          descripcion: "ACTIVIDADES DE ASOCIACIONES PROFESIONALES (COLEGIO DE CONTADORES, INGENIEROS)"
        },
        {
          codigo: "9420",
          descripcion: "ACTIVIDADES DE SINDICATOS"
        },
        {
          codigo: "9491",
          descripcion: "ACTIVIDADES DE ORGANIZACIONES RELIGIOSAS"
        },
        {
          codigo: "9492",
          descripcion: "ACTIVIDADES DE ORGANIZACIONES POLÍTICAS"
        },
        {
          codigo: "9499",
          descripcion: "ACTIVIDADES DE OTRAS ASOCIACIONES N.C.P."
        },
        {
          codigo: "9511",
          descripcion: "REPARACIÓN DE ORDENADORES Y EQUIPO PERIFÉRICO"
        },
        {
          codigo: "9512",
          descripcion: "REPARACIÓN DE EQUIPOS COMUNICACIONALES"
        },
        {
          codigo: "9521",
          descripcion: "REPARACIÓN DE APARATOS ELECTRÓNICOS DE CONSUMO"
        },
        {
          codigo: "9522",
          descripcion: "REPARACIÓN DE APARATOS DE USO DOMÉSTICO Y EQUIPO DOMÉSTICO Y DE JARDINERÍA"
        },
        {
          codigo: "9523",
          descripcion: "REPARACIÓN DE CALZADO Y ARTÍCULOS DE CUERO"
        },
        {
          codigo: "9524",
          descripcion: "REPARACIÓN DE MUEBLES Y ACCESORIOS DOMÉSTICOS"
        },
        {
          codigo: "9529",
          descripcion: "REPARACIÓN DE OTROS EFECTOS PERSONALES Y ENSERES DOMÉSTICOS"
        },
        {
          codigo: "9601",
          descripcion: "LAVADO Y LIMPIEZA, INCLUIDA LA LIMPIEZA EN SECO, DE PRODUCTOS TEXTILES Y DE PIEL"
        },
        {
          codigo: "9602",
          descripcion: "PELUQUERÍA Y OTROS TRATAMIENTOS DE BELLEZA"
        },
        {
          codigo: "9603",
          descripcion: "POMPAS FÚNEBRES Y ACTIVIDADES CONEXAS"
        },
        {
          codigo: "9609",
          descripcion: "OTRAS ACTIVIDADES DE SERVICIOS PERSONALES N.C.P."
        },
        {
          codigo: "9700",
          descripcion: "ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES DE PERSONAL DOMÉSTICO"
        },
        {
          codigo: "9900",
          descripcion: "ACTIVIDADES DE ORGANIZACIONES Y ÓRGANOS EXTRATERRITORIALES"
        }
        
      ]
      
      export const relationshipOptions = [
        { val: "Mi Persona", text: "Mi Persona" },
        { val: "Cónyuge", text: "Cónyuge" },
        { val: "Conviviente civil", text: "Conviviente civil" },
        { val: "Padre", text: "Padre" },
        { val: "Hermano(a)", text: "Hermano(a)" },
        { val: "Madre", text: "Madre" },
        { val: "Hijo(a)", text: "Hijo(a)" },
        { val: "Abuelo(a)", text: "Abuelo(a)" },
        { val: "Nieto(a)", text: "Nieto(a)" },
        { val: "Suegro(a)", text: "Suegro(a)" },
        { val: "Hijastro(a)", text: "Hijastro(a)" },
        { val: "Abuelo(a) del cónyuge", text: "Abuelo(a) del cónyuge" },
        { val: "Nieto(a) del cónyuge", text: "Nieto(a) del cónyuge" },
        { val: "Yerno", text: "Yerno" },
        { val: "Nuera", text: "Nuera" },
        { val: "Tío(a)", text: "Tío(a)" },
        { val: "Primo(a)", text: "Primo(a)" },
        { val: "Sobrino(a)", text: "Sobrino(a)" },
        { val: "Bisabuelo(a)", text: "Bisabuelo(a)" },
        { val: "Bisnieto(a)", text: "Bisnieto(a)" },
        { val: "Bisabuelo(a) del cónyuge", text: "Bisabuelo(a) del cónyuge" },
        { val: "Cuñado(a)", text: "Cuñado(a)" },
        { val: "Sobrino(a) del Cónyuge", text: "Sobrino(a) del Cónyuge" },
      ]
      
      export const relationshipOptionsParauco = [
        { val: "Cónyuge", text: "Cónyuge" },
        { val: "Conviviente civil", text: "Conviviente civil" },
        { val: "Padre", text: "Padre" },
        { val: "Madre", text: "Madre" },
        { val: "Hijo(a)", text: "Hijo(a)" },
        { val: "Hermano(a)", text: "Hermano(a)" },
        { val: "Abuelo(a)", text: "Abuelo(a)" },
        { val: "Nieto(a)", text: "Nieto(a)" },
        { val: "Suegro(a)", text: "Suegro(a)" },
        { val: "Yerno", text: "Yerno" },
        { val: "Nuera", text: "Nuera" },
        { val: "Cuñado(a)", text: "Cuñado(a)" },
      ]
      
      export const cargosOptions = [
        { val: "Director", text: "Director" },
        { val: "Socio", text: "Socio" },
        { val: "Accionista", text: "Accionista" },
        { val: "Gerente", text: "Gerente" },
        { val: "Ejecutivo Principal", text: "Ejecutivo Principal" },
        { val: "Administrador", text: "Administrador" },
        { val: "Asesor", text: "Asesor" },
      ]
      
      export const tipoDocOptions = [
        { val: "DNI", text: "DNI" }, 
        { val: "Otros", text: "Otros" }
      ]
      export const tipoDocEntityOptions = [
        { val: "RUC", text: "RUC" }, 
        { val: "Otros", text: "Otros" }
      ]
      
      export const tipoEmpresaOptions = [
        { val: "Proveedor", text: "Proveedor" },
        { val: "Contratista", text: "Contratista" },
        { val: "Cliente", text: "Cliente" },
      ]
      
      export const pepTypeOptions = [
        {val: 'PEP', text: 'PEP'},
        {val: 'Funcionario Publico', text: 'Funcionario Público'}
      ]
      
      export const tipoFundacionOptions = [
        { val: "Fundación", text: "Fundación" },
        { val: "Corporación", text: "Corporación" },
        { val: "Institución de Beneficencia o Sin Fines de Lucro", text: "Institución de Beneficencia o Sin Fines de Lucro" },
      ]
      
      export const radioBtnIds = [
        'hasOutdoorActivities',
        'hasInterlocking',
        'hasRelLabAnterior',
        'hasCompetencia',
        'hasParentesco',
        'hasPep',
        "hasRelacionProveedor",
        'hasFundaciones',
        'hasOtherConflicts',
        'hasOtherConflictsv2',
        'hasPemp',
        'hasPsoc',
      ];