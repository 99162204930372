import apiConfig from '../config/api'
import {apiRequestorHelper} from '../helpers'

const FileManager = {

  getFileList: () => {
    return apiRequestorHelper({
      url: apiConfig.url + '/fileManager/getFilesAndFolders',
      method: 'get'
    })
  },

  deleteClientFileList(fileList) {
    return apiRequestorHelper({
      url: apiConfig.url + '/fileManager/deleteFileAndFolder',
      method: 'post',
      body: fileList
    })
  },

  moveClientFile(filePath, destinationPath) {
    return apiRequestorHelper({
      url: apiConfig.url + '/fileManager/moveFileToFolder',
      method: 'post',
      body: {
        "filePathList": filePath,
        "folderPath": destinationPath
      }
    })
  },

  validateMoveClientFile(filePath, destinationPath) {
    return apiRequestorHelper({
      url: apiConfig.url + '/fileManager/validateMoveFileToFolder',
      method: 'post',
      body: {
        "filePathList": filePath,
        "folderPath": destinationPath
      }
    })
  },

  uploadClientFile(formData) {
      return apiRequestorHelper({
        url: apiConfig.url + '/fileManager/uploadClientFile',
        method: 'post',
        body: formData
      })
    },

  createFolderPath: (folderName) => {
      return apiRequestorHelper({
        url: apiConfig.url + '/fileManager/createFolder',
        method: 'post',
        body:
            {"folderName": folderName}
      })
    }

}

export default FileManager;
