import './NewFolderModal.scss';
import {Button, Form, Input, Modal, Spin} from 'antd';
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const NewfolderModal = (props) => {
    const [isSubmitDisabled, setSubmitDisabled] = useState(true);
    const [forderName, setFolderName] = useState('');

    //Hace referencia al componente FileManager
    const {isNewFolderOpen} = props
    const {setIsNewFolderOpen} = props

    const {currentFolder} = props;
    const {createFolder} = props;
    const {form} = props;
    const {isCreateFolderLoading} = props;

    const {t} = useTranslation();

    const {getFieldDecorator, validateFields} = form;

    useEffect(() => {
        let elems = document.getElementsByClassName('folder-name-input');
        if (elems.length > 0) {
            elems[0].focus()
        }
    }, []);

    const handleChangeInput = (value) => {
        setFolderName(value)
    }

    const handleValidateInput = (e) => {
        validateFields(["folder"], (errors) => {
            if (!errors) {
                setSubmitDisabled(false);
              } else {
                setSubmitDisabled(true);
              }
        });
    };

    return (

        <div className={"new-folder-modal"}>
            {isNewFolderOpen &&
                <Modal className = {"folder-modal"}
                    title={`${t("messages.aml.file.manager.new.folder.message")} ${currentFolder}`}
                    visible={true}
                    footer={null}
                    onCancel={() => setIsNewFolderOpen(false)}
                >
                    <div className={"new-folder-modal"}>
                    <Spin size='large' spinning={isCreateFolderLoading}>
                        <Form id="new-folder-name"
                              autoComplete="off"
                        >

                            <div className="folder-input-container">

                                    <span className="folder-name-input" style={{fontSize: '11px'}}>
                                        {t("messages.aml.file.manager.remember.valid.character")}
                                        <span className="caracter-especial">{"\\ % \. ' \" : < > ? /"}</span>

                                    </span>
                            </div>

                            <br/>

                            <Form.Item>
                            {getFieldDecorator('folder', {

                                    rules: [
                                        {
                                            required: true,
                                            message: t("messages.aml.file.manager.folder.name.rule")
                                        },
                                        {
                                            pattern: /^[a-zA-Z0-9_ *!|ñ#$&¿]+$/,
                                            message: t("messages.aml.file.manager.especial.character.rule")
                                        },
                                        {
                                            validator: (_, value, callback) => {
                                                if (value && value.trim().length === 0) {
                                                    callback(t("messages.aml.file.manager.especial.character.rule"));
                                                } else {
                                                    callback();
                                                }
                                            },
                                        },
                                    ],
                                })(
                                    <div className="folder-input-container">
                                        <Input className="folder-name-input"
                                               placeholder="Ingrese nombre de nueva carpeta"
                                               onChange={(e) => handleChangeInput(e.target.value)}
                                               onKeyUp={handleValidateInput}
                                               onBlur={handleValidateInput}
                                        />
                                    </div>
                                )}
                            </Form.Item>

                            <div className={"ant-modal-footer"}>
                                <div>
                                    <Button
                                        //style={{ marginRight: '10px' }}
                                        className={"ant-btn"}
                                        onClick={() => setIsNewFolderOpen(false)}
                                    >
                                        <span>{t("messages.aml.cancel")}</span>
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            createFolder(forderName)
                                        }}
                                        className={"ant-btn btn-save-folder"}
                                        type="primary"
                                        disabled={isSubmitDisabled}
                                    >
                                        <span>{t("messages.aml.create")}</span>

                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </Spin>
                    </div>
                </Modal>
            }
        </div>

    )
}


export default Form.create()(NewfolderModal);
