import './AdminTransFile.scss';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Form, Icon, message, Modal, notification, Spin} from 'antd';
import 'devextreme/dist/css/dx.light.css';
import FileManager, {Column, Details, Item, ItemView, Permissions, Toolbar} from 'devextreme-react/file-manager';
import {
    createFolderPathPromise,
    deleteClientFileListPromise,
    getClientFileListPromise,
    moveClientFilePromise,
    uploadClientFilePromise,
    validateMoveClientFilePromise
} from "../../promises";
import Dragger from "antd/es/upload/Dragger";
import uploadIcon from "../Batch/components/ModalAddBatchFile/drag-icon.png";
import {useTranslation} from "react-i18next";
import image from "./assets/image.png";
import rar from "./assets/rar.png";
import pdf from "./assets/pdf.png";
import word from "./assets/word.png";
import ppt from "./assets/ppt.png";
import excel from "./assets/excel.png";
import file from "./assets/file.png";
import folder from "./assets/folder.png";
import {LocalStorageService, ReportService} from "../../services";

import esMessages from './locales/es.json';
import enMessages from './locales/en.json';

import {loadMessages, locale} from 'devextreme/localization';
import {NewFolderModal} from "./components/index";


const AdminTransFile = (props) => {

    //Hace referencia al componente FileManager
    const fileManagerRef = useRef(null);
    const {t} = useTranslation();

    const {currentUser} = props
    const {form} = props;

    const [filesAndFolderList, setFilesAndFolderList] = useState([]);
    const [isLoadFileList, setIsLoadFileList] = useState(false);
    const [isModalUploadRelatedVisible, setIsModalUploadRelatedVisible] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [fileListUpload, setFileListUpload] = useState([]);
    const [unableToDeleteFolders, setUnableToDeleteFolders] = useState([]);
    const [isNewFolderOpen, setIsNewFolderOpen] = useState(false);
    const [isCreateFolderLoading, setIsCreateFolderLoading] = useState(false);
    const [isElementGrouping, setIsElementGrouping] = useState(false);
    const [isElementMoving, setIsElementMoving] = useState(false);
    const [filePath, setFilePath] = useState([]);
    const [destinationPath, setDestinationPath] = useState('');
    const [isConfirmModalMoveFile, setIsConfirmModalMoveFile] = useState(false);
    const [existingFileToMove, setExistingFileToMove] = useState([]);


    useEffect(() => {
        const language = LocalStorageService.read('i18nextLng')
        locale(language.substring(0, 2))

        const localeMessage = language.substring(0, 2) === "es" ? esMessages : enMessages;

        //corrige root folder name
        localeMessage[language.substring(0, 2)]["dxFileManager-rootDirectoryName"] = currentUser.cliente.abreviado;

        loadMessages(localeMessage);
        loadDataTable();
    }, []);

    //Carga contenido de tabla
    const loadDataTable = useCallback(() => {
        getClientFileListPromise()
            .then((response) => {
            const filesAndFolder = itemDataFormater(response)
            if (filesAndFolder.items !== undefined) {
                setFilesAndFolderList(filesAndFolder.items[0].items);
            }
            })
            .finally(() => {
                setIsLoadFileList(true);
            });
    }, []);

    const itemDataFormater = (data) => {
        const levelData = {};

        for (const item of data) {
            const pathParts = item.path.split('/').filter(Boolean);
            let currentLevel = levelData;

            //valida cada parte del path de current item
            for (const part of pathParts) {
                currentLevel.items = currentLevel.items || [];
                const existingItem = currentLevel.items.find((i) => i.name === part);

                //casos con items o hijos
                if (existingItem) {
                    currentLevel = existingItem;
                } else {
                    const newItem = {
                        name: part,
                        isDirectory: item.isFolder,
                    };
                    if (item.size !== undefined) newItem.size = item.size;
                    if (item.uploadDate !== undefined) newItem.uploadDate = item.uploadDate;
                    if (item.category) newItem.category = item.category;
                    currentLevel.items.push(newItem);
                    currentLevel = newItem;
                }
            }
        }

        //validar
        const flattenTree = (node) => {

            const item = {
                name: node.name,
                isDirectory: node.isDirectory,
                type: node.isDirectory ? 'folder' : 'file',
            };
            if (node.size !== undefined) item.size = node.size;
            if (node.uploadDate !== undefined) {
                item.dateModified = node.uploadDate;
            }
            if (node.category) item.category = node.category;
            if (node.items) {
                item.items = node.items.map((subNode) => flattenTree(subNode));
            }

            return item;
        };
        let tree = flattenTree(levelData);
        return tree
    }

    //Función agregar archivo
    const handleUploadFile = () => {
        setIsModalUploadRelatedVisible(true)
    }

    const newFileMenuOptions = {
        items: [
            {
                text: t('messages.aml.file.manager.add.file'),
                icon: 'plus'
            },
        ],
        onItemClick: handleUploadFile
    };

    const reloadAction = {
        items: [
            {
                icon: 'refresh'
            },
        ],
        onItemClick: loadDataTable
    };

    const handleActionSaveClientFile = () => {
        const formData = new FormData()
        fileListUpload.forEach(file => {
            formData.append('file', file)
            formData.append('targetFolder', getCurrentFolder())
        })
        setUploading(true)
        uploadClientFilePromise(formData)
            .then((response) => {
                if (response.status === 200) {
                    notification.success({
                        message: t('messages.aml.information'),
                        description: response.data
                    });
                    loadDataTable();
                } else {
                    notification.error({
                        message: 'Error',
                        description: response.data
                    });
                }
            })
            .catch((e) => {
                let errorMensaje = 'Ocurrió un error desconocido.';
                if(e.status === 409){
                    errorMensaje = e.data;
                }else{
                    loadDataTable();
                }
                notification.error({
                    message: 'Error',
                    description: errorMensaje
                })
            })
            .finally(() => {
                setUploaded(false)
                setUploading(false)
                setFileListUpload([])
                setIsModalUploadRelatedVisible(false)
            })
    }

    const propsUpload = {
        //accept: ".*",//
        onRemove: file => {
            const index = fileListUpload.indexOf(file)
            const newFileList = fileListUpload.slice()
            newFileList.splice(index, 1)
            setUploaded(false)
            return {
                fileListUpload: newFileList
            }
        },
        beforeUpload: file => {
            if (fileListUpload.length === 0) {
                setFileListUpload(fileListUpload => [...fileListUpload, file])
            }
            setUploaded(true)
            return false
        },
        multiple: false,
        fileList: fileListUpload,
    }

    const cleanFile = () => {
        setFileListUpload([])
        setUploaded(false)
        setUploading(false)
    }

    //Crear carpeta
    function handleCreateFolder(folderName) {
        setIsCreateFolderLoading(true)
        const currentFolderPath = getCurrentFolder()

        if (folderName === null || folderName === "") {
            notification['error']({
                "message": t('messages.aml.information'),
                "description": t('messages.aml.file.manager.error.new.folder.create.message')
            });
            return;
        }

        let completeFolderPath = currentFolderPath + folderName.replace(":", "")

        createFolderPathPromise(completeFolderPath)
            .then(response => {
                notification['success']({
                    "message": t('messages.aml.information'),
                    "description": t('messages.aml.file.manager.success.new.folder.create.message')
                })
                setIsModalUploadRelatedVisible(false)

                loadDataTable()
            })
            .catch((e) => {
                notification.error({
                    message: 'Error',
                    description: e
                })
            })
            .finally(() => {
                setUploaded(false)
                setUploading(false)
                setFileListUpload([])
                setIsNewFolderOpen(false)
                setIsCreateFolderLoading(false)
            })

    }

    const newFolderMenuOptions = {
        items: [
            {
                text: t('messages.aml.file.manager.add.folder'),
                icon: 'plus'
            },
        ],
        onItemClick: () => {
            setIsNewFolderOpen(true)
            //folderNameRef.current.focus()
        }
    };

    //Botón eliminar
    const handleDeleteFiles = (e) => {
        if (!isElementGrouping) {
            let selectedItems = [];
            selectedItems = fileManagerRef.current._instance.getSelectedItems()
                .map(item => {
                    return {
                        path: item.path + (item.isDirectory? "/" : ""),
                        isFolder: item.isDirectory,
                    }
                });
            setIsElementGrouping(true)

            //caso en que la acción eliminar se gatilla desde botón derecho
            if (selectedItems.length === 0) {
                selectedItems = [{
                    path: e.item.path + (e.item.isDirectory ? "/" : ""),
                    isFolder: e.item.isDirectory,
                }]
            }

            fileManagerRef.current._instance._clearSelection();

            deleteClientFileListPromise(selectedItems)
                .then(response => {
                    if (response.status === 200) {
                        response = response.data
                        if (response.deletedStatus && response.deletedStatus.unableToDeleteFolders && response.deletedStatus.unableToDeleteFolders.length > 0) {
                            const folderNames = response.deletedStatus.unableToDeleteFolders.map(folder => folder);
                            setUnableToDeleteFolders(folderNames)
                            //alert("Las siguientes carpetas poseen contenido en su interior, no es posible eliminar:  \n" + folderNames.join("\n ") );
                        }
                        if (response.deletedStatus && response.deletedStatus.elementListDeleted.length > 0) {
                            notification['success']({
                                "message": t('messages.aml.information'),
                                "description":
                                    <>
                                        <strong>
                                            {response.deletedStatus.elementListDeleted.length}
                                        </strong>
                                        {t('messages.aml.file.manager.success.delete.elemets')}
                                        <br/>
                                        <ul>
                                            {response.deletedStatus.elementListDeleted.map((item, index) => (
                                                <li key={index} style={{fontSize: "12px"}}>{item}</li>
                                            ))}
                                        </ul>

                                    </>
                            })
                        }

                    } else {
                        notification['error']({
                            "message": 'Error',
                            "description": t('messages.aml.file.manager.error.delete.elemets')
                        })
                    }
                    setIsElementGrouping(false)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    notification['error']({
                        "message": 'Error',
                        "description": t('messages.aml.errors.unknownError')
                    })
                    setIsElementGrouping(false)
                })
                .finally(() => {
                    loadDataTable();
                });
        }
    };

    //Descarga de archivos
    const handleDownloadFile = async (e) => {
        if (!isElementGrouping) {
            let selectedItems = [];
            let currentPath = getCurrentFolder();
            selectedItems = fileManagerRef.current._instance.getSelectedItems()
                .map(item => {
                    return {
                        path: item.path + (item.isDirectory ? "/" : ""),
                        isFolder: item.isDirectory,
                        name: item.name
                    }
                });

            fileManagerRef.current._instance._clearSelection();

            try {
                message.loading({content: 'Descargando archivo...', duration: 0, key: 'msg-report'})
                const downloadPromises = selectedItems.map((item) =>
                        downloadFile(item)
                    );
                message.destroy()


                setIsElementGrouping(true)
                await Promise.all(downloadPromises)

            } catch (error) {
                    message.error({content: `Error en las descargas: ${error.message}`, key: 'msg-report'});
                } finally {
                    setIsElementGrouping(false)
                }
            }

    }

    const downloadFile = async (item) => {
        return new Promise((resolve, reject) => {

            ReportService.read('/fileManager/downloadClientFile/' + item.name, {"currentPath": getCurrentFolder()}, null, item.name, {
                onDownloadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                }
            })
            resolve()
        });
    };


    //Botón Mover
    const onItemMoved = (e) => {
        if (!isElementMoving) {

            let selectedItems = []
            fileManagerRef.current._instance.getSelectedItems().forEach(item =>  selectedItems.push(getCurrentFolder()  +item.dataItem.name));

            setIsElementMoving(true)

            if (selectedItems.length === 0) {
                selectedItems = [
                    getCurrentFolder() + e.itemName,
                ]
            }

            fileManagerRef.current._instance._clearSelection();
            let folderPath = getCurrentFolder().split('/')[0] + "/" + e.itemPath.replace(e.itemName, "")

            validateMoveFileFolder(selectedItems, folderPath);
        }
    };

    const validateMoveFileFolder = (filePath, destinationPath) => {
        validateMoveClientFilePromise(filePath, destinationPath)
            .then(response => {
                if (response.data.status === "conflict") {
                    setIsConfirmModalMoveFile(true);
                    setFilePath(filePath)
                    setDestinationPath(destinationPath)
                    setExistingFileToMove(response.data.existingFiles)

                } else if (response.status === 200) {
                    notification['success']({
                        "message": t("messages.aml.information"),
                        "description": t('messages.aml.file.manager.success.move.item.message')
                    })
                    loadDataTable();
                } else {
                    notification['error']({
                        "message": 'Error',
                        "description": t('messages.aml.file.manager.move.message')
                    })
                    loadDataTable();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                notification['error']({
                    "message": 'Error',
                    "description": t('messages.aml.file.manager.move.message')
                })
                loadDataTable();
            })
            .finally(() => {
                setIsElementMoving(false)
            });
        setFilePath([])
        setDestinationPath('')
        setExistingFileToMove([])
    }


    const moveFileToFolder = (filePath, destinationPath) => {
        moveClientFilePromise(filePath, destinationPath)
            .then(response => {
                if (response.status === 200) {
                    notification['success']({
                        "message": t("messages.aml.information"),
                        "description": t('messages.aml.file.manager.success.move.item.message')
                    })
                    loadDataTable();
                } else {
                    notification['error']({
                        "message": 'Error',
                        "description": t('messages.aml.file.manager.move.message')
                    })
                    loadDataTable();

                }
            })
            .catch((error) => {
                console.error('Error:', error);
                notification['error']({
                    "message": 'Error',
                    "description": t('messages.aml.file.manager.move.message')
                })
                loadDataTable();

            });
        setFilePath([])
        setDestinationPath('')
        setExistingFileToMove([])
        setIsConfirmModalMoveFile(false);
    };

    const handleShowContextMenu = (e) => {
        let isFolder = false;
        const selectedItems = e.component.getSelectedItems();
        if(selectedItems.some(item => item.isDirectory) || e.fileSystemItem.isDirectory ){
            isFolder = true
        }
        fileManagerRef.current._instance._options.option("permissions.move", !isFolder);
    }

    //Logica modales
    const handleOnCancelModalUploadClientFile = () => {
        setIsModalUploadRelatedVisible(false)
        setUploading(false)
        setUploaded(false)
        setFileListUpload([])
    }

    const handleCloseErrorOnDelete = () => {
        setUnableToDeleteFolders([])
    }

    //Función para formatear data proveniente desde backend
    function getCurrentFolder() {
        // Obtiene información desde la librería
        const breadcrumb = fileManagerRef?.current?._instance?._breadcrumbs?._currentDirectory?.fileItem?.relativeName;
        return breadcrumb
            ? `${currentUser?.cliente?.abreviado}/${breadcrumb}/`
            : `${currentUser?.cliente?.abreviado}/`;
    }

    const getIconForFileName = (item) => {
        const itemName = item.name ? item.name : item;

        const itemNameSplit = itemName.split('.')
        if (itemNameSplit.length === 1) {
            return folder
        }

        const extension = itemNameSplit.pop();
        switch (extension.toLowerCase()) {
            case 'png':
            case 'jpeg':
            case 'gif':
            case 'jpg':
                return image;
            case '7zip':
            case 'gz':
            case 'zip':
            case 'rar':
                return rar;
            case 'pdf':
                return pdf;
            case 'doc':
            case 'docx':
                return word;
            case 'ppt':
            case 'pptx':
                return ppt;
            case 'xls':
            case 'xlsx':
                return excel;
            default:
                return file;
        }
    };

    //ordenamiento
    const sortItemsByName = (a, b) => {
        if (a.isDirectory && !b.isDirectory) {
            return -1;
        } else if (!a.isDirectory && b.isDirectory) {
            return 1;
        } else {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            return nameA.localeCompare(nameB);
        }
    };

    const validateCancelMoveConfirm = () => {
        setIsConfirmModalMoveFile(false)
        setFilePath([])
        setDestinationPath('')
        setExistingFileToMove([])
        loadDataTable()
    }
    return (
        <div className={"transfer-file"}>
            {!isLoadFileList ?
                <Spin className="file-manager-content" spinning={true} size="large"/>
                :
                <div>
                    <FileManager
                        ref={fileManagerRef}
                        fileSystemProvider={{
                            data: filesAndFolderList,
                            sort: sortItemsByName
                        }}
                        height={450}
                        customizeThumbnail={getIconForFileName}
                        onItemDeleted={handleDeleteFiles}
                        onContextMenuShowing={handleShowContextMenu}
                        onItemDownloading={handleDownloadFile}
                        onItemMoved={onItemMoved}

                        notifications={{
                            showPanel: false,
                            showPopup: false
                        }}
                    >

                        <Permissions
                            create={false}
                            delete={true}
                            download={true}
                            move={false}
                        >
                        </Permissions>


                        <ItemView showParentFolder={false}>
                            <Details>
                                <Column dataField="thumbnail"></Column>
                                <Column dataField="name"></Column>
                                <Column dataField="dateModified"></Column>
                                <Column dataField="size"></Column>
                            </Details>
                        </ItemView>

                        <Toolbar>
                            <Item name="showNavPane" visible={false}/>
                            <Item name="switchView" location="before"/>
                            <Item name="separator"/>
                            <Item widget="dxMenu" options={newFolderMenuOptions}/>
                            <Item widget="dxMenu"/>
                            <Item name="separator" location="after"/>

                            <Item widget="dxMenu" location="after" options={newFileMenuOptions}/>
                            <Item widget="dxMenu" location="after" options={reloadAction}/>
                        </Toolbar>

                    </FileManager>

                    {isModalUploadRelatedVisible &&
                        <Modal
                            title={t('messages.aml.uploadFile')}
                            className="modal-cliente"
                            visible={isModalUploadRelatedVisible}
                            onCancel={handleOnCancelModalUploadClientFile}
                            footer={[
                                <div>
                                    <Button
                                        onClick={handleOnCancelModalUploadClientFile}>{t('messages.aml.cancel')} </Button>
                                    <Button type="primary" onClick={handleActionSaveClientFile} disabled={!uploaded}
                                            loading={uploading}>{t('messages.aml.save')}</Button>
                                </div>
                            ]}>
                            <div className="fix-content-modal">
                                <Form layout="vertical">
                                    {fileListUpload.length === 0 ?
                                        <Form.Item>
                                            <Dragger {...propsUpload} >
                                                <img src={uploadIcon} alt="" style={{height: '100px'}}/>
                                                <p className="ant-upload-text">Haga click o arrastre un archivo hacia
                                                    aquí.</p>
                                            </Dragger>
                                        </Form.Item>
                                        :
                                        <div className="file-wrapper">
                                            <div className="file-inner">
                                                <div className="image-wrapper">
                                                    {!uploading &&
                                                        <div className="remove">
                                                            <Icon type="close" onClick={cleanFile}/>
                                                        </div>
                                                    }
                                                    <img src={getIconForFileName(fileListUpload[0].name)} alt=""/>
                                                </div>
                                                <p className="file-name">{fileListUpload[0].name}</p>
                                            </div>
                                        </div>
                                    }
                                </Form>
                            </div>
                        </Modal>
                    }

                    <Modal
                        title={t('messages.aml.file.manager.error.delete.elemets')}
                        visible={unableToDeleteFolders.length > 0}
                        footer={
                            <Button key="submit" type="primary" onClick={handleCloseErrorOnDelete}>
                                Ok
                            </Button>
                        }
                    >
                        <div className="fix-content-modal">
                            <p>{t('messages.aml.file.manager.folder.content')}</p>
                            <ul>
                                {unableToDeleteFolders.map((folder, index) => (
                                    <li key={index}>{folder}</li>
                                ))}
                            </ul>
                        </div>
                    </Modal>


                    {isNewFolderOpen &&
                        <NewFolderModal
                            isNewFolderOpen={isNewFolderOpen}
                            setIsNewFolderOpen={setIsNewFolderOpen}
                            isCreateFolderLoading={isCreateFolderLoading}
                            setIsCreateFolderLoading={setIsCreateFolderLoading}
                            createFolder={handleCreateFolder}
                            currentFolder={getCurrentFolder()}
                        />
                    }


                    <Modal className={"folder-modal"}
                           visible={isConfirmModalMoveFile}
                           title={t('messages.aml.file.manager.validate.overwrite')}
                           footer={null}
                           onCancel={() => validateCancelMoveConfirm()}

                    >
                        <div style={{marginLeft: "21px"}}>
                            <div> {t('')}</div><br/>
                            <div>
                                <ul className={"caracter-especial"} style={{fontSize: "13px"}}>
                                    {existingFileToMove.map((fileName, index) => (
                                        <li key={index}>
                                            {fileName}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>

                        <br/>


                        <div className={"ant-modal-footer"}>
                            <div>
                                <Button
                                    //style={{ marginRight: '10px' }}
                                    className={"ant-btn"}
                                    onClick={() => {
                                        validateCancelMoveConfirm()
                                        }
                                    }
                                >
                                    <span>{t("messages.aml.cancel")}</span>
                                </Button>
                                <Button
                                    onClick={() => {
                                        moveFileToFolder(filePath, destinationPath)
                                    }}
                                    className={"ant-btn btn-save-folder"}
                                    type="primary"
                                >
                                    <span>{"Sobrescribir todo"}</span>
                                </Button>
                            </div>
                        </div>
                    </Modal>


                </div>
            }
        </div>
    )
}


export default Form.create()(AdminTransFile);
