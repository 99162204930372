import {FileManagerService} from "../services";

export default (filePath, destinationPath) => {
    return new Promise((resolve) => {
        FileManagerService.moveClientFile(filePath, destinationPath)
            .then(response => {
                resolve(response)
            })
            .catch(err => console.log(err))
    })

}